import { Drawer } from "antd";
import { Observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { LimitedSharedAccess } from '../../../common/components/LimitedSharedAccess/LimitedSharedAccess';
import { useAnalytics } from '../../app/data/AnalyticsProvider';
import { useStore } from '../../app/data/root.store';
import { FiltersPanel } from '../../filters/components/FiltersPanel';
import { ComparePlansModal } from '../../onboarding/components/ComparePlansModal/ComparePlansModal';
import { MobilePricingModal } from '../../onboarding/components/PricingModal/MobilePricingModal';
import { StarterPlanModal } from '../../onboarding/components/StarterPlanModal/StarterPlanModal';
import { PageRouter } from '../../router/components/PageRouter';
import { SessionExpirationModal } from '../../user/components/SessionExpirationModal/SessionExpirationModal';
import { DemoModeMobileBanner } from './Banner/DemoModeMobileBanner';
import { MobileFooter } from './MobileFooter/MobileFooter';
import { AppWrapper } from './MobileLayout.styles';
import { MobileSharedPortfolioBanner } from './SharedPortfolioBanner/MobileSharedPortfolioBanner';
import { MobileMoreMenu } from './Sidebar/MobileMoreMenu';

export const MobileLayout = () => {
  const location = useLocation();
  const { userStore, uiStore, metadataStore, billingStore, wealthStore } = useStore();
  const { mixpanel, mixpanelTrack } = useAnalytics();

  useEffect(() => {
    if (uiStore.currentRoute !== location.pathname) {
      if (location.pathname !== '/' && userStore.isAuthenticated) {
        mixpanel.config && mixpanelTrack('Page Viewed', {
          path: window.location.pathname,
          referrer: document.referrer,
          title: document.title,
          url: window.location.href,
          is_product: true
        });
      }
      uiStore.routeChanged(location.pathname)
    }
  }, [uiStore, mixpanel, mixpanelTrack, location, userStore.isAuthenticated]);

  const checkScroll = useCallback(() => {
    const isWithBanner = (userStore.isDemoMode || userStore.isHavingSharedPortfolios || false);
    const { scrollY, innerHeight } = window;
    uiStore.setMobileScrollOnTop(scrollY === 0);
    uiStore.setMobileHeaderFixed(scrollY > (isWithBanner ? 48 : -1));
    uiStore.setMobileScrollOnBottom(document.body.scrollHeight < (scrollY + innerHeight + 20))
  }, [uiStore, userStore.isDemoMode, userStore.isHavingSharedPortfolios]);

  useEffect(() => {
    window.addEventListener('scroll', checkScroll);

    // Initial check to set the state correctly on mount
    checkScroll();

    return () => window.removeEventListener('scroll', checkScroll);
  }, [checkScroll]);

  return (<Observer>{() => (
    <>
      {(userStore.isDemoMode && !userStore.data?.profile?.isOnBoarding) ? <DemoModeMobileBanner /> : null}
      {userStore.isHavingSharedPortfolios && !userStore.isEmptySharedSideMenu ? <MobileSharedPortfolioBanner /> : null}
      <AppWrapper
        isHeaderFixed={uiStore.isMobileHeaderFixed}
        isWithTopBanner={userStore.isDemoMode || userStore.isHavingSharedPortfolios}
        isItemPageView={uiStore.currentRoute.includes('wealth/') && wealthStore.viewOf === 'item'}
        isOnBoarding={userStore.data?.profile?.isOnBoarding}
        isWelcome={userStore.isWelcome}
        isAuthenticated={userStore.isAuthenticated}
        emptySideMenuLayout={uiStore.isRouteWithEmptySideBarLayout}
      >

        {userStore.isLoaded &&
          <PageRouter
            isLoggedIn={userStore.isAuthenticated}
            isOnBoarding={userStore.data?.profile?.isOnBoarding}
            isGranteeHavePortfolio={userStore.isGranteeHavePortfolio}
            isViewingOwnPortfolio={userStore.isViewingOwnPortfolio}
            isViewRolesPageBlocked={userStore.isActionBlockedByRole('viewRolesPage')}
            isDemoMode={userStore.isDemoMode || userStore.isDemoTier}
            isWelcome={userStore.isWelcome}
            isRedeemed={userStore.isRedeemed}
            isCommunityLimited={!userStore.userLimitations.community || userStore.isReadOnlyMode}
            isDemoOrReadOnlyMode={userStore.isDemoOrReadOnlyMode}
            isMobileGetStarted={uiStore.showMobileGetStarted}
            isPricingTableOpen={billingStore.isShowPricingTablePopup}
            isMobileScreen={uiStore.isMobileScreenSize}
            isDesktopView={false}
            skipGetStartedGuid={userStore.skipGetStartedGuid}
            isPricingModalPage={userStore.isRelevantVariant === 3}
            isTier4FirstTimeUserExperience={userStore.data?.profile?.showTier4FirstTimeExperiencePopup}
            metaDataLoaded={metadataStore.isLoaded} />
        }

        {(userStore.isAuthenticated && !userStore.isWelcome && !uiStore.isRouteWithEmptySideBarLayout && !userStore.isNoPortfolioSelected && !userStore.data?.profile?.isOnBoarding) && <MobileFooter isHidden={!uiStore.isAppMobileMenuOpen && (uiStore.isRouteWithoutFooter || (!uiStore.isMobileScrollOnTop && !uiStore.isMobileScrollOnBottom))} />}
        {billingStore.isShowPricingTablePopup && <MobilePricingModal onClose={() => { billingStore.setShowPricingTableModal(false) }} />}
        {billingStore.showComparePlansModal && <ComparePlansModal onClose={() => { billingStore.setShowComparePlansModal(false) }} />}
        {!userStore.isViewingOwnPortfolio && <LimitedSharedAccess variant='drawer' visible={userStore.showLimitedSharedAccess} onClose={() => { userStore.setShowLimitedSharedAccess(false) }} portfolioDetails={userStore.currentPortfolioDetails} />}
        {billingStore.showStarterModal && <StarterPlanModal onClose={() => { billingStore.setShowStarterModal(false) }} />}

        <FiltersPanel />
        <Drawer
          closable={false}
          visible={uiStore.isAppMobileMenuOpen}
          width={'100%'}
          className={'appMenuDrawerContainer_mobile'}
          placement={'left'}
          push={false}
          onClose={() => { uiStore.setIsAppMobileMenuOpen(false) }}>
          <MobileMoreMenu className="sidebar" />
        </Drawer>
        {uiStore.showLogoutModal && <SessionExpirationModal onBackDropClick={() => { }} />}
      </AppWrapper>
    </>
  )}
  </Observer>);
}
