import { makeAutoObservable } from "mobx";
import moment from "moment";

// const LS_KEY = 'data-layers';
// const MAX_SELECTION_COUNT = 4;

export const inflowOutflowTimeFrameOptions = [
    { label: 'Years', value: 'years' },
    { label: 'Quarters', value: 'quarters' },
    { label: 'Months', value: 'months' },
    { label: 'Total (sum overall)', value: 'total' },
]

export const eventTypesOptions = [
    { label: 'Return on capital', value: 'roc' },
    { label: 'Return of capital', value: 'rfc' },
    { label: 'Contributions', value: 'ctr' },
    { label: 'Other expenses', value: 'oex' },
    { label: 'Cash Account', value: 'ca', hidden: true },
]

export class InflowOutflowDataLayersStore {
    timeFrame = 'years';
    endpointIndex = 0;
    selectedEventTypes = eventTypesOptions.map(op => op.value)

    constructor(metadataStore) {
        makeAutoObservable(this);
        this.metadataStore = metadataStore;
    }

    setTimeFrame(timeFrame) {
        this.timeFrame = timeFrame;
        this.endpointIndex = 0;
    }

    setEndpointIndex(index) {
        this.endpointIndex = index;
    }

    setSelectedEventTypes = (selection) => {
        if (this.selectedEventTypes.includes(selection)) {
            this.selectedEventTypes = this.selectedEventTypes.filter(s => s !== selection);

            if (this.selectedEventTypes.filter(i => i !== 'ca').length === 0) {
                this.selectedEventTypes = []
            }
        } else {

            this.selectedEventTypes = [...this.selectedEventTypes, selection];
            if (!this.selectedEventTypes.includes('ca')) {
                this.selectedEventTypes = [...this.selectedEventTypes, 'ca']
            }
        }
    }

    toggleAllEventTypes() {
        if (this.selectedEventTypes.length === eventTypesOptions.length
            // Object.keys(inflowOutflowEventsTypeOptions).length
        ) {
            this.selectedEventTypes = [];
        } else {
            this.selectedEventTypes = eventTypesOptions.map(op => op.value);//Object.keys(inflowOutflowEventsTypeOptions);
        }
    }

    get selectedTimeFrameLabel() {
        return inflowOutflowTimeFrameOptions.find(op => op.value === this.timeFrame)?.label || '';
    }

    get columns() {
        const today = moment();
        let columns = [];
        if (this.timeFrame === 'years') {

            const currentYear = today.year();
            for (let index = 0; index < 3; index++) {
                const point = moment().add((index + this.endpointIndex) * -1, "years");
                columns.push({ date: point, label: currentYear - (index + this.endpointIndex) });
            }
        }
        else if (this.timeFrame === 'quarters') {
            for (let index = 0; index < 4; index++) {
                const point = moment().add((index + this.endpointIndex) * -3, "months");
                const pointMonth = point.month();
                columns.push({ date: point, label: `Q${Math.floor(pointMonth / 3) + 1} ${point.year()}`, timeFrameIndex: Math.floor(pointMonth / 3) + 1 });
            }
        } else if (this.timeFrame === 'months') {
            for (let index = 0; index < 6; index++) {
                const point = moment().add((index + this.endpointIndex) * -1, "months");
                columns.push({ date: point, label: point.format("MMM YYYY") });
            }
        }
        else if (this.timeFrame === 'total') {
            columns.push({ date: null, label: 'Total Inflow / Outflow' })
        }
        return columns.reverse();
    }

    // get dataLayersColumns() {
    //     const flatAttrs = this.attributes.flatMap(attr => {
    //         const {subAttributes, ...attrProps} = attr;
    //         return [{...attrProps},...(subAttributes || [])]
    //     });
    //     return this.appliedDataLayers.map(dataLayerKey=>{
    //         const relevantAttribute = flatAttrs.find(attr => attr.dataKey === dataLayerKey);
    //         if (!relevantAttribute){return null}
    //         // const relevantParentAttribute = this.attributes.find(attr => attr.dataKey === dataLayerKey.split('_')[0]);
    //         // if (!relevantParentAttribute){return null}
    //         // const subAttribute = dataLayerKey.includes('_') ? relevantParentAttribute.subAttributes.find(sAttr => sAttr.dataKey === dataLayerKey) : null;
    //         // console.log(relevantAttribute)
    //         return {
    //             dataKey: dataLayerKey,
    //             type: relevantAttribute.type,
    //             path: relevantAttribute.path
    //             // isAttr : relevantParentAttribute.isAttr,
    //             // isItemProp: relevantParentAttribute.isItemProp,
    //             // parentDataKey: relevantParentAttribute.dataKey,
    //             // title: relevantParentAttribute.title,
    //             // ...( subAttribute ? { isSubAttribute : true, subDataKey: subAttribute.subKey} : {})
    //         }        
    //     }).filter(i=>i);;
    // }

}
