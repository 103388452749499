import { makeAutoObservable } from "mobx";
import moment from "moment";
import { CURRENCY_SYMBOL } from "../../../common/constants/currency-symbol.const";
import { ls } from "../../../common/utils/localstorage.util";
import { displayMoneyFormatter, percentageDisplay } from "../../../common/utils/number-display.utils";
import { capitalize } from "../../../common/utils/string.utils";
import {  getFiltersData } from "./filters.service";
import { liquidityFixedGroups, riskFixedGroups } from "../../allocations/data/allocations.const";
import { customClassColorsObj } from "../../user/components/CustomClassList/icons/custom-colors";

const FILTERS_KEY = 'filters';
const formatDate = (date) => (moment(date).format('MMM YYYY'))

const filtersParameters = {
    'holdingEntity': {title: 'Holding Entity' , type: 'list', displayFunction: (val)=>val === null ? 'Unspecified' : val},
    'classes': {title: 'Asset Classes' , type: 'classes'},
    'customClasses': {title: 'Custom Classes' , type: 'customClasses'},
    'institutions': {title: 'Institution / Sponsor' , type: 'sponsor',  displayFunction: (val)=>val === '' ? 'Unspecified' : val},
    'containers': {title: 'Investments account' , type: 'list', displayFunction: (val)=>val === null ? 'Unspecified' : val},
    'stocks': {title: 'Ticker name' , type: 'stocks', displayFunction: ()=>{}},
    'ownerships': {title: 'Ownership %' , type: 'list', displayFunction: (val) => percentageDisplay(val*100)},
    'assetsValue': {title: 'Asset Value' , type: 'range', displayFunction: ({from,to}) => `${displayMoneyFormatter(from,'USD')} - ${displayMoneyFormatter(to,'USD')}` },
    'riskLevel' : {title: 'Risk' , type : 'list' , displayFunction:(val)=>`Risk: ${riskFixedGroups.find((opt)=>opt.value === val).label}` }, 
    'liquidityLevel' : {title: 'Liquidity' , type : 'list' , displayFunction:(val)=>`Liquidity: ${liquidityFixedGroups.find((opt)=>opt.value === val).label}`}, 
    'startEndDate': {title: 'Start / End date' , type: 'startEndDate', displayFunction: ({from, to}) => `${formatDate(from)} - ${formatDate(to)}`},
    'itemStatus': {title: 'Item Status' , type: 'list', displayFunction: (val) => `Item status: ${capitalize(val)}`, options:[{value: 'active', label: 'Active', isSelected: false},{value: 'closed', label: 'Closed', isSelected: false}]},
    'currencies': {title: 'Currency' , type: 'list', displayFunction: (val)=>{return (CURRENCY_SYMBOL[val] ? (CURRENCY_SYMBOL[val] + ' - ') : '') + val}},
    'leveragedAssets': {title: 'Leveraged assets' , type: 'leveragedAssets', displayFunction: ()=>{} , leveragedAssetsId: {options:[]} , notLeveragedAssetsId:{options:[]}},
    'propertyType': {title: 'Property type' , type: 'list', displayFunction: (val)=>val === null ? 'Unspecified' : val},
    'companySector': {title: 'Company sector' , type: 'list', displayFunction: (val)=>val === null ? 'Unspecified' : val},
}

export const filtersOrder = [
    'holdingEntity',
    'classes',
    'customClasses',
    'institutions',
    'containers',
    'stocks',
    'ownerships',
    'assetsValue',
    'riskLevel',
    'liquidityLevel',
    'startEndDate',
    'itemStatus',
    'currencies',
    'leveragedAssets',
    'propertyType',
    'companySector'
]
export class FiltersStore {
    isLoading = true;
    showDrawer = false;
    parametersObj = filtersParameters;
    institutionStr = '';
    stockStr = '';
    selectedClasses = [];
    openClasses = [];
    selectedCategories = [];
    selectedCustomClasses = [];
    openCustomClasses = [];
    hasUnspecifiedCustomClass = false;
    customClassesWithUnspecifiedSubClass = [];
    filterKey = '';
    appliedFilters = {}; 
    visibleBreadcrumbsCount = this.appliedFilters.length;

    constructor(metadataStore){
        makeAutoObservable(this);
        this.metadataStore = metadataStore;
    }

    setShowDrawer(show) {
        this.showDrawer = show;
    }

    setLoading(isLoading){
        this.isLoading = isLoading;
    }
    
    setLSKey(userId){
        this.filterKey = FILTERS_KEY + '_' + userId;
        // this.urlParamsToAppliedFilters(window.location.search);
        this.appliedFilters = JSON.parse(ls.get(this.filterKey)) || {};
    }
    
    fetchData = async () =>{
        this.setLoading(true);
        try {
            const data = await getFiltersData();
            this.setData(data);
            this.setSelectedStateBasedOnAppliedFilters()
        } 
        catch (e) {} 
        finally {
            this.setLoading(false);
        }
    }

    setData(data) {
        this.parametersObj.holdingEntity.options = data.holdingEntity.map(option=>({value: option === 'Unspecified' ? null : option, label: this.parametersObj.holdingEntity.displayFunction(option),isSelected:false}));
        this.selectedClasses = [];
        this.openClasses = [];
        this.selectedCategories = [];
        this.selectedCustomClasses = [];
        this.openCustomClasses = [];
        this.parametersObj.holdingEntity.isHidden = this.parametersObj.holdingEntity.options.length === 0;
        this.parametersObj.institutions.options = data.institutions.map((option,index)=>({...option, index, value: option.name === 'Unspecified' ? '' : option.name , isSelected:false}));
        this.parametersObj.institutions.isHidden = this.parametersObj.institutions.options.length === 0;
        this.parametersObj.containers.options = data.containers.map(option=>({value: option === 'Unspecified' ? null : option, label: this.parametersObj.containers.displayFunction(option),isSelected:false}))
        this.parametersObj.containers.isHidden = this.parametersObj.containers.options.length === 0;
        this.parametersObj.stocks.options = data.stocks.map((option,index)=>({...option, index, isSelected:false}));
        this.parametersObj.stocks.isHidden = this.parametersObj.stocks.options.length === 0;
        this.parametersObj.ownerships.options = data.ownerships.map(option=>({ value: option, label:this.parametersObj.ownerships.displayFunction(option),isSelected:false}));
        this.parametersObj.ownerships.isHidden = this.parametersObj.ownerships.options.length === 0;
        this.parametersObj.riskLevel.options =  riskFixedGroups.map(({label,value})=>({value: value, label: label, isSelected: false})).filter(opt=>opt.value !== null || data.hasUnspecifiedRiskLevel ); 
        this.parametersObj.liquidityLevel.options = liquidityFixedGroups.map(({label,value})=>({value: value, label: label, isSelected: false})).filter(opt=>opt.value !== null || data.hasUnspecifiedLiquidityLevel );
        this.parametersObj.propertyType.options = data.propertyTypes.map(option=>({value: option === 'Unspecified' ? null : option, label:this.parametersObj.propertyType.displayFunction(option),isSelected:false}));
        this.parametersObj.propertyType.isHidden = this.parametersObj.propertyType.options.length === 0;
        this.parametersObj.companySector.options = data.companySectors.map(option=>({value: option === 'Unspecified' ? null : option, label:this.parametersObj.companySector.displayFunction(option),isSelected:false}));
        this.parametersObj.companySector.isHidden = this.parametersObj.companySector.options.length === 0;
        this.hasUnspecifiedCustomClass = data.hasUnspecifiedCustomClass;
        this.hasUnspecifiedCustomSubClass = data.hasUnspecifiedCustomSubClass;
        this.customClassesWithUnspecifiedSubClass = data.customClassesWithUnspecifiedSubClass;
                                                
        if (data.hasOwnProperty('assetsValue')){
            this.parametersObj.assetsValue.value = data.assetsValue;
            this.parametersObj.assetsValue.defaultValue = data.assetsValue;
            this.parametersObj.assetsValue.isHidden = false;
        } else {
            this.parametersObj.assetsValue.isHidden = true;
        }
        if (data.hasOwnProperty('startDate')){
            this.parametersObj.startEndDate.startDate = {from: data.startDate.from, to: data.startDate.to, defaultFrom: data.startDate.from, defaultTo: data.startDate.to, isSelected: false};
        } else {
            this.parametersObj.startEndDate.startDate = {isHidden : true};
        }
        if (data.hasOwnProperty('endDate')){
            this.parametersObj.startEndDate.endDate = {from: data.endDate.from, to: data.endDate.to, defaultFrom: data.endDate.from, defaultTo: data.endDate.to, isSelected:false, isNoEndDateIncluded: false};
        } else {
            this.parametersObj.startEndDate.endDate = {isHidden : true};
        }
        this.parametersObj.currencies.options = data.currencies.map((option,ind)=>({ value: option, label:this.parametersObj.currencies.displayFunction(option),isSelected:false}));
        this.parametersObj.currencies.isHidden = this.parametersObj.currencies.options.length === 0;
        if (data.hasOwnProperty('leveragedAssetsId')){
            this.parametersObj.leveragedAssets.leveragedAssetsId = {isSelected: false, isOpen: false ,  selectedCount: 0};
            this.parametersObj.leveragedAssets.leveragedAssetsId.options = data.leveragedAssetsId.map((option,index)=>({...option, index, isSelected:false}));
        } else {
            this.parametersObj.leveragedAssets.leveragedAssetsId = {isHidden : true,  selectedCount: 0};
        }
        if (data.hasOwnProperty('notLeveragedAssetsId')){
            this.parametersObj.leveragedAssets.notLeveragedAssetsId = {isSelected: false ,  selectedCount: 0};
            this.parametersObj.leveragedAssets.notLeveragedAssetsId.options = data.notLeveragedAssetsId.map((option,index)=>({...option, index, isSelected:false}));
        } else {
            this.parametersObj.leveragedAssets.notLeveragedAssetsId = {isHidden : true , selectedCount: 0};
        }
    }
    
    setSelectedStateBasedOnAppliedFilters() {
        const classesParameter = this.parametersObj['classes'];
        const customClassesParameter = this.parametersObj['customClasses'];
        const institutionsParameter = this.parametersObj['institutions'];
        const ownershipsParameter = this.parametersObj['ownerships'];
        const containersParameter = this.parametersObj['containers'];
        const riskParameter = this.parametersObj['riskLevel'];
        const liquidityParameter = this.parametersObj['liquidityLevel'];
        const propertyTypeParameter = this.parametersObj['propertyType'];
        const companySectorParameter = this.parametersObj['companySector'];
        const stocksParameter = this.parametersObj['stocks'];
        const assetsValueParameter = this.parametersObj['assetsValue'];
        const startDateParameter = this.parametersObj['startEndDate'].startDate;
        const endDateParameter = this.parametersObj['startEndDate'].endDate;
        const currenciesParameter = this.parametersObj['currencies'];
        const leveragedAssetsParameter = this.parametersObj['leveragedAssets'];
        const leveragedAssetsIdParameter = leveragedAssetsParameter.leveragedAssetsId;
        const notLeveragedAssetsIdParameter = leveragedAssetsParameter.notLeveragedAssetsId;
        const itemStatusParameter = this.parametersObj['itemStatus'];
    
        this.parameters.forEach(param => {
            param.selectedCount = 0;
            param.isOpen = false;
            if (param.hasOwnProperty('options')){
                param.options.forEach(option => {
                    option.isSelected = false;
                })
            }
        });
    
        if (this.appliedFilters.holdingEntity) {
            this.parametersObj['holdingEntity'].options = this.parametersObj['holdingEntity'].options.map(option => ({
                ...option,
                isSelected: this.appliedFilters.holdingEntity.includes(option.value)
            }));
            this.parametersObj['holdingEntity'].selectedCount = this.appliedFilters.holdingEntity.length;
        }
    
        if (this.appliedFilters.assetsCategories) {
            this.selectedCategories = this.appliedFilters.assetsCategories;
        }
        if (this.appliedFilters.assetsClasses) {
            classesParameter.selectedCount = this.appliedFilters.assetsClasses.length;
            this.selectedClasses = this.appliedFilters.assetsClasses;
        }

        if (this.appliedFilters.customClasses) {
            customClassesParameter.selectedCount = this.appliedFilters.customClasses.length;
            this.selectedCustomClasses = this.appliedFilters.customClasses;
        }
    
        if (this.appliedFilters.institutions) {
            institutionsParameter.options = institutionsParameter.options.map(option => ({
                ...option,
                isSelected: this.appliedFilters.institutions.includes(option.name)
            }));
            institutionsParameter.selectedCount = this.appliedFilters.institutions.length;
        }

        if (this.appliedFilters.stocks) {
            stocksParameter.options = stocksParameter.options.map(option => ({
                ...option,
                isSelected: this.appliedFilters.stocks.includes(option.name)
            }));
            stocksParameter.selectedCount = this.appliedFilters.stocks.length;
        }

        if (this.appliedFilters.itemStatus) {
            itemStatusParameter.options = itemStatusParameter.options.map(option => ({
                ...option,
                isSelected: this.appliedFilters.itemStatus.includes(option.value)
            }));
            itemStatusParameter.selectedCount = this.appliedFilters.itemStatus.length;
        }
    
        if (this.appliedFilters.ownerships) {
            ownershipsParameter.options = ownershipsParameter.options.map(option => ({
                ...option,
                isSelected: this.appliedFilters.ownerships.includes(+option.value)
            }));
            ownershipsParameter.selectedCount = this.appliedFilters.ownerships.length;
        }

        if (this.appliedFilters.containers) {
            containersParameter.options = containersParameter.options.map(option => ({
                ...option,
                isSelected: this.appliedFilters.containers.includes(option.value)
            }));
            containersParameter.selectedCount = this.appliedFilters.containers.length;
        }

        if (this.appliedFilters.riskLevel) {
            riskParameter.options = riskParameter.options.map(option => ({
                ...option,
                isSelected: this.appliedFilters.riskLevel.includes(option.value)
            }));
            riskParameter.selectedCount = this.appliedFilters.riskLevel.length;
        }

        if (this.appliedFilters.liquidityLevel) {
            liquidityParameter.options = liquidityParameter.options.map(option => ({
                ...option,
                isSelected: this.appliedFilters.liquidityLevel.includes(option.value)
            }));
            liquidityParameter.selectedCount = this.appliedFilters.liquidityLevel.length;
        }

        if (this.appliedFilters.propertyType) {
            propertyTypeParameter.options = propertyTypeParameter.options.map(option => ({
                ...option,
                isSelected: this.appliedFilters.propertyType.includes(option.value)
            }));
            propertyTypeParameter.selectedCount = this.appliedFilters.propertyType.length;
        }

        if (this.appliedFilters.companySector) {
            companySectorParameter.options = companySectorParameter.options.map(option => ({
                ...option,
                isSelected: this.appliedFilters.companySector.includes(option.value)
            }));
            companySectorParameter.selectedCount = this.appliedFilters.companySector.length;
        }
        
    
        if (this.appliedFilters.assetsValue) {
            assetsValueParameter.value = this.appliedFilters.assetsValue;
            assetsValueParameter.selectedCount = 1;
        }
    
        if (this.appliedFilters.startDate) {
            startDateParameter.from = this.appliedFilters.startDate.from;
            startDateParameter.to = this.appliedFilters.startDate.to;
            startDateParameter.isSelected = true;
        }
    
        if (this.appliedFilters.endDate) {
            endDateParameter.from = this.appliedFilters.endDate.from;
            endDateParameter.to = this.appliedFilters.endDate.to;
            endDateParameter.isSelected = true;
            endDateParameter.isNoEndDateIncluded = this.appliedFilters.endDate.includedNull;
        }

        if (this.appliedFilters.leveragedAssetsId) {
            leveragedAssetsIdParameter.isSelected = true;
            leveragedAssetsIdParameter.options = leveragedAssetsIdParameter.options.map(option => ({ 
                ...option,
                isSelected: option.assetsId.every(astId=>this.appliedFilters.leveragedAssetsId.includes(''+astId))
            }));
            leveragedAssetsIdParameter.selectedCount = leveragedAssetsIdParameter.options.reduce((acc,option)=>acc+option.isSelected,0);
        }

        if (this.appliedFilters.notLeveragedAssetsId) {
            notLeveragedAssetsIdParameter.isSelected = true;
            notLeveragedAssetsIdParameter.options = notLeveragedAssetsIdParameter.options.map(option => ({
                ...option,
                isSelected: option.assetsId.every(astId=>this.appliedFilters.notLeveragedAssetsId.includes(''+astId))
            }));
            notLeveragedAssetsIdParameter.selectedCount = notLeveragedAssetsIdParameter.options.reduce((acc,option)=>acc+option.isSelected,0);
        }
        leveragedAssetsParameter.selectedCount = ( leveragedAssetsIdParameter.selectedCount ? 1 : 0 ) + ( notLeveragedAssetsIdParameter.selectedCount ? 1 : 0);
        // if (this.appliedFilters.leveragedAssetsId || this.appliedFilters.notLeveragedAssetsId) {
        //     leveragedAssetsParameter.selectedCount = leveragedAssetsIdParameter.selectedCount + notLeveragedAssetsIdParameter.selectedCount;
        // }
    
        if (this.appliedFilters.currencies) {
            currenciesParameter.options = currenciesParameter.options.map(option => ({
                ...option,
                isSelected: this.appliedFilters.currencies.includes(option.value)
            }));
            currenciesParameter.selectedCount = this.appliedFilters.currencies.length;
        }
    
        this.setStartEndDateSelectedCount();
    }
  
    setParameterIsOpen(paramIndex,isOpen){
        this.parameters[paramIndex].isOpen = isOpen;
    }

    setParameterOpenByType(parameterType) {
        const parameterKey = parameterType === 'startDate' || parameterType === 'endDate' ? 'startEndDate' : parameterType;
        const parameter = this.parametersObj[parameterKey];
        if (parameter) {
            parameter.isOpen = true;
        }
    }

    setListOptionSelected(paramIndex,optionIndex,isSelected) {
        this.parameters[paramIndex].options[optionIndex].isSelected = isSelected;
        const selectedCount = this.parameters[paramIndex].options.reduce((a,b)=>a + (b.isSelected ? 1 : 0), 0);
        this.parameters[paramIndex].selectedCount = selectedCount;
    }

    setListOptionSelectedFromAllocation(filterName,value) {
        const filter = this.parametersObj[filterName];
        filter.options = filter.options.map(option => ({
            ...option, isSelected: option.value === value || option.name === value 
        }))
        filter.selectedCount = 1;
        filter.isSelected = true;
    }

    setInstitutionStr(searchStr) {
        this.institutionStr = searchStr;
    }

    setStockStr(searchStr) {
        this.stockStr = searchStr;
    }

    setStartEndDateSelected(key, isSelected) {
        const startEndDateParameter = this.parametersObj['startEndDate'];
    
        startEndDateParameter[key].isSelected = isSelected;
    
        this.setStartEndDateSelectedCount();
    }

    setLeveragedAssetsSelected(key, isSelected) {
        
        const leveragedAssetsParameter = this.parametersObj['leveragedAssets'];
        const leveragedAssetsIdParameter = leveragedAssetsParameter.leveragedAssetsId;
        const notLeveragedAssetsIdParameter = leveragedAssetsParameter.notLeveragedAssetsId;
        
        
        leveragedAssetsParameter[key].isSelected = isSelected;
        leveragedAssetsParameter[key].options = leveragedAssetsParameter[key].options.map(option => ({
            ...option, isSelected: isSelected
        }))
        leveragedAssetsParameter[key].selectedCount = leveragedAssetsParameter[key].options.reduce((acc,option)=>acc+option.isSelected,0);

        if (isSelected){
            const secondKey = key === 'leveragedAssetsId' ? 'notLeveragedAssetsId' : 'leveragedAssetsId';
            leveragedAssetsParameter[secondKey].isSelected = false;
            leveragedAssetsParameter[secondKey].isOpen = false;
            leveragedAssetsParameter[secondKey].selectedCount = 0;
            leveragedAssetsParameter[secondKey].options = leveragedAssetsParameter[secondKey].options.map(option => ({
                ...option, isSelected: false
            }))
        }
    
        leveragedAssetsParameter.selectedCount = ( leveragedAssetsIdParameter.selectedCount ? 1 : 0 ) + ( notLeveragedAssetsIdParameter.selectedCount ? 1 : 0);
    }
    
    setStartEndDate(optionKey, dataKey, date) {
        const startEndDateParameter = this.parametersObj['startEndDate'];
        startEndDateParameter[optionKey][dataKey] = date ? moment(date).format('YYYY-MM') : startEndDateParameter[optionKey]['default' + capitalize(dataKey)];
    
        this.setStartEndDateSelectedCount();
    }

    setStartEndDateSelectedCount() {
        const startEndDateParameter = this.parametersObj['startEndDate'];
        const startDate = startEndDateParameter.startDate;
        const endDate = startEndDateParameter.endDate;
    
        startEndDateParameter.selectedCount = 
            (startDate.isSelected && (
                (startDate.to !== startDate.defaultTo) || (startDate.from !== startDate.defaultFrom)
            ) ? 1 : 0)
            +
            (endDate.isSelected && (
                (endDate.to !== endDate.defaultTo) || (endDate.from !== endDate.defaultFrom) || endDate.isNoEndDateIncluded
            ) ? 1 : 0);
    }
    
    setStartEndDateIncludeNoEndDate(checked) {
        const endDateParameter = this.parametersObj['startEndDate'].endDate;
        endDateParameter.isNoEndDateIncluded = checked;
        this.setStartEndDateSelectedCount();
    }
    
    setRangeValues(paramIndex, from, to) {
        this.parameters[paramIndex].value = { from, to };
        this.parameters[paramIndex].selectedCount = ( from ===  this.parameters[paramIndex].defaultValue.from &&  to ===  this.parameters[paramIndex].defaultValue.to ) ? 0 : 1;
    }

    setVisibleBreadcrumbsCount(count) {
        this.visibleBreadcrumbsCount = count;
    }

    setAppliedFilters(filters) {
        this.appliedFilters = filters;
        this.appliedFiltersToUrlParams();
        ls.set(this.filterKey, JSON.stringify(filters));
    }

    handleSelectClass(classId) {
        const classCategories = this.metadataStore.categories.filter(cat => cat.classId === classId);
        const classCategoriesIds = classCategories.map(cat => cat.id);
    
        if (this.selectedClasses.includes(classId)) {
            this.selectedClasses = this.selectedClasses.filter(c => c !== classId);
            this.openClasses = this.openClasses.filter(c => c !== classId);
            this.selectedCategories = this.selectedCategories.filter(c => !classCategoriesIds.includes(c));
        } else {
            this.selectedClasses = [...this.selectedClasses, classId];
            this.selectedCategories = [...this.selectedCategories, ...classCategoriesIds];
            // this.selectedClasses.push(classId);
            // this.selectedCategories.push(...classCategoriesIds);
        }
    
        this.parametersObj['classes'].selectedCount = this.selectedClasses.length;
    }

    handleOpenCloseClass(classId) {
        if (this.openClasses.includes(classId)) {
            this.openClasses = this.openClasses.filter(c => c !== classId);
        } else {
            this.openClasses.push(classId);
        }
    
        const classCategoriesIds = this.metadataStore.categories
            .filter(cat => cat.classId === classId && !cat.isHidden)
            .map(cat => cat.id);
    
        const isNoCategorySelected = !classCategoriesIds.some(catId => this.selectedCategories.includes(catId));
    
        if (isNoCategorySelected) {
            this.selectedClasses = this.selectedClasses.filter(c => c !== classId);
            this.parametersObj['classes'].selectedCount = this.selectedClasses.length;
        }
    }

    handleSelectAllCategories(classId, isAllSelected){
        const classCategoriesIds = this.metadataStore.categories.filter(cat => cat.classId === classId).map(cat=>cat.id);
        if (isAllSelected){
            this.selectedCategories = this.selectedCategories.filter(c=>!classCategoriesIds.includes(c));
        } else {
            this.selectedCategories = [...this.selectedCategories,...classCategoriesIds];
        }
    }

    handleSelectCategory(categoryId){
        this.selectedCategories = this.selectedCategories.includes(categoryId) ? 
            this.selectedCategories.filter(c=>c!==categoryId) : 
            [...this.selectedCategories,categoryId];
    }

    handleSelectCustomClass(classId) {
        // const classCategories = this.metadataStore.categories.filter(cat => cat.classId === classId);
        // const classCategoriesIds = classCategories.map(cat => cat.id);
        // console.log('classId', classId , this.metadataStore.customClasses)
        const customClassSubClassesIds = this.metadataStore.customClasses.find(cls=>cls.id === classId)?.customSubClasses.map(subCls=>subCls.id);
        if (this.customClassesWithUnspecifiedSubClass?.includes(classId)){
            customClassSubClassesIds.push(null);
        }
        
        const isCustomClassSelected = this.selectedCustomClasses.find(customClassObj=> customClassObj.id === classId);
        
        if (isCustomClassSelected) {
            if (classId === null && isCustomClassSelected.customSubClasses?.length > 0){
                this.selectedCustomClasses = this.selectedCustomClasses.map(c => c.id !== classId ? c : {...c, customSubClasses: []});
            } else {
                this.selectedCustomClasses = this.selectedCustomClasses.filter(c => c.id !== classId);
                this.openCustomClasses = this.openCustomClasses.filter(c => c !== classId);
            }
        } else {
            this.selectedCustomClasses = [...this.selectedCustomClasses, {id: classId, customSubClasses: customClassSubClassesIds} ];
        }
    
        this.parametersObj['customClasses'].selectedCount = this.customClasses.filter(c=>c.isSelected).length; // this.selectedCustomClasses.length;
    }

    handleOpenCloseCustomClass(classId) {
        if (this.openCustomClasses.includes(classId)) {
            this.openCustomClasses = this.openCustomClasses.filter(c => c !== classId);
        } else {
            this.openCustomClasses.push(classId);
        }
        
        const customClassSubClassesIds = this.metadataStore.customClasses.find(cls=>cls.id === classId).customSubClasses.map(subCls=>subCls.id);
        if (this.customClassesWithUnspecifiedSubClass?.includes(classId)){
            customClassSubClassesIds.push(null);
        }
        
        if (this.selectedCustomClasses.find(customClassObj=> customClassObj.id === classId)?.customSubClasses.length === 0){
            this.selectedCustomClasses = this.selectedCustomClasses.filter(c => c.id !== classId);
            this.parametersObj['customClasses'].selectedCount = this.selectedCustomClasses.length;
        }
    }

    handleSelectAllSubClasses(classId, isAllSelected){
        const customClassObj = this.customClasses.find(cls=>cls.id === classId);
        const customClassSubClassesIds = customClassObj.subClasses.map(subCls=>subCls.id);
        let updatedCustomSubClasses = []
        if (!customClassObj.isAllSubClassesSelected){
            updatedCustomSubClasses = customClassSubClassesIds;
        }    
        this.selectedCustomClasses = this.selectedCustomClasses.map(customClassObj=> customClassObj.id === classId ? {...customClassObj, customSubClasses: updatedCustomSubClasses} : customClassObj);
    }

    handleSelectSubClass(classId,subClassId){

        this.selectedCustomClasses = this.selectedCustomClasses.map(customClassObj=> customClassObj.id === classId ? 
            {...customClassObj, 
                customSubClasses: customClassObj.customSubClasses.includes(subClassId) ? customClassObj.customSubClasses.filter(c=>c!==subClassId) : [...customClassObj.customSubClasses,subClassId]} 
            : customClassObj);
        
    }

    handleSelectContainer(containerName){
        const containerOption = this.parametersObj.containers.options.find(o=>o.value === containerName);
        if (containerOption){
            containerOption.isSelected = true;    
        }
        const selectedCount = this.parametersObj.containers.options.reduce((a,b)=>a + (b.isSelected ? 1 : 0), 0);
        this.parametersObj.containers.selectedCount = selectedCount;
    }

    handleOpenCloseLoansWithCollaterals() {
        const leveragedAssetsIdParameter = this.parametersObj['leveragedAssets'].leveragedAssetsId;
        leveragedAssetsIdParameter.isOpen = !leveragedAssetsIdParameter.isOpen;
    }

    handleSelectCategoryFromAllocation(classId , categoryId){
        this.selectedClasses = [classId];
        this.selectedCategories = [categoryId];
        this.parametersObj['classes'].selectedCount = this.selectedClasses.length;
    }

    handleSelectSubClassFromAllocation(classId , subClassId){
        this.selectedCustomClasses = [{id: classId, customSubClasses: [subClassId]}];        
        this.parametersObj['customClasses'].selectedCount = this.customClasses.filter(c=>c.isSelected).length;
    }

    handleSelectUnspecifiedSubClass(isSelected){
        this.selectedCustomClasses = isSelected ? this.selectedCustomClasses.filter(c=>c.id !== null) : [...this.selectedCustomClasses , {id: null, customSubClasses: [null]}];        
        this.parametersObj['customClasses'].selectedCount = this.customClasses.filter(c=>c.isSelected).length;
    }

    handleSelectAllLoanWithCollateral(dataKey, setAllSelected){
        const leveragedAssetsParameter = this.parametersObj['leveragedAssets'];
        const leveragedAssetsInnerParameter = leveragedAssetsParameter[dataKey];
        leveragedAssetsInnerParameter.options = leveragedAssetsInnerParameter.options.map(option=> ({...option, isSelected: setAllSelected}));
        leveragedAssetsInnerParameter.selectedCount = setAllSelected ? leveragedAssetsInnerParameter.options.length : 0;
        leveragedAssetsInnerParameter.isSelected = setAllSelected;
        leveragedAssetsParameter.selectedCount = (leveragedAssetsInnerParameter.isSelected ? 1 : 0);
    }

    handleSelectLoanWithCollateral(loanId, isSelected){
        const leveragedAssetsParameter = this.parametersObj['leveragedAssets'];
        const leveragedAssetsIdParameter = leveragedAssetsParameter.leveragedAssetsId;
        // const notLeveragedAssetsIdParameter = leveragedAssetsParameter.notLeveragedAssetsId;
        leveragedAssetsIdParameter.options = leveragedAssetsIdParameter.options.map(option=>option.liabilityId===loanId ? {...option, isSelected: isSelected}: option);
        leveragedAssetsIdParameter.selectedCount = leveragedAssetsIdParameter.options.reduce((acc,option)=>acc+option.isSelected,0);
        leveragedAssetsIdParameter.isSelected = leveragedAssetsIdParameter.selectedCount > 0;
        leveragedAssetsParameter.selectedCount = (leveragedAssetsIdParameter.isSelected ? 1 : 0);
    }

    handleClearFilterSelections(clearApplied = true) {
        this.selectedClasses = [];
        this.openClasses = [];
        this.selectedCategories = [];
        this.selectedCustomClasses = [];
        this.openCustomClasses = [];
    
        this.parameters.forEach((param,ind) => {
            if (param.options) {
                param.options.forEach(opt => opt.isSelected = false);
            } else if (param.type === 'startEndDate') {
                if (param.startDate){
                    param.startDate.isSelected = false;
                }
                if (param.endDate){
                    param.endDate.isSelected = false;
                    param.endDate.isNoEndDateIncluded = false;
                }
            } else if (param.type === 'leveragedAssets'){
                param.leveragedAssetsId.isSelected = false;
                param.leveragedAssetsId.options.forEach(opt => opt.isSelected = false);
                param.leveragedAssetsId.selectedCount = 0;
                param.notLeveragedAssetsId.isSelected = false;
                param.notLeveragedAssetsId.options.forEach(opt => opt.isSelected = false);
                param.notLeveragedAssetsId.selectedCount = 0;
            }
            param.selectedCount = 0;
        });
    
        if (clearApplied) {
            this.setAppliedFilters({});
        }
    
        ls.remove(this.filterKey);
    }

    closeAllParameters() {
        this.parameters.forEach(param => param.isOpen = false);
    }

    removeFilter(filterKey, deselectData) {
        const relevantParameter = this.parametersObj[filterKey];

        if (['holdingEntity','ownerships','currencies','containers','riskLevel','propertyType','companySector','liquidityLevel','institutions','stocks','itemStatus'].includes(filterKey)) {
            const relevantOption = relevantParameter?.options?.filter(i=>i.isSelected)?.find((opt,ind)=> opt.value + `_${ind}` === deselectData || opt.name + ind === deselectData); 
            if (relevantOption){
                relevantOption.isSelected = false;
            }
            relevantParameter.selectedCount = relevantParameter.options.filter(opt => opt.isSelected).length;
        }
    
        if (filterKey === 'classes') {
            const classCategoriesIds = this.metadataStore.categories
                .filter(cat => cat.classId === deselectData)
                .map(cat => cat.id);
            this.selectedClasses = this.selectedClasses.filter(c => c !== deselectData);
            this.selectedCategories = this.selectedCategories.filter(c => !classCategoriesIds.includes(c));
            relevantParameter.selectedCount--;
        }

        if (filterKey === 'customClasses') {
            this.selectedCustomClasses = this.selectedCustomClasses.filter(c => c.id !== deselectData);
            relevantParameter.selectedCount--;
        }
    
        if (filterKey === 'assetsValue') {
            relevantParameter.value = relevantParameter.defaultValue;
            relevantParameter.selectedCount--;
        }
    
        if (filterKey === 'startDate' || filterKey === 'endDate') {
            const startEndDateParameter = this.parametersObj['startEndDate'];
            startEndDateParameter[filterKey] = {
                ...startEndDateParameter[filterKey],
                from: startEndDateParameter[filterKey].defaultFrom,
                to: startEndDateParameter[filterKey].defaultTo,
                isSelected: false,
                ...(filterKey === 'endDate' ? { isNoEndDateIncluded: false } : {})
            };
            this.setStartEndDateSelectedCount();
        }

        if (filterKey === 'leveragedAssetsId' || filterKey === 'notLeveragedAssetsId') {
            const leveragedAssetsParameter = this.parametersObj['leveragedAssets'];
            leveragedAssetsParameter[filterKey] = {
                ...leveragedAssetsParameter[filterKey],
                options: leveragedAssetsParameter[filterKey].options.map(option => ({
                    ...option,
                    isSelected: false
                })),
                isSelected: false,
                selectedCount: 0,
            };
            leveragedAssetsParameter.selectedCount = 0;
        }
    
        this.setAppliedFiltersFromSelection();
    }

    setAppliedFiltersFromSelection() {
        const appliedFilters = {};
    
        if (this.selectedClasses.length) {
            appliedFilters.assetsClasses = this.selectedClasses;
        }
    
        if (this.selectedCategories.length) {
            appliedFilters.assetsCategories = this.selectedCategories;
        }

        if (this.selectedCustomClasses.length) {
            appliedFilters.customClasses = this.selectedCustomClasses;
        }
    
        const holdingEntityParameter = this.parametersObj['holdingEntity'];
        if (holdingEntityParameter.selectedCount > 0) {
            appliedFilters.holdingEntity = holdingEntityParameter?.options?.filter(opt => opt.isSelected).map(opt => opt.value);
        }
    
        const institutionsParameter = this.parametersObj['institutions'];
        if (institutionsParameter.selectedCount > 0) {
            appliedFilters.institutions = institutionsParameter?.options?.filter(opt => opt.isSelected).map(opt => opt.value);
        }
    
        const ownershipsParameter = this.parametersObj['ownerships'];
        if (ownershipsParameter.selectedCount > 0) {
            appliedFilters.ownerships = ownershipsParameter?.options?.filter(opt => opt.isSelected).map(opt => +opt.value);
        }
    
        const assetsValueParameter = this.parametersObj['assetsValue'];
        if (assetsValueParameter.selectedCount > 0) {
            appliedFilters.assetsValue = { from: '' + assetsValueParameter.value.from, to: '' + assetsValueParameter.value.to };
        }
    
        const startDateParameter = this.parametersObj['startEndDate'].startDate;
        const endDateParameter = this.parametersObj['startEndDate'].endDate;
        if (startDateParameter?.isSelected && ((startDateParameter.to !== startDateParameter.defaultTo) || (startDateParameter.from !== startDateParameter.defaultFrom))) {
            appliedFilters.startDate = { from: startDateParameter.from, to: startDateParameter.to };
        }
        if (endDateParameter?.isSelected && ((endDateParameter.to !== endDateParameter.defaultTo) || (endDateParameter.from !== endDateParameter.defaultFrom) || endDateParameter.isNoEndDateIncluded)) {
            appliedFilters.endDate = { from: endDateParameter.from, to: endDateParameter.to, includedNull: endDateParameter.isNoEndDateIncluded };
        }
        
        const leveragedAssetsIdParameter = this.parametersObj['leveragedAssets'].leveragedAssetsId;
        const notLeveragedAssetsIdParameter = this.parametersObj['leveragedAssets'].notLeveragedAssetsId;
        if (leveragedAssetsIdParameter?.selectedCount > 0) {
            // eslint-disable-next-line
            appliedFilters.leveragedAssetsId = leveragedAssetsIdParameter.options.reduce((acc, option) => (option.isSelected && acc.push(...option.assetsId.map(id=>''+id)), acc), []);
        }
        if (notLeveragedAssetsIdParameter?.selectedCount > 0) {
            // eslint-disable-next-line
            appliedFilters.notLeveragedAssetsId = notLeveragedAssetsIdParameter.options.reduce((acc, option) => (option.isSelected && acc.push(...option.assetsId.map(id=>''+id)), acc), []);
        }
    
        const currenciesParameter = this.parametersObj['currencies'];
        if (currenciesParameter.selectedCount > 0) {
            appliedFilters.currencies = currenciesParameter?.options?.filter(opt => opt.isSelected).map(opt => opt.value);
        }

        const containersParameter = this.parametersObj['containers'];
        if (containersParameter.selectedCount > 0) {
            appliedFilters.containers = containersParameter?.options?.filter(opt => opt.isSelected).map(opt => opt.value);
        }   

        const riskParameter = this.parametersObj['riskLevel'];
        if (riskParameter.selectedCount > 0) {
            appliedFilters.riskLevel = riskParameter?.options?.filter(opt => opt.isSelected).map(opt => opt.value);
        }

        const propertyTypeParameter = this.parametersObj['propertyType'];
        if (propertyTypeParameter.selectedCount > 0) {
            appliedFilters.propertyType = propertyTypeParameter?.options?.filter(opt => opt.isSelected).map(opt => opt.value);
        }

        const companySectorParameter = this.parametersObj['companySector'];
        if (companySectorParameter.selectedCount > 0) {
            appliedFilters.companySector = companySectorParameter?.options?.filter(opt => opt.isSelected).map(opt => opt.value);
        }
        
        const liquidityParameter = this.parametersObj['liquidityLevel'];
        if (liquidityParameter.selectedCount > 0) {
            appliedFilters.liquidityLevel = liquidityParameter?.options?.filter(opt => opt.isSelected).map(opt => opt.value);
        }

        const stocksParameter = this.parametersObj['stocks'];
        if (stocksParameter.selectedCount > 0) {
            appliedFilters.stocks = stocksParameter?.options?.filter(opt => opt.isSelected).map(opt => opt.name);
        }

        const itemStatusParameter = this.parametersObj['itemStatus'];
        if (itemStatusParameter.selectedCount > 0) {
            appliedFilters.itemStatus = itemStatusParameter?.options?.filter(opt => opt.isSelected).map(opt => opt.value);
        }
    
        this.setAppliedFilters(appliedFilters);
    }

    appliedFiltersToUrlParams() {
        const filterKeys = Object.keys(this.appliedFilters);
        if (filterKeys.length === 0){
          return '';
        }
        let urlResult = '?filters=1';
        filterKeys.forEach(key => {
            const value = this.appliedFilters[key];
            if(Array.isArray(value)) {
                urlResult += '&'+key+'=' + value.join('&'+key+'=');
            } else if(key === 'assetsValue') {
                urlResult += '&avFROM=' + value.from + '&avTO=' + value.to
            } else if(key === 'startDate') {
                urlResult += '&sdFROM=' + value.from + '&sdTO=' + value.to
            } else if(key === 'endDate') {
                urlResult += '&edFROM=' + value.from + '&edTO=' + value.to + (value.includedNull ? '&edIncludedNull=' + this.appliedFilters[key].includedNull : '')
            }
        });
        return urlResult;
    }

    urlParamsToAppliedFilters(input) {
        const arrayParams = ['assetsClasses','assetsCategories','holdingEntity','institutions','ownerships','currencies','containers','riskLevel','propertyType','companySector' ,'liquidityLevel','stocks','leveragedAssetsId','notLeveragedAssetsId','itemStatus'];
        const simpleParams = ['avFROM','avTO','sdFROM','sdTO','edFROM','edTO','edIncludedNull']
        const groupParamsByKey = (params) => [...params.entries()].reduce((acc, tuple) => {
            // getting the key and value from each tuple
            const [ _key, val ] = tuple;
            const key = _key.replace(/[]$/, ''); /* eslint-disable-line no-empty-character-class */
            if(acc.hasOwnProperty(key)) {
                // if the current key is already an array, we'll add the value to it
                if(Array.isArray(acc[key])) {
                // if(arrayParams.includes(key)) {
                    acc[key] = [...acc[key], val]
                } else {
                    // if it's not an array, but contains a value, we'll convert it into an array
                    // and add the current value to it
                    acc[key] = [acc[key], val];
                }
            } else if(simpleParams.includes(key) || arrayParams.includes(key)) {
                // console.log(key);
                // plain assignment if no special case is present
                acc[key] = val;
            }
        
        return acc;
        }, {});
        
        const params = new URLSearchParams(input);
        //  const params = new URLSearchParams('abc=foo&def=asf&xyz=5&def=dude');
        const output = groupParamsByKey(params) // {abc: "foo", def: ["[asf]", "dude"], xyz: 5}
        Object.keys(output).forEach(key=>{
            if(arrayParams.includes(key) && !Array.isArray(output[key])) {
                output[key] = [output[key]];
            }
        })
        return output;
    }

    get classes() {
        return this.metadataStore && this.metadataStore.classes.map(classItem=>{
            const isSelected = this.selectedClasses.includes(classItem.id);
            const isOpen = this.openClasses.includes(classItem.id);
            let categories = [];
            if (isSelected){
                const classCategories = this.metadataStore.categories.filter(cat => cat.classId === classItem.id && !cat.isHidden);
                categories = classCategories.map(category=>{
                    const isSelected = this.selectedCategories.includes(category.id);
                    return {...category,isSelected}
                });
            }            
            const selectedCategoriesCount = categories.reduce((a,b)=>a + b.isSelected, 0);
            const isAllCategoriesSelected = selectedCategoriesCount === categories.length;
            return {
                ...classItem,
                isSelected, 
                isOpen,
                categories,
                selectedCategoriesCount,
                isAllCategoriesSelected
            }
        });
    }

    get customClasses() {
        const displayedCustomClasses =  this.metadataStore && this.metadataStore.customClasses.map(customClassItem=>{
            const selectedCustomClass = this.selectedCustomClasses.find(customClassObj=> customClassObj.id === customClassItem.id);
            const isSelected = selectedCustomClass !== undefined;
            const isOpen = this.openCustomClasses.includes(customClassItem.id);
            const customClassColor = customClassColorsObj[customClassItem.colorCode]?.fallbackColor;
            // const 
            let subClasses = [];
            if (isSelected){
                subClasses = customClassItem.customSubClasses.map(subClass=>{
                    const isSelected = selectedCustomClass && selectedCustomClass.customSubClasses.includes(subClass.id);
                    return {...subClass,isSelected}
                });
                // TODO - add this only if there is unspecified subClass for this class
                if (subClasses.length > 0 && this.customClassesWithUnspecifiedSubClass?.includes(customClassItem.id) ){
                    subClasses.push({
                        id : null,
                        name: 'Unspecified',
                        isSelected: selectedCustomClass && selectedCustomClass.customSubClasses.includes(null)
                    })
                }
            }            
            const selectedSubClassesCount = subClasses.reduce((a,b)=>a + b.isSelected, 0);
            const selectedSubClassesTitles = subClasses.filter(sc=>sc.isSelected).map(sc=>sc.name); // reduce((a,b)=>a + b.isSelected, 0);
            const isAllSubClassesSelected = selectedSubClassesCount === subClasses.length;
            return {
                id: customClassItem.id,
                name: customClassItem.name,
                color: customClassColor,
                isSelected, 
                isOpen,
                subClasses,
                selectedSubClassesCount,
                selectedSubClassesTitles,
                isAllSubClassesSelected
            }
        });

        return [...displayedCustomClasses,
            ...(this.hasUnspecifiedCustomClass ? [{
                id: null,
                name: 'Unspecified Class' ,
                color: '#585D66',
                isSelected: this.selectedCustomClasses.find(customClassObj=> customClassObj.id === null) !== undefined && !this.isUnspecifiedCustomSubClassesSelected,
                isOpen: this.openCustomClasses.includes(null),
                subClasses:[],
                selectedSubClassesCount: 0,
                isAllSubClassesSelected : false
            }]: []),
            ...(this.hasUnspecifiedCustomSubClass ? [{
                id: null,
                name: 'Unspecified Sub-class' ,
                isUnspecifiedCustomSubClasses: true,
                color: '#585D66',
                isSelected: this.isUnspecifiedCustomSubClassesSelected, // this.selectedCustomClasses.find(customClassObj=> customClassObj.id === null) !== undefined,
                isOpen: this.openCustomClasses.includes(null),
                subClasses:[],
                selectedSubClassesCount: 0,
                isAllSubClassesSelected : false
            }]: []),
        ]
        
    }

    get institutionSearchResults() {
        return this.parametersObj['institutions']?.options?.filter(opt => opt.name?.toLowerCase().includes(this.institutionStr?.toLowerCase()));
    }

    get stockSearchResults() {
        return this.parametersObj['stocks']?.options?.filter(opt => opt.name?.toLowerCase().includes(this.stockStr?.toLowerCase()));
    }
    
    get filterTags() {
        const selectedHoldingEntities = this.appliedFilters['holdingEntity']?.map((he, ind) => ({
            deselectData: he + `_${ind}`,
            type: 'holdingEntity',
            title: this.parametersObj['holdingEntity'].displayFunction(he),
            titlePrefix: 'Entity: ',
            tagColor: ''
        }));
    
        const ownershipsTags = this.appliedFilters['ownerships']?.map((own, ind) => ({
            deselectData: own + `_${ind}`,
            type: 'ownerships',
            title: this.parametersObj['ownerships'].displayFunction(own),
            titlePrefix: 'Owned: ',
            tagColor: ''
        }));
    
        const assetsValueTag = this.appliedFilters['assetsValue'] ? [{
            type: 'assetsValue',
            title: this.parametersObj['assetsValue'].displayFunction(this.appliedFilters['assetsValue']),
            titlePrefix: 'Asset value: ',
            tagColor: ''
        }] : [];
    
        const startDateTag = this.appliedFilters['startDate'] ? {
            type: 'startDate',
            title: this.parametersObj['startEndDate'].displayFunction(this.appliedFilters['startDate']),
            titlePrefix: 'Start Date: ',
            tagColor: ''
        } : [];
    
        const endDateTag = this.appliedFilters['endDate'] ? {
            type: 'endDate',
            title: this.parametersObj['startEndDate'].displayFunction(this.appliedFilters['endDate']),
            titlePrefix: 'End Date: ',
            tagColor: ''
        } : [];
    
        const currenciesTags = this.appliedFilters['currencies']?.map((curr, ind) => ({
            deselectData: curr + `_${ind}`,
            type: 'currencies',
            title: this.parametersObj['currencies'].displayFunction(curr),
            titlePrefix: '',
            tagColor: ''
        }));

        const itemStatusTags = this.appliedFilters['itemStatus']?.map((status, ind) => ({
            deselectData: status + `_${ind}`,
            type: 'itemStatus',
            title: this.parametersObj['itemStatus'].displayFunction(status),
            titlePrefix: '',
            tagColor: ''
        }));

        const containersTags = this.appliedFilters['containers']?.map((con, ind) => ({
            deselectData: con + `_${ind}`,
            type: 'containers',
            title: this.parametersObj['containers'].displayFunction(con),
            titlePrefix: '',
            tagColor: ''
        }));

        const riskTags = this.appliedFilters['riskLevel']?.map((con, ind) => ({
            deselectData: con + `_${ind}`,
            type: 'riskLevel',
            title: this.parametersObj['riskLevel'].displayFunction(con),
            titlePrefix: '',
            tagColor: ''
        }));

        const propertyTypeTags = this.appliedFilters['propertyType']?.map((con, ind) => ({
            deselectData: con + `_${ind}`,
            type: 'propertyType',
            title: this.parametersObj['propertyType'].displayFunction(con),
            titlePrefix: 'Property type: ',
            tagColor: ''
        }));

        const companySectorTags = this.appliedFilters['companySector']?.map((con, ind) => ({
            deselectData: con + `_${ind}`,
            type: 'companySector',
            title: this.parametersObj['companySector'].displayFunction(con),
            titlePrefix: 'Sector: ',
            tagColor: ''
        }));

        const liquidityTags = this.appliedFilters['liquidityLevel']?.map((con, ind) => ({
            deselectData: con + `_${ind}`,
            type: 'liquidityLevel',
            title: this.parametersObj['liquidityLevel'].displayFunction(con),
            titlePrefix: '',
            tagColor: ''
        }));
    
        const data = [
            selectedHoldingEntities,
            this.selectedClassesTags,
            this.selectedCustomClassesTags,
            this.selectedSponsorTags,
            this.selectedStockTags,
            ownershipsTags,
            assetsValueTag,
            startDateTag,
            endDateTag,
            currenciesTags,
            itemStatusTags,
            this.selectedLeverageAssetsFilter,
            containersTags,
            riskTags,
            liquidityTags,
            propertyTypeTags,
            companySectorTags
        ].filter(i => i).flat();
    
        return data;
    }

    get selectedClassesTags() {
        return this.appliedFilters.assetsClasses ? this.appliedFilters.assetsClasses.map(selectedClassId => {
            const selectedClass = this.classes.find(cls=>cls.id === selectedClassId);
            if (!selectedClass){return {}}
            return {
                id: selectedClass.id,
                deselectData: selectedClass.id,
                title: selectedClass.title,
                type: 'classes',
                isLiability: selectedClass.isLiability,
                icon: selectedClass.icon,
                titlePrefix: '',
                tagColor: selectedClass.color,
                selectedCategoriesCount: selectedClass.isAllCategoriesSelected ? null : selectedClass.selectedCategoriesCount
            }
        })  : []
    }

    get selectedCustomClassesTags() {
        return this.appliedFilters.customClasses ? this.appliedFilters.customClasses.map(selectedClassObj => {
            // special check for Unspecified sub classes: means customClass is null & subClass is null, 
            // for unspecified class - there is no subClasses array
            if (selectedClassObj.id === null && selectedClassObj.customSubClasses?.length === 1 && selectedClassObj.customSubClasses[0] === null){
                return {
                    id: null,
                    deselectData: null,
                    title: 'Custom sub class: Unspecified',
                    type: 'customClasses',
                    icon: 'hero-custom-sub-classes',
                    titlePrefix: '',
                    tagColor: '#585D66',
                    selectedCategoriesCount: null
                }
            }
            const selectedClass = this.customClasses.find(cls=>cls.id === selectedClassObj.id);
            if (!selectedClass){return {}}
            return {
                id: selectedClass.id,
                deselectData: selectedClass.id,
                title: selectedClass.name === 'Unspecified Class' ? 'Custom class: Unspecified' : selectedClass.name,
                type: 'customClasses',
                icon: selectedClass.icon,
                titlePrefix: '',
                tagColor: selectedClass.color,
                selectedCategoriesCount: selectedClass.isAllSubClassesSelected ? null : selectedClass.selectedSubClassesCount,
                selectedSubClassesTitles: selectedClass.selectedSubClassesTitles
            }
        })  : []
    }
                

    get selectedSponsorTags() {
        return this.appliedFilters.institutions
            ? this.appliedFilters.institutions.map((selectedInstitutionName, ind) => {
                const selectedInstitution = this.parametersObj['institutions']?.options?.find(inst => inst.name === selectedInstitutionName || inst.value === selectedInstitutionName);
                return {
                    ...(selectedInstitution ? selectedInstitution : {}),
                    id: "inst_" + (!selectedInstitution ? selectedInstitutionName : selectedInstitution.name),
                    deselectData: (!selectedInstitution ? selectedInstitutionName : selectedInstitution.name) + ind,
                    title: this.parametersObj['institutions'].displayFunction(selectedInstitutionName),
                    type: 'institutions',
                    titlePrefix: '',
                    tagColor: '',
                };
            })
            : [];
    }

    get selectedStockTags() {
        return this.appliedFilters.stocks
            ? this.appliedFilters.stocks.map((selectedStockName, ind) => {
                const selectedStock = this.parametersObj['stocks']?.options?.find(stock => stock.name === selectedStockName);
                return {
                    ...(selectedStock ? selectedStock : {}),
                    id: "stock_" + (!selectedStock ? selectedStockName : selectedStock.name),
                    deselectData: (!selectedStock ? selectedStockName : selectedStock.name) + ind,
                    title: selectedStockName,
                    type: 'stocks',
                    titlePrefix: '',
                    tagColor: '',
                };
            })
            : [];
    } 

    get selectedLeverageAssetsFilter(){
        if (this.appliedFilters['leveragedAssetsId']){
            return [{
                // deselectData: 'leveragedAssets',
                type: 'leveragedAssetsId',
                title: 'Leveraged assets' +  (this.appliedFilters.leveragedAssetsId.length === this.parametersObj['leveragedAssets']?.leveragedAssetsId?.options.reduce((acc,option)=>acc+option.assetsId.length,0) ? '' : ' + 1 filter'), //    this.parametersObj['leveragedAssets'].displayFunction(curr),
                titlePrefix: '',
                tagColor: ''
            }]
        } else if (this.appliedFilters['notLeveragedAssetsId']){
            return [{
                // deselectData: 'leveragedAssets',
                type: 'notLeveragedAssetsId',
                title: 'Not leveraged assets' +  (this.appliedFilters.notLeveragedAssetsId.length === this.parametersObj['leveragedAssets']?.notLeveragedAssetsId?.options.reduce((acc,option)=>acc+option.assetsId.length,0) ? '' : ' + 1 filter'), //    this.parametersObj['leveragedAssets'].displayFunction(curr),
                titlePrefix: '',
                tagColor: ''
            }]
        } else {
            return [];
        }
    }

    get countActiveFilters() {
        return this.filterTags.length;
    }

    get activeFiltersTypes() {
        return this.filterTags.map(tag=>tag.type);
    }

    get pendingFiltersCount() {
        return this.parameters.reduce((a, b) => ( a + b.selectedCount),0);
    }

    get isApplyFilterDisabled() { 
        return this.filterTags.length === 0 && this.pendingFiltersCount === 0;
    }

    get singleClassFilter() {
        return this.filterTags.length === 1 && this.filterTags[0].type === 'classes' ? this.filterTags[0] : null;
    }
    
    get isOnlyOneClassFilter() {
        return this.filterTags.length === 1 && this.filterTags[0].type === 'classes';
    }

    get isOnlyOneCustomClassAndOneOrZeroSubClassFilter() {
        return this.filterTags.length === 1 && this.filterTags[0].type === 'customClasses' && [null,1].includes(this.filterTags[0].selectedCategoriesCount);
    }

    get isSingleClassFilterWithAllCategoriesSelected() {
        return this.isOnlyOneClassFilter && this.filterTags[0].selectedCategoriesCount === null;
    }

    get parameters() {
        return filtersOrder.map(filter => this.parametersObj[filter]);
    }

    get isUnspecifiedCustomSubClassesSelected(){
        return this.selectedCustomClasses.some(customClassObj=> customClassObj.id === null && customClassObj.customSubClasses?.includes(null));
    }

}
