import React , {useEffect} from 'react';
import { Observer } from 'mobx-react';
import { useStore } from '../../../app/data/root.store';
import {  useLocation } from "react-router-dom";
import {
    ActionButtonText, ActionButtonIcon, ReadOnlyAndDemoModeIconTextWrapper,
    ReadOnlyAndDemoModeTextWrapper, ReadOnlyAndDemoModeIconWrapper, ReadOnlyAndDemoModeActionButtonWrapper
} from './Banner.styles'
import { EyesIcon } from './icons/banner-icons'
import { Banner } from './Banner';
import { LinkArrowIcon } from '../../../../assets/icons/common/common-icons';
import { LimitationModal } from '../../../../common/components/LimitationModal/LimitationModal';
import { ROUTES } from '../../../../common/constants/routes.const';

export function DemoModeBanner() {
    const location = useLocation();
    const { billingStore , userStore, uiStore } = useStore();

    const handleStartMyPortfolioClick = async () => {
        // const paymentLink = await billingStore.fetchBillingUrl()
        if (userStore.isRelevantVariant === 6) {
            await billingStore.setStarterPlan();
            window.location.href = `${ROUTES.wealthOverview.url}?subscribed_success&sw`;
        } else {
            billingStore.setShowPricingTableModal(true);   
        }
    }

    useEffect(() => {
        if (uiStore.currentRoute !== location.pathname) { 
          if (userStore.isDemoMode){
            uiStore.setIsRightMenuOpen(location.pathname.startsWith(ROUTES.wealth.url + '/') ? uiStore.isDemoOverviewOpen : false);
          }
        }
      }, [uiStore, location , userStore]);

    return <Observer>{() => (
        <>
            
        <Banner bannerBgc={'#2565E7'} bannerTxtColor={'#FFFFFF'} 
            titleComponent={
                <>
                    {/* <IconTextWrapper>
                        <DemoModeTextWrapper>You are in Demo mode</DemoModeTextWrapper>
                    </IconTextWrapper> */}
                    <ReadOnlyAndDemoModeIconTextWrapper>
                        <ReadOnlyAndDemoModeTextWrapper>You are in Demo mode</ReadOnlyAndDemoModeTextWrapper>
                        <ReadOnlyAndDemoModeIconWrapper><EyesIcon /></ReadOnlyAndDemoModeIconWrapper>
                    </ReadOnlyAndDemoModeIconTextWrapper>
                </>}
            buttonComponent={<>
                <ReadOnlyAndDemoModeActionButtonWrapper bannerButtonBgc={'#FFFFFF'} bannerButtonTxtColor={'#2565E7'} onClick={() => handleStartMyPortfolioClick()}>
                    <ActionButtonText>Start My Portfolio Free</ActionButtonText>
                    <ActionButtonIcon bannerButtonTxtColor={'#2565E7'}><LinkArrowIcon /></ActionButtonIcon>
                </ReadOnlyAndDemoModeActionButtonWrapper>
            </>}
        />
        {uiStore.isShowDemoLimitationModal &&  <LimitationModal modalType={'demoPortfolio'} onStartMyPortfolio={()=>handleStartMyPortfolioClick()} onClose={()=>{uiStore.setShowDemoLimitationModal(false)}} />}
        </>
    )}
    </Observer>
}


