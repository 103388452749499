import React, { useEffect, useState } from 'react'
import { ActionButtonWrapper, ActionMenuItem, ActionsOverlayContent, ActionsOverlayWrapper, DocumentActions, DocumentDescription, DocumentInfo, DocumentTitle, MobileDocumentRowWrapper } from './MobileAssetDocumentsTab.styles'
import { Dropdown, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const MobileDocumentRow = ({ document, rowUpdated, rowDeleted, documentIndex, editedIndex, onEditedIndexChange }) => {
  const defaultValues = document;
  const [currentDocument, setCurrentDocument] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  // const [isEdited, setIsEdited] = useState(false);

  const { confirm } = Modal;

  useEffect(() => {
    if (editedIndex === documentIndex) {
      setCurrentDocument(document)
      // setIsEdited(true);
    } else {
      setCurrentDocument(defaultValues)
      // setIsEdited(false);
    }
    // return () => {}
  }, [editedIndex, document, documentIndex, defaultValues])

  const showDeleteDocumentConfirm = (documentId) => {
    confirm({
      title: `Are you sure you want to delete this document?`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        rowDeleted?.(documentId);
      },
      onCancel() {
      },
    });
  }

  const downloadDocument = () => {
    fetch(`${baseApiUrl}documents/${currentDocument?.id}`, {
      method: 'GET',
      credentials: 'include'
    })
      .then(response => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          }
        })
      })
      .then(stream => new Response(stream))
      .then(response => response.blob())
      .then(blob => {
        const objectUrl = URL.createObjectURL(blob);
        var link = window.document.createElement("a");
        link.href = objectUrl;
        link.download = currentDocument?.originalName;
        window.document.body.appendChild(link);
        link.click();
        window.document.body.removeChild(link);
      })
  }

  return (
    <MobileDocumentRowWrapper key={currentDocument?.id} isFirst={documentIndex === 0}>

      <DocumentInfo>
        <DocumentTitle>{currentDocument?.originalName}</DocumentTitle>
        {currentDocument?.remarks ? <DocumentTitle style={{fontWeight:'450'}}>{currentDocument?.remarks}</DocumentTitle> : null}
        <DocumentDescription><div>{currentDocument?.type}</div>·<div>{currentDocument?.year}</div></DocumentDescription>
      </DocumentInfo>

      <DocumentActions style={{
        padding: '6px', ...(isOpen ? {
          borderRadius: '50px',
          background: '#E8E9ED',
        } : {})
      }}>
        <Dropdown overlay={ActionsOverlay({
          editDocument: () => { onEditedIndexChange?.(documentIndex) },
          downloadDocument: (e) => { downloadDocument(e) },
          showDeleteDocumentConfirm: () => { showDeleteDocumentConfirm(currentDocument?.id) },
          onClose: () => { setIsOpen(false) }
        })} trigger={['click']} placement='topLeft'
        onVisibleChange={(isOpen) => { setIsOpen(isOpen) }}
        >
          <div onClick={e => e.preventDefault()} style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
            <ThreeDotsSvg />
          </div>
        </Dropdown>
      </DocumentActions>

    </MobileDocumentRowWrapper>
  )
}

const ActionsOverlay = ({ editDocument, downloadDocument, showDeleteDocumentConfirm, onClose }) => {
  return (
    <ActionsOverlayWrapper>
      <ActionsOverlayContent>
        <ActionMenuItem onClick={()=>{onClose?.(); editDocument?.()}}><ActionButtonWrapper><EditSvg /></ActionButtonWrapper>Edit info</ActionMenuItem>
        <ActionMenuItem onClick={()=>{onClose?.(); downloadDocument?.()}}><ActionButtonWrapper><DownloadSvg /></ActionButtonWrapper>Download</ActionMenuItem>
        <ActionMenuItem onClick={()=>{onClose?.(); showDeleteDocumentConfirm?.()}} style={{color:'red'}}><ActionButtonWrapper><EraseSvg /></ActionButtonWrapper>Delete</ActionMenuItem>
      </ActionsOverlayContent>
    </ActionsOverlayWrapper>
  )
}

const ThreeDotsSvg = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="3" cy="9" r="1.5" fill="#585D66" />
      <circle cx="9" cy="9" r="1.5" fill="#585D66" />
      <circle cx="15" cy="9" r="1.5" fill="#585D66" />
    </svg>
  )
}

const DownloadSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 15.25L12 4.75" stroke="black" strokeWidth="1.5" />
      <path d="M15.5 11.75L12 15.25L8.5 11.75" stroke="black" strokeWidth="1.5" />
      <path d="M5 13.3895V18.25C5 18.8023 5.44772 19.25 6 19.25L18 19.25C18.5523 19.25 19 18.8023 19 18.25L19 13.25" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}

const EraseSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.25 9.5C18.25 9.5 17.7262 15.9022 17.4194 18.9881C17.369 19.4947 16.9419 19.8757 16.4328 19.876L7.65189 19.8811C7.14227 19.8814 6.7143 19.5 6.66174 18.9931C6.34059 15.8953 5.75 9.5 5.75 9.5" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M19.5 6.55298H4.5" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
      <path d="M16.5 6.55288C15.4225 6.55288 15.0944 5.67353 15.042 4.75719C15.004 4.09153 14.5116 3.5 13.8448 3.5H10.1552C9.48842 3.5 8.99604 4.09153 8.95799 4.75719C8.90562 5.67353 8.57753 6.55288 7.5 6.55288" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

const EditSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M17.3979 11.7995L12.0028 6.58966" stroke="black" strokeWidth="1.5" />
      <path d="M9.90656 19.5702L9.9056 20.3202C10.1098 20.3205 10.3053 20.2375 10.4469 20.0903L9.90656 19.5702ZM4.5028 14.3693L3.96242 13.8492C3.8208 13.9964 3.74531 14.1949 3.75338 14.399L4.5028 14.3693ZM4.70825 19.5636L3.95883 19.5932L3.98729 20.3127L4.70729 20.3136L4.70825 19.5636ZM14.6889 5.22794L18.6517 9.04193L19.6919 7.96118L15.7291 4.14719L14.6889 5.22794ZM18.6585 9.39542L9.36619 19.0501L10.4469 20.0903L19.7392 10.4356L18.6585 9.39542ZM5.04317 14.8894L14.3354 5.2347L13.2547 4.19452L3.96242 13.8492L5.04317 14.8894ZM9.90752 18.8202L4.70921 18.8136L4.70729 20.3136L9.9056 20.3202L9.90752 18.8202ZM5.45766 19.5339L5.25221 14.3397L3.75338 14.399L3.95883 19.5932L5.45766 19.5339ZM18.6517 9.04193C18.7512 9.13768 18.7542 9.29594 18.6585 9.39542L19.7392 10.4356C20.4094 9.73924 20.3882 8.6314 19.6919 7.96118L18.6517 9.04193ZM15.7291 4.14719C15.0328 3.47697 13.9249 3.49816 13.2547 4.19452L14.3354 5.2347C14.4312 5.13522 14.5895 5.13219 14.6889 5.22794L15.7291 4.14719Z" fill="black" />
      <path d="M20 19.6H13" stroke="black" strokeWidth="1.5" />
    </svg>
  )
}