import { Input } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { CalendlyPopupWidget } from '../../../../common/components/CalendlyPopupWidget/CalendlyPopupWidget';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyString } from '../../../../common/utils/object.utils';
import { useStore } from '../../../app/data/root.store';
import { questionnaireIcons } from './assets/questionnaireIcons';
import { AnswerItemWrapper, AnswersWrapper, AnythingElseTextAreaWrapper, FloatingLabeInInput, InputWithLabelOnTopWrapper, MaybeLaterWrapper, NetWorthBar, QuestionnaireContinueButton, QuestionnaireNameInput, QuestionnaireStepTitle, QuestionnaireTitleWrapper, QuestionnaireTopSubTitle, WelcomeStepSubTitle, WelcomeStepTitle, WelcomeStepTitlesWrapper } from './OnBoardingQuestionnaire.styles';
import { newQuestionnaireStepsDataAdvisor } from './QuestionnaireStepsAdvisor.const';
import { CountAnimation } from '../../../../common/components/count-animation/CountAnimation';
import { ROUTES } from '../../../../common/constants/routes.const';
import { useAnalytics } from '../../../app/data/AnalyticsProvider';
import { PhoneInput } from '../../../user/components/PhoneInput/PhoneInput';
import { countryListPhone } from '../../../../common/constants/countries.const';

const {
  CalendarSvg,
  ManageMyWealthSvg,
  ManageOthersWealthSvg,
  StrategyAndPlanningSvg,
  ToolsToManageWealthSvg,
  WalletSvg,
  WhereAndHowToInvestSvg,
  WhiteArrowRightIcon,
  MagnifierSvg,
  ThreeDBox,
  VillageSvg,
  MarshmallowsSvg,
} = questionnaireIcons;

const { TextArea } = Input

export const newQuestionnaireStepsData = {
  welcome_step: ({isMobileView}) => ({
    isPathStep: false,
    showPathSteps: false,
    showBackButton: false,
    adamImageSize: 150,
    stepHeader: () => (<>
      <WelcomeStepTitlesWrapper>

        <WelcomeStepTitle>Welcome!</WelcomeStepTitle>
        <WelcomeStepSubTitle style={{ textAlign: 'center' }}>
          I'm Adam, your personal account manager.{!isMobileView ? <br /> : ' '}
          Let's start with a few questions to customize your experience.
        </WelcomeStepSubTitle>
      </WelcomeStepTitlesWrapper>

    </>),
    showContent: false,
    stepFooter: () => <QuestionnaireWelcomeStepFooter />,
  }),
  name_step: ({ isMobileView }) => ({
    whyDoWeAskContent: 'Vyzer is your personal financial concierge. It is important to us to know your name.',
    title: `What's your name?`,
    nextStep: 'phone_step',
    isPathStep: false,
    showPathSteps: false,
    stepContent: () => <QuestionnaireNameStepContent />,
    stepFooter: () => <QuestionnaireNameStepFooter />,
    isValidInput: (data) => { return !isNullOrUndefinedOrEmptyString(data?.name) },
  }),

  phone_step: ({ firstNameCapitalize }) => ({
    isPathStep: false,
    showPathSteps: false,
    whyDoWeAskContent: 'We request your phone number to enhance the security of your account and facilitate account verification.',
    topSubTitle: `Nice to meet you, ${firstNameCapitalize}`,
    title: `what's your mobile number?`,
    subTitle: 'For extra security',
    stepContent: () => <QuestionnairePhoneStepContent />,
    stepFooter: () => <QuestionnairePhoneStepFooter />,
    isValidInput: (data) => { return !isNullOrUndefinedOrEmptyString(data?.phone) },
  }),
  individual_or_advisor_step: ({ firstNameCapitalize }) => ({
    title: `Whose wealth do you manage, ${firstNameCapitalize}?`,
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    answerType: 'goToStep',
    answerOptions: [
      { label: 'I manage my own wealth', icon: <ManageMyWealthSvg />, goToStep: 'individual_step', value: 'individual' },
      { label: `I manage other people's wealth`, icon: <ManageOthersWealthSvg />, goToStep: 'advisor_step', value: 'advisor' }
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none' },
    showFooter: false,
    withRandomize: true,
  }),
  individual_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    isPathStep: false,
    isInsightsStep: true,
    stepHeader: () => <IndividualInsightHeader />,
    showContent: false,
    nextStep: 'net_worth_step',
    nextButtonText: `Wow! Let's continue`,
  },
  net_worth_step: ({isMobileView}) => ({
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    title: 'What is your approximate net worth?',
    nextStep: 'investment_step',
    answerType: 'goToStep',
    answerOptions: [
      { label: 'Less than $1M', icon: <NetWorthBar />, value: 'less_than_1m', goToStep: 'investment_step' },
      { label: '$1M  - $8M', icon: <NetWorthBar height={56} />, value: '1m_to_8m', goToStep: 'investment_step' },
      { label: '$8M - $20M', icon: <NetWorthBar height={80} />, value: '8m_to_20m', goToStep: 'investment_step' },
      { label: 'Over $20M', icon: <NetWorthBar height={104} />, value: 'over_20m', goToStep: 'investment_step' },
    ],
    answerWrapperCustomStyles: { maxWidth: 'none', },
    answerCustomStyles: { 
      ...(isMobileView ?
        { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap:16, maxHeight: 72, paddingLeft: 12 }
        :
        { display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', gap:8, padding:'32px 0 24px 0', maxHeight: 'none', minHeight: 190, minWidth: '156px', maxWidth:'156px' }
      )
    },
    isValidInput: (data) => { return !isNullOrUndefinedOrEmptyString(data?.value) },
    showFooter: false,
  }),
  investment_step: ({ firstNameCapitalize }) => ({
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: `Thanks ${firstNameCapitalize}, let’s help you grow it!`,
    title: 'what does your net worth consist of?',
    subTitle: 'Select all that apply:',
    answerType: 'multiChoice',
    answerOptions: [
      { label: 'Syndications', value: 'syndications' },
      { label: 'Real estate', value: 'real_estate' },
      { label: 'Private equity', value: 'private_equity' },
      { label: 'Public markets', value: 'public_markets' },
      { label: 'Startups', value: 'startups' },
      { label: 'Crypto', value: 'crypto' },
      { label: 'Hedge funds', value: 'hedge_funds' },
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    nextStep: 'looking_for_step',
    withRandomize: true,
  }),
  looking_for_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    title: 'Great! What are you looking for?',
    answerType: 'goToStep',
    answerOptions: [
      { label: 'Tools to manage wealth', icon: <ToolsToManageWealthSvg />, value: 'tools_to_manage_wealth_step', goToStep: 'tools_to_manage_wealth_step' },
      { label: 'Where and how to invest', icon: <WhereAndHowToInvestSvg />, value: 'where_and_how_to_invest_step', goToStep: 'where_and_how_to_invest_step' },
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none' },
    showFooter: false,
    withRandomize: true,
  },
  where_and_how_to_invest_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    isPathStep: false,
    isInsightsStep: true,
    stepHeader: () => <WhereAndHowToInvestInsightHeader />,
    showContent: false,
    nextStep: 'where_and_how_to_invest_type_of_tool_step',
  },
  tools_to_manage_wealth_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    isPathStep: false,
    isInsightsStep: true,
    stepHeader: () => <ToolsToManageWealthInsightHeader />,
    showContent: false,
    nextStep: 'type_of_tool_step'
  },
  where_and_how_to_invest_type_of_tool_step: ({ firstNameCapitalize }) => ({
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    title: `Ok, ${firstNameCapitalize}, what do you need most?`,
    subTitle: 'Select one:',
    answerType: 'goToStep',
    answerOptions: [
      { label: 'Finding new investment opportunities', icon: <MagnifierSvg />, value: 'finding_new_investment_opportunities', goToStep: 'finding_new_investment_opportunities_and_planning_step' },
      { label: 'Refining my investment strategy', icon: <StrategyAndPlanningSvg />, value: 'refining_my_investment_strategy', goToStep: 'strategy_and_planning_step' },
      { label: 'Solving financial complexity', icon: <ThreeDBox />, value: 'solving_financial_complexity', goToStep: 'type_of_tool_step' },
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none', maxWidth: '280px' },
    showFooter: false,
    withRandomize: true,
  }),
  finding_new_investment_opportunities_and_planning_step: ({ firstNameCapitalize }) => ({
    title: `What type of investments are you looking for, ${firstNameCapitalize}?`,
    answerType: 'goToStep',
    answerOptions: [
      { label: 'Private investments', icon: <VillageSvg />, value: 'private_investments', goToStep: 'private_investments_strategy_and_planning_step' },
      { label: 'Public investments', icon: <MarshmallowsSvg />, value: 'public_investments', goToStep: 'public_investments_strategy_and_planning_step' },
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none' },
    showFooter: false,
    withRandomize: true,
  }),
  public_investments_strategy_and_planning_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: 'Excellent! Last question, within the public markets,',
    title: 'What are your two biggest challenges?',
    subTitle: 'Select up to two:',
    answerSelectionLimit: 2,
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    answerType: 'multiChoice',
    answerOptions: [
      { label: 'Choosing which stocks / bonds / ETFs to buy', value: 'choosing_which_stocks_bonds_etfs_to_buy' },
      { label: 'Accessing structured products', value: 'accessing_structured_products' },
      { label: 'Finding someone to manage my public portfolio', value: 'finding_someone_to_manage_my_public_portfolio' },
      { label: 'Accessing top-performing hedge funds', value: 'accessing_top-performing_hedge_funds' },
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column' ,  alignItems: 'stretch' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none', alignItems:'stretch' },
    nextStep: 'anything_else_step',
    withRandomize: true,
  },
  private_investments_strategy_and_planning_step: {
    topSubTitle: `Got it, within the private markets,`,
    title: 'What are your two biggest challenges?',
    subTitle: 'Select up to two:',
    answerType: 'multiChoice',
    answerSelectionLimit: 2,
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    answerOptions: [
      { label: 'Accessing investment opportunities', value: 'accessing_investment_opportunities' },
      { label: 'Vetting investment opportunities', value: 'vetting_investment_opportunities' },
      { label: 'Reducing fees on investments', value: 'reducing_fees_on_investments' },
      { label: 'Lowering the minimum ticket for investment opportunities', value: 'lowering_the_minimum_ticket_for_investments_opportunities' },
      { label: 'Finding tax efficient investments opportunities', value: 'finding_tax_efficient_investments_opportunities' },
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    nextStep: 'are_you_an_accredited_investor_step',
    withRandomize: true,
  },
  are_you_an_accredited_investor_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: 'Excellent! Last question,',
    title: 'Are you an accredited investor?',
    answerType: 'goToStep',
    showFooter: false,
    answerOptions: [
      { label: 'Yes', value: 'yes', goToStep: 'anything_else_step' },
      { label: 'No', value: 'no', goToStep: 'anything_else_step' },
      { label: 'Not sure', value: 'not_sure', goToStep: 'accredited_investor_not_sure_step' },
    ],
    // nextStepFn: (data) => {
    //   if (data.value === 'not_sure') {
    //     return 'accredited_investor_not_sure_step';
    //   }
    //   return 'anything_else_step';
    // },
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none' },
  },
  accredited_investor_not_sure_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    isPathStep: false,
    topSubTitle: `Let's help determine your status.`,
    title: 'Do any of these apply to you?',
    answerType: 'multiChoice',
    answerOptions: [
      { label: 'I earn $200K+ yearly, or $300K+ with my spousal equivalent', value: 'i_earn_200k_yearly', },
      { label: 'I have $1M+ in assets, excluding my primary residence', value: 'i_have_1m_in_assets', },
      { label: 'I own an entity (i.e., family office) with $5M+ in assets', value: 'i_own_an_entity_with_5m_in_assets', },
      { label: 'I hold a Series 7, 65 or 82 license currently in good standing', value: 'i_hold_a_series_7_65_or_82_license', },
      { label: 'None of the above', value: 'none_of_the_above', },
    ],
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column', alignItems: 'stretch' },
    nextStep: 'anything_else_step',
  },
  type_of_tool_step: ({ firstNameCapitalize }) => ({
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    title: `Ok, ${firstNameCapitalize}, so what type of tool do you need most?`,
    subTitle: 'Select one:',
    answerType: 'goToStep',
    answerOptions: [
      { label: 'Strategy and planning', icon: <StrategyAndPlanningSvg />, value: 'strategy_and_planning_step', goToStep: 'strategy_and_planning_step' },
      { label: 'Investments tracking and reporting', icon: <ToolsToManageWealthSvg />, value: 'investments_tracking_and_reporting', goToStep: 'investments_tracking_and_reporting_step' },
      { label: 'Budgeting and expense management', icon: <WalletSvg />, value: 'budgeting_and_expense_management', goToStep: 'budgeting_and_expense_management_step' },
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none' },
    showFooter: false,
    withRandomize: true,
  }),
  investments_tracking_and_reporting_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: 'Excellent, we can help with investment tracking and reporting! Last question,',
    title: 'what are your two biggest challenges?',
    subTitle: 'Select up to two:',
    answerType: 'multiChoice',
    answerSelectionLimit: 2,
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    answerOptions: [
      { label: 'Tracking diverse assets’ performance', value: 'tracking_diverse_assets_performance' },
      { label: 'Preparing tax returns', value: 'preparing_tax_returns' },
      { label: 'Gathering investment data from many sources', value: 'gathering_investment_data_from_many_sources' },
      { label: 'Understanding how much I’m paying in fees', value: 'understanding_how_much_im_paying_in_fees' },
      { label: 'Understanding my net worth', value: 'understanding_my_net_worth' },
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    nextStep: 'anything_else_step',
    withRandomize: true,
  },
  budgeting_and_expense_management_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: 'Excellent! Last question,',
    title: 'what are your two biggest challenges?',
    subTitle: 'Select up to two:',
    answerType: 'multiChoice',
    answerSelectionLimit: 2,
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    answerOptions: [
      { label: 'Understanding my expense breakdown', value: 'understanding_my_expense_breakdown' },
      { label: 'Gathering data from many bank accounts and credit cards', value: 'gathering_data_from_many_bank_accounts_and_credit_cards' },
      { label: 'Creating and following a budget', value: 'creating_and_following_a_budget' },
      { label: 'Tracking my debt-to-income ratio', value: 'tracking_my_debt_to_income_ratio' },
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    answerWrapperCustomStyles: { display: 'flex', flexDirection: 'column', alignItems: 'stretch' },
    answerCustomStyles: { display: 'flex', flexDirection: 'column', maxHeight: 'none' },
    nextStep: 'anything_else_step',
    withRandomize: true,
  },
  strategy_and_planning_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    topSubTitle: 'Excellent, we can help with strategy and planning! Last question,',
    title: 'what are your two biggest challenges?',
    subTitle: 'Select up to two:',
    answerType: 'multiChoice',
    answerSelectionLimit: 2,
    isValidInput: (data) => { return !isNullOrUndefined(data?.value) && data?.value.length > 0 },
    answerOptions: [
      { label: 'Building an estate plan', value: 'building_an_estate_plan' },
      { label: 'Planning future cash flow', value: 'planning_future_cashflow' },
      { label: 'Refining tax strategy', value: 'refining_tax_strategy' },
      { label: 'Understanding my net worth over time', value: 'understanding_my_net_worth_over_time' },
      { label: 'Aligning my asset allocation strategy and goals', value: 'aligning_my_asset_allocation_strategy_and_goals', customStyle: {} },
      { label: 'Other', value: 'other', isOtherInput: true },
    ],
    nextStep: 'anything_else_step',
    withRandomize: true,
  },
  anything_else_step: {
    whyDoWeAskContent: 'This helps us define your initial experience while using Vyzer.',
    // isPathStep: false,
    title: 'Anything else for us to customize your experience?',
    withoutFullWidth: true,
    stepContent: () => <QuestionnaireAnythingElseStepContent />,
    nextStep: 'schedule_call_step',
  },
  schedule_call_step: {
    isPathStep: false,
    topSubTitle: 'The next step is setting up your portfolio',
    title: 'An onboarding call will help set it up quickly.',
    stepContent: () => <QuestionnaireScheduleCallStepContent />,
    showFooter: false,
  },
  final_step: ({ firstNameCapitalize }) => ({
    isPathStep: false,
    topSubTitle: `Ok ${firstNameCapitalize}, you can book a call later.`,
    title: `Let's get you set up!`,
    stepContent: () => <QuestionnaireFinalStepContent />,
    showFooter: false,
  }),
  final_step_after_scheduled_call: ({ firstNameCapitalize }) => ({
    isPathStep: false,
    topSubTitle: `Perfect! see you soon!`,
    title: 'In the meantime, you can set up the basics',
    stepContent: () => <QuestionnaireFinalStepContent />,
    showFooter: false,
  }),
  ...newQuestionnaireStepsDataAdvisor,
};

const QuestionnaireWelcomeStepFooter = observer(({ defaultValue }) => {

  const { onBoardingStore } = useStore();
  const {mixpanelTrack} = useAnalytics();

  const handleStartOnClick = () => {
    mixpanelTrack('Questionnaire Step Completed', {step_name: onBoardingStore.questionnaireStep});
    onBoardingStore.startQuestionnaire();
  }

  return (
    <QuestionnaireContinueButton onClick={handleStartOnClick}>Let's start <WhiteArrowRightIcon /></QuestionnaireContinueButton>
  )

})

const QuestionnaireNameStepFooter = observer(() => {

  const { userStore, onBoardingStore } = useStore();
  const {mixpanelTrack} = useAnalytics();

  const handleStartOnClick = () => {
    if (!isNullOrUndefinedOrEmptyString(onBoardingStore.currentStepData.name)) {
      // Save profile
      userStore.updateFullName(onBoardingStore.currentStepData.name);
      userStore.saveProfileUpdates(['fullName'], true);
      mixpanelTrack('Questionnaire Step Completed', {step_name: onBoardingStore.questionnaireStep, step_selections: onBoardingStore.currentStepData?.name});
      onBoardingStore.handleNext('phone_step');
    }
  }

  const isValidInput = (data) => {
    return !isNullOrUndefinedOrEmptyString(data)
  }

  return (
    <QuestionnaireContinueButton disabled={!isValidInput(onBoardingStore.currentStepData.name)} onClick={handleStartOnClick}>Continue</QuestionnaireContinueButton>
  )

})


const QuestionnaireNameStepContent = observer(({ defaultValue }) => {

  const { userStore, onBoardingStore } = useStore();
  const [nameInput, setNameInput] = useState(onBoardingStore.currentStepData['name']); //  onBoardingStore.currentStepData['name'] || userStore.firstNameCapitalize + (userStore.data.profile?.lastName ? (' ' + userStore.data.profile?.lastName) : ''));
  // const { mixpanelTrack, sendDataToGTM } = useAnalytics();
  // const mixpanelStepsData = { step_name: 'What is your name', step_number: 2 };

  const handleInputChange = (value) => {
    setNameInput(value)
    onBoardingStore.updateCurrentStepData('name', value);
  }

  useEffect(() => {
    if (isNullOrUndefined(onBoardingStore.currentStepData['name'])) {
      const nameValue = userStore.firstNameCapitalize + (userStore.data.profile?.lastName ? (' ' + userStore.data.profile?.lastName) : '');
      setNameInput(nameValue)
      onBoardingStore.updateCurrentStepData('name', nameValue);
    }
  }, [userStore, onBoardingStore]);

  return (
    <AnswersWrapper>
      <InputWithLabelOnTopWrapper>
        <FloatingLabeInInput>Enter your full name</FloatingLabeInInput>
        <QuestionnaireNameInput value={nameInput} onChange={(e) => { handleInputChange(e.target.value) }} autoFocus />
      </InputWithLabelOnTopWrapper>
    </AnswersWrapper>
  )

})

const QuestionnaireAnythingElseStepContent = observer(({ defaultValue }) => {

  const { onBoardingStore } = useStore();
  const [nameInput, setNameInput] = useState(onBoardingStore.currentStepData['name']); //  onBoardingStore.currentStepData['name'] || userStore.firstNameCapitalize + (userStore.data.profile?.lastName ? (' ' + userStore.data.profile?.lastName) : ''));

  const handleInputChange = (value) => {
    setNameInput(value)
    onBoardingStore.updateCurrentStepData('anythingElse', value);
  }

  return (
    <AnythingElseTextAreaWrapper>
      <TextArea
        placeholder='Type here... (optional)'
        autoSize={{ minRows: 7, }}
        style={{ width: '100%', borderRadius: '8px', border: '1px solid #E0E0E0', padding: '20px', fontSize: '16px', lineHeight: '24px', marginBottom: '24px' }}
        value={nameInput} onChange={(e) => { handleInputChange(e.target.value) }} autoFocus />
    </AnythingElseTextAreaWrapper>
  )
})

const QuestionnaireScheduleCallStepContent = observer(({ defaultValue }) => {
  const { onBoardingStore } = useStore();
  const {mixpanelTrack} = useAnalytics();

  const handleScheduleClick = () => {
    mixpanelTrack('CTA Clicked', {
      step_name: onBoardingStore.questionnaireStep, 
      cta_text: 'Schedule an onboarding call',
      location: 'questionnaire',
      destination: 'Calendly',
      type: 'Button',
      url: window.location.href,
    });
  }

  const handleOnScheduleSuccess = () => {
    mixpanelTrack('Questionnaire Step Completed', {step_name: onBoardingStore.questionnaireStep, step_selections: onBoardingStore.currentStepData});
    onBoardingStore.handleNext('final_step_after_scheduled_call');
  }

  const handleMaybeLayerClick = () => {
    mixpanelTrack('CTA Clicked', {
      step_name: onBoardingStore.questionnaireStep, 
      cta_text: 'Maybe later',
      location: 'questionnaire',
      destination: 'final_step_after_scheduled_call',
      type: 'Hyperlink',
      url: window.location.href,
    });
    mixpanelTrack('Questionnaire Step Completed', {step_name: onBoardingStore.questionnaireStep, step_selections: onBoardingStore.currentStepData});
    onBoardingStore.handleNext('final_step');
  }

  return (
    <AnswersWrapper style={{ display: 'flex', flexDirection: 'column', }}>
      <CalendlyPopupWidget triggerComponent={
        <AnswerItemWrapper onClick={handleScheduleClick} style={{ display: 'flex', flexDirection: 'column', maxHeight: 'none' }}><CalendarSvg />Schedule an onboarding call</AnswerItemWrapper>
      } triggerWrapperStyles={{ marginBottom: '8px' }} onScheduleSuccess={handleOnScheduleSuccess} />
      <MaybeLaterWrapper onClick={handleMaybeLayerClick}>Maybe later</MaybeLaterWrapper>
    </AnswersWrapper>
  )
})

const QuestionnaireFinalStepContent = observer(({ defaultValue }) => {
  const { onBoardingStore, billingStore } = useStore();
  const {mixpanelTrack} = useAnalytics();
  
  const handleStartMyPortfolioOnClick = async () => {
    mixpanelTrack('CTA Clicked', {
      step_name: onBoardingStore.questionnaireStep, 
      cta_text: 'Set up my portfolio',
      location: 'questionnaire',
      destination: `Adam's onboarding steps`,
      type: 'Button',
      url: window.location.href,
    });
    await onBoardingStore.completeQuestionnaire(false, async () => {
      await billingStore.setStarterPlan();
      window.location.href = ROUTES.wealthOverview.url
    });
  }

  const handleExploreDemoDataOnClick = () => {
    mixpanelTrack('CTA Clicked', {
      step_name: onBoardingStore.questionnaireStep, 
      cta_text: 'Explore demo-data portfolio',
      location: 'questionnaire',
      destination: 'Demo-data portfolio',
      type: 'Hyperlink',
      url: window.location.href,
    });
    onBoardingStore.completeQuestionnaire(true, () => {window.location.href = ROUTES.wealthOverview.url})
  }

  return (
    <AnswersWrapper style={{ display: 'flex', flexDirection: 'column', }}>
      <QuestionnaireContinueButton onClick={handleStartMyPortfolioOnClick}>Set up my portfolio</QuestionnaireContinueButton>
      <MaybeLaterWrapper onClick={handleExploreDemoDataOnClick}>Explore demo-data portfolio</MaybeLaterWrapper>
      {/* <MaybeLaterWrapper onClick={() => { onBoardingStore.completeQuestionnaireStgTesting() }}>Restart questionnaire ( For Stg testing )</MaybeLaterWrapper> */}
      
      
    </AnswersWrapper>
  )
})


const QuestionnairePhoneStepContent = observer(() => {
  const { onBoardingStore, userStore } = useStore();
  const [userPhoneNumber, setUserPhoneNumber] = useState(
    onBoardingStore.currentStepData['phone'] || {}
  );

  const handlePhoneChange = (phoneNumber) => {
    setUserPhoneNumber(phoneNumber);
    onBoardingStore.updateCurrentStepData('phone', {...phoneNumber, withError: false});
  }

  useEffect(() => {
    if (isNullOrUndefined(onBoardingStore.currentStepData['phone'])) {
      const phoneValue = { value: userStore.phoneNumber, alpha3: userStore.phoneAlpha3 }
      setUserPhoneNumber(phoneValue)
      onBoardingStore.updateCurrentStepData('phone', phoneValue );
    }
  }, [userStore, onBoardingStore]);

  return (
    <AnswersWrapper>
      <PhoneInput
        userPhoneNumber={userPhoneNumber}
        handlePhoneChange={handlePhoneChange}
        customWrapperStyle={{
           height: '60px', boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.15), 0px 1px 8px 0px rgba(0, 0, 0, 0.08)', borderRadius: '8px',
          ...(onBoardingStore.currentStepData.phone?.withError ? {
            border: '1px solid #ff0000'
          } : {})
        }}
        withError={onBoardingStore.currentStepData.phone?.withError}
      />
    </AnswersWrapper>
  )
})

const QuestionnairePhoneStepFooter = observer(() => {

  const { userStore, onBoardingStore } = useStore();
  const {mixpanelTrack} = useAnalytics();

  const handleContinueOnClick = async () => {
    const { phone } = onBoardingStore.currentStepData;
    if (isNullOrUndefinedOrEmptyString(phone?.value) || !isValidInput(phone.value)) return;
  
    const phoneNumberWithCountryCode = `${phone.alpha3 ? `${countryListPhone[phone.alpha3].code}-` : ''}${phone.value}`;
    const isValid = await userStore.updatePhoneNumber(phoneNumberWithCountryCode, phone.alpha3);
  
    if (isValid) {
      mixpanelTrack('Questionnaire Step Completed', { step_name: onBoardingStore.questionnaireStep, step_selections: phoneNumberWithCountryCode });
      onBoardingStore.handleNext('individual_or_advisor_step');
    } else {
      onBoardingStore.updateCurrentStepData('phone', {...phone, withError: true});
    }
  };

  const isValidInput = (data) => {
    return !isNullOrUndefinedOrEmptyString(data) && data.length > 6
  }

  return (
    <QuestionnaireContinueButton disabled={!isValidInput(onBoardingStore.currentStepData.phone?.value)} onClick={handleContinueOnClick}>Continue</QuestionnaireContinueButton>
  )

})

const ToolsToManageWealthInsightHeader = () => (
  <QuestionnaireTitleWrapper>
    <QuestionnaireTopSubTitle>Perfect choice! Time is golden.</QuestionnaireTopSubTitle>
    <QuestionnaireStepTitle>Our clients save over <CountAnimation width={34} value={20} duration={2} /> hours a month with us.<br />Welcome aboard!</QuestionnaireStepTitle>
  </QuestionnaireTitleWrapper>
)

const IndividualInsightHeader = () => (
  <QuestionnaireTitleWrapper>
    <QuestionnaireTopSubTitle>You're in good hands!</QuestionnaireTopSubTitle>
    <QuestionnaireStepTitle>Vyzer helps <CountAnimation width={80} value={10843} duration={2} /> individuals like you<br />effectively manage their wealth.</QuestionnaireStepTitle>
  </QuestionnaireTitleWrapper>
)

const WhereAndHowToInvestInsightHeader = () => (
  <QuestionnaireTitleWrapper>
    <QuestionnaireTopSubTitle>You're in good hands!</QuestionnaireTopSubTitle>
    <QuestionnaireStepTitle>Tracking <CountAnimation width={180} currency={'USD'} value={6383101445} duration={2} /> in assets, Vyzer's unique investment<br />insights have helped thousands make better decisions.</QuestionnaireStepTitle>
  </QuestionnaireTitleWrapper>
)