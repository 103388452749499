import styled from "@emotion/styled/macro";
import { colors, views } from "../../../../common/styles/theme.styles";
import { flexCenter, flexColumn } from "../../../../common/styles/combos.styles";

export const DrawerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height:100%;
    overflow: hidden;
    flex:1;
`;

export const DrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  color: ${colors.darkGray1};
  font-size: 16px;
  font-weight: 450;
  box-shadow: 0px -1px 0px 0px #DDE3EB inset;
  ${views.tablet} {
    padding:12px 0;
  }
`;

export const MobileHeaderTitle = styled.div`
    color: ${colors.darkGray1};
    font-family: Circular;
    font-size: 18px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const MobileHeaderSubTitle = styled(MobileHeaderTitle)`
    color: ${colors.darkGray2};
    font-size: 13px;
`;

export const ModalCloseButtonWrapper = styled.div`
    ${flexCenter}
    cursor: pointer;
    padding: 4px;
`;

export const CustomClassContentWrapper = styled.div`
    ${flexColumn}
    align-items: center;
    gap:16px;
    padding:24px 40px 32px 40px;
    flex:1;
    overflow-y: auto;
`;

export const DrawerFooter = styled.div`
    border-top: 1px solid ${colors.gray3};
    padding:24px;
    ${flexCenter};
    justify-content: space-between;
    @media (max-width: 420px) {
        flex-direction: column-reverse;
        align-items: stretch;
        gap: 8px;
    }
`;

export const EditCustomClassInputRow = styled.div`
    display: flex;
    align-items: center;
    text-align:left;
    width: 100%;

    &.ant-dropdown-disabled {
        background: #f5f5f5;
        color: rgba(0, 0, 0, 0.25);
    }

    .ant-picker, .ant-input {
        outline: none;
        border-radius:8px;
        :hover, :active, :focus {
            box-shadow: none;
            border: 1px solid ${colors.gray3};
        }
    }

    .ant-picker-focused{
        border-color:#7d7d7d!important;
        box-shadow: none!important;
    }
    .ant-input-borderless {
        background-color: #fff;
    }
    ${props => props.disabled ? `
        background-color: ${colors.lightGray1};
    ` : `
        cursor: pointer;
    `}
`

export const InputWithLabelOnTopWrapper = styled.div`
    position: relative;
    width: 100%;

    .ant-dropdown-trigger {

      .simple_select_button_text {
        padding-top: 18px;
        font-size: 18px;
        color:${colors.darkGray1};
        font-weight:450;
      }
      .select-arrow{
        width:18px;
        height:18px;
        margin-right: 4px; 
      }
      ${props => props.withError ? `
            border: 1px solid red;
        ` : ``}
    }

    .ant-input{
        padding-top: 26px;
        font-size: 18px;
        :hover{
            border-color: #1A1B1D;
        }
        ${props => props.withError ? `
            border: 1px solid red;
        ` : ``}

    }

    .ant-picker .ant-picker-input input{
      padding-top: 18px;
      font-size: 18px;
      color:${colors.darkGray1};
      font-weight:450;
    }
    .ant-picker .ant-picker-input .ant-picker-suffix{
      font-size:18px;
    }
`;

export const FloatingLabeInInput = styled.div`
    position:absolute;
    top:6px;
    left:12px;
    font-size: 13px;
    color:${colors.darkGray2};
    font-weight: 450;
    pointer-events: none;
    z-index: 2;
`;

export const ImageTile = styled.div`
    background: ${props => props.fallbackColor} url(${props => props.image});
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: ${props => props.borderRadius || 10}px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    ${props=>props.size ? `
        width: ${props.size}px;
        height: ${props.size}px;
        svg {
            width: ${props.size - 8}px;
            height: ${props.size - 8}px;
        }
    ` : ``}
    ${props=>props.isSelectable ? `
    :hover {
        ::after {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 14px;
            border: 2px solid #000;
            background-color: transparent;
            margin: -4px;
        }
    }
    ${props.isSelected ? `
        ::after {
            content: '';
            position: absolute;
            inset: 0;
            border-radius: 14px;
            border: 2px solid #000;
            background-color: transparent;
            margin: -4px;
        }
        ` : ``}
    ` : ``}
`;

export const CustomClassColorsInputTitle = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
    align-self: stretch;
    color: ${colors.darkGray2};
    font-family: Circular;
    font-size: 13px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
`;

export const ColorSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const IconSelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const SelectionContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  ${views.tablet} {
    gap: 24px;
    justify-content: center;
  }
`;

export const SelectEmptyStateWrapper = styled.div`
        display:flex;
        flex-direction:column;
        align-items:center; 
        padding:24px 0;
        gap:4px;
`;
export const SelectEmptyStateMessage = styled.div`
        color: ${colors.gray2};
        font-size: 14px;
        font-weight: 500;
`;

export const MobileCloseCreateCustomClassWrapper = styled.div`
    ${flexCenter};
    padding: 8px;
    margin-right: 12px;
`;