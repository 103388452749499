import { Observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { ModalCloseIcon } from '../../../../../../../assets/icons/common/common-icons';
import { BaseModal } from '../../../../../../../common/components/BaseModal/BaseModal';
import { ROUTES } from '../../../../../../../common/constants/routes.const';
import { colors } from '../../../../../../../common/styles/theme.styles';
import { useAnalytics } from '../../../../../../app/data/AnalyticsProvider';
import { useStore } from '../../../../../../app/data/root.store';
import { FormWithStore } from '../../../../../../form-displayer-connector/components/FormWithStore';
import { useFormHook } from '../../../../../../form-displayer-connector/data/useFormHook';
import { AttributeSections } from './BulkEditFormFields';
import { ActionButton, ModalCloseButtonWrapper, ModalContent, ModalFooter, ModalHeader } from './BulkEditModal.styles';

const titles = {
  beneficiary : ['entity','entities'],
  customClass : ['custom class','custom classes'],
  riskLevel : ['risk level','risk levels'],
  liquidity : ['liquidity level','liquidity levels'],
}


export const BulkEditModal = (props) => {
  const { onBackDropClick , handleActionClick , editedAttribute } = props;
  const { userStore, wealthStore } = useStore();
  const { mixpanelTrack } = useAnalytics()

  const formName = 'editBulkItemsForm';
  const editAttributeForm = useFormHook(formName);

  useEffect(() => {
    mixpanelTrack('Popup Viewed', {
      destination: ROUTES.wealthOverview.url,
      // location: 'Billing Page',
      title: `Edit selected items`,
      cta_text: 'Keep exploring',
      type: 'Bulk edit Modal',
      is_demo: userStore.isDemoMode
    })

    // return () => {}
  }, [mixpanelTrack, userStore.isDemoMode])

  // const handleActionClick = (action) => {
  //   mixpanelTrack('Popup Clicked', {
  //     click_type: 'CTA click',
  //     destination: ROUTES.wealthOverview.url,
  //     location: 'Billing Page',
  //     title: `Explore Vyzer for an Extra 90 Days Risk-Free!`,
  //     cta_text: `${action === 'cancel' ? 'I want to cancel' : 'Keep exploring'}`,
  //     type: 'Cancel Subscription Modal',
  //     is_demo: userStore.isDemoMode
  //   })
  //   if (action === 'cancel') {
  //     billingStore.setShowCancelSurveyModal(true);
  //   } else {
  //     navigateToUrl(history, ROUTES.wealthOverview.url)
  //   }
  //   onBackDropClick && onBackDropClick();
  // }

  const handleSaveClick = () => {
    if (editedAttribute === 'customClass') {
      handleActionClick(editAttributeForm.valuesForSave);
    } else {
      handleActionClick(editAttributeForm.valuesForSave[editedAttribute]);
    }
  }

  return (<Observer>{() => (
    <BaseModal width={'700px'} height={'auto'} borderRadius={8} onBackDropClick={onBackDropClick}>

      <ModalHeader>
        <ModalCloseButtonWrapper onClick={onBackDropClick}>
          <ModalCloseIcon />
        </ModalCloseButtonWrapper>
        Edit selected items ({wealthStore.selectedItemsForBulkEdit.length})

      </ModalHeader>
      <ModalContent>
        <FormWithStore formName={formName} sections={ AttributeSections[editedAttribute]} defaultValues={wealthStore.commonSingleSelectedAttributeForSelectedItem} />
        <div>
          {
            editedAttribute === 'customClass' ?
            `This action will override any current custom classification.`
            :
            wealthStore.numberOfDifferentSelectedAttributeForSelectedItem === 1 ?
            `This action will override any current ${titles[editedAttribute][0] }.`
            :
            `This action will override the ${wealthStore.numberOfDifferentSelectedAttributeForSelectedItem} current ${titles[editedAttribute][1] } assigned to these items.`
          }
        </div>
      </ModalContent>

      <ModalFooter>
        {/* <ActionButton onClick={() => { handleActionClick('cancel') }}>I want to cancel</ActionButton> */}
        <ActionButton onClick={handleSaveClick} backgroundColor={colors.darkGray1} whiteText>Save</ActionButton>
      </ModalFooter>

    </BaseModal>
  )}</Observer>)
}


