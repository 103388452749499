import { Tooltip } from 'antd';
import { Observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import DetectableOverflow from 'react-detectable-overflow';
import { useHistory } from 'react-router-dom';
import { monthDiff } from '../../../../../../common/utils/dates.utils';
import { navigateToUrl } from '../../../../../../common/utils/navigation.utils';
import { displayMoneyValue, floatFixed, numberWithCommas, percentageDisplay, quantityFormatWithCommas } from '../../../../../../common/utils/number-display.utils';
import { getValueByPath } from '../../../../../../common/utils/object.utils';
import { useStore } from '../../../../../app/data/root.store';
import { instSyncTimeAgo } from '../../../../../connected-institutions/components/institutions-panel/institutionPanel.commons';
import { HoldingValue, ItemSelectedCheckbox, ItemSelectedCheckboxWrapper, ListItemWrapper, TableCell, TableHeaderCell, TableHeaderWrapper } from '../../../class/components/ClassPage.styles';
import { ItemContent, ItemLogoWrapper, ItemMoreInfos, ItemText, ItemTitle, MoreInfosItem, TickerIconPlaceholder, TickerIconWrapper } from './AssetTickersTab/AssetsTickersTab.styles';
import { connectedBrokerAgePageTickersAttributes } from './categories-tickers-attributes.const';
import { CommonBadge } from '../../../../../../common/components/CommonBadge/CommonBadge';
import { ToggleClosedItemsVisibility } from '../../../ToggleClosedItemsVisibility/ToggleClosedItemsVisibility';

export const TickersList = (props) => {
  const { isClassView } = props;
  const { uiStore } = useStore();

  return (<Observer>{() => (uiStore.isDesktopView || isClassView ? <TickersListContent {...props} /> : <div style={{overflowX:'scroll'}}><div style={{width:'720px'}}>
      <TickersListContent {...props} />
    </div></div> )}</Observer>);

}

const TickersListContent = (props) => {
  const { tickers , isClassView, closedHoldingsInDisplayCount } = props;
  const { uiStore, dataLayersStore, wealthStore } = useStore();
  const history = useHistory();

  const [isTooltipNeeded, setIsTooltipNeeded] = useState(false);

  const handleOverflowChange = (isOverflowed) => {
    setIsTooltipNeeded(isOverflowed);
  }

  const handleTickerOnClick = (ticker) => {
    if (ticker.isSingleTicker) {
      navigateToUrl(history, `/wealth/assets/public-markets/${ticker.id}`);
    } else {
      navigateToUrl(history, `/wealth/assets/public-markets/${ticker.associateId}/stock/${ticker.id}`);
      wealthStore.refreshWealthData(false, history);
    }
  }

  const handleItemSelectedCheckboxClick = (e, ticker) => {
    e.stopPropagation();
    if (wealthStore.selectedItemsForBulkEdit.length === 0) {
      wealthStore.setIsBulkEditMode(true);
    }
    wealthStore.addOrRemoveItemForBulkEdit(ticker.id);
  }

  return (<Observer>{() => (
    <>
      {
        !isClassView && <>
          <TableHeaderWrapper matricesColumns={4} keepDesktopView={!isClassView}>
            <TableHeaderCell key={'hc1'} >Asset name</TableHeaderCell>
            {connectedBrokerAgePageTickersAttributes.map((dataL,index)=>(
              <TableHeaderCell key={'hc' + (index+2) } >{dataL.title}</TableHeaderCell>
            ))}
            
            <TableHeaderCell isValue key={'hc6' } >Market value</TableHeaderCell>
          </TableHeaderWrapper>
        </>
      }
      {tickers.filter(item => wealthStore.isShowClosedAssets ? true : !item.isClosed).map((ticker, index) => (
        <ListItemWrapper key={(ticker.isSingleTicker ? 'i' : 't') + ticker.id}
          matricesColumns={ isClassView ?( uiStore.isDesktopView ? dataLayersStore.dataLayersColumns.length : 0 ) : 4} lastRounded
          onClick={() => { handleTickerOnClick(ticker) }}
        >
          <TableCell>

            {uiStore.isDesktopView && isClassView && <ItemSelectedCheckboxWrapper disabled={ticker.isContainer} onClick={(e) => {handleItemSelectedCheckboxClick(e,ticker)}} isSelected={wealthStore.selectedItemsForBulkEdit.includes(ticker.id)} isBulkEditMode={wealthStore.isBulkEditMode}>
              <ItemSelectedCheckbox isSelected={wealthStore.selectedItemsForBulkEdit.includes(ticker.id)}>
                  {wealthStore.selectedItemsForBulkEdit.includes(ticker.id) && <ItemSelectedIcon />}
              </ItemSelectedCheckbox>
            </ItemSelectedCheckboxWrapper>}

            <ItemLogo ticker={ticker} /> 
            <ItemContent>
              <ItemText>
                <ItemTitle>
                  <DetectableOverflow onChange={handleOverflowChange}
                  style={{
                    wordWrap: 'break-word'
                  }}
                  >
                    <Tooltip title={isTooltipNeeded ? ticker.title : ''} placement={"topLeft"}>{ticker.title || ticker.type}</Tooltip>
                  </DetectableOverflow>
                  {uiStore.isDesktopView && ticker.isClosed && <CommonBadge badgeType={'closed'} />}
                </ItemTitle>
                <ItemMoreInfos>
                  <MoreInfosItem>
                {!uiStore.isDesktopView && ticker.isClosed && <CommonBadge noMargin badgeType={'closed'} />}
                    {/* TODO: add missing subtitle to endpoint */}
                    {/* {ticker.subTitle} */}
                  </MoreInfosItem>
                </ItemMoreInfos>
              </ItemText>
            </ItemContent>
          </TableCell>

          { isClassView ? <>
            {dataLayersStore.dataLayersColumns.map(dl => (
            <TableCell key={dl.dataKey} isValue={!uiStore.isDesktopView }>
              {RenderDataValue(dl, ticker, uiStore.isIncognito)}
            </TableCell>
          ))}
          </> : <>
          {connectedBrokerAgePageTickersAttributes.map(dl => (
            <TableCell key={dl.dataKey} withColors={dl.withSignColors} isPositive={ticker[dl.dataKey] >= 0} >
              {RenderDataValue(dl, ticker, uiStore.isIncognito)}
            </TableCell>
          ))}
          </> }

          { ( uiStore.isDesktopView || dataLayersStore.dataLayersColumns.length === 0 ) &&
          <TableCell isValue isCashFlow>
            {ticker.isClosed ? '--' : displayMoneyValue(ticker.value, ticker.itemCurrency || ticker.currency || ticker.isoCurrencyCode, uiStore.isIncognito, ticker.holdings ? ticker.holdings * 100 : 100)}
            { isClassView && !ticker.isClosed && <HoldingValue style={{fontSize:'12px',maxWidth:'100%' , whiteSpace:'nowrap', overflow:'hidden' , textOverflow:'ellipsis' }}><OptionalToolTip text={`(${quantityFormatWithCommas(ticker.quantity,4)} ${ticker.symbol})`} >({quantityFormatWithCommas(ticker.quantity,4)} {ticker.symbol})</OptionalToolTip></HoldingValue>}
          </TableCell> }

        </ListItemWrapper>
      ))}

      {closedHoldingsInDisplayCount ? <ToggleClosedItemsVisibility closedCount={closedHoldingsInDisplayCount} /> : null}

    </>
  )}</Observer>)
}

const RenderDataValue = (dataLayerColumn, item, isIncognito) => {

  const dataLayerValue = dataLayerColumn.path ? getValueByPath(item, dataLayerColumn.path) : item[dataLayerColumn.dataKey];

  if (!dataLayerValue && dataLayerValue !== 0 && dataLayerColumn.type !== 'bool') { return '--' }

  switch (dataLayerColumn.type) {
    case 'date':
      let toReturn;
      if (dataLayerColumn.dataKey === 'lastUpdate') {
        toReturn = instSyncTimeAgo(moment(dataLayerValue).toDate());
      } else {
        toReturn = moment(dataLayerValue).format('MMM YYYY');
      }
      return toReturn;
    case 'remainingTimeFromDate':
      if (moment(dataLayerValue).isValid) {
        const monthsDiff = monthDiff(new Date(), moment(dataLayerValue).toDate());
        return `${monthsDiff} Month${monthsDiff > 1 ? "s" : ''}`
      } else {
        return '--'
      }
    case 'money':
      return displayMoneyValue(dataLayerValue, item.categoryId === 36 ? 'USD' : item.isoCurrencyCode ? item.isoCurrencyCode : item.currency, isIncognito, dataLayerColumn.showHoldings ? ( item.holdings ? item.holdings * 100 : 100) : 100); // TODO: Think about this, make general solution ( not categoryId === )
    case 'number':
      return numberWithCommas(floatFixed(+dataLayerValue, 3));
    case 'percentage':
      return percentageDisplay(dataLayerValue);
    case 'percentageMax1':
      return percentageDisplay(dataLayerValue * 100);
    case 'multiplier':
      return numberWithCommas(parseFloat(dataLayerValue.toFixed(2))) + 'x';
    case 'text':
      let textValue = (dataLayerColumn.labels && dataLayerColumn.labels[dataLayerValue]) ? dataLayerColumn.labels[dataLayerValue] : dataLayerValue;
      return <FreeTextOverflow value={textValue} />
    case 'liabilityAmount':
      return Array.isArray(dataLayerValue) && dataLayerValue.length ? displayMoneyValue(dataLayerValue.reduce((a, b) => a + b.usdValue, 0), 'USD') : '--';
    case 'bool':
      return dataLayerValue ? 'V' : '--'
    default:
      return dataLayerValue
  }
};

const FreeTextOverflow = ({ value }) => {

  const textRef = useRef(null);
  const [isTooltipNeeded, setIsTooltipNeeded] = useState(false);

  useEffect(() => {
    const needToolTip = textRef.current && textRef.current.scrollWidth + 17 > textRef.current.parentElement.offsetWidth;
    setIsTooltipNeeded(needToolTip);
    return () => { }
  }, []);

  return (<Tooltip title={isTooltipNeeded ? value : ''} placement={"topLeft"}>
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} ref={textRef} > {value}</div>

    {/* {isTooltipNeeded && <ArrowDownWrapper><ArrowDownSmallTriangleIcon /></ArrowDownWrapper>} */}
  </Tooltip>
  )


};

const ItemLogo = ({ticker}) => {
  const [isCorruptedImage, setIsCorruptedImage] = useState(false);

  return (
    <ItemLogoWrapper>
      {ticker.logoUrl && !isCorruptedImage ?
        <TickerIconWrapper><img alt={ticker.symbol} src={ticker.logoUrl} onError={() => setIsCorruptedImage(true)} /></TickerIconWrapper> :
        <TickerIconPlaceholder>{(ticker.title && ticker.title !== '') ? ticker.title.charAt(0).toUpperCase() : (ticker.symbol && ticker.symbol !== '') ? ticker.symbol.charAt(0).toUpperCase() : (ticker.type && ticker.type !== '') ? ticker.type.charAt(0).toUpperCase() : ''}</TickerIconPlaceholder>}
      </ItemLogoWrapper>
  )
}

const OptionalToolTip = ({text}) => {

  const [isTooltipNeeded, setIsTooltipNeeded] = useState(false);
  const handleOverflowChange = (isOverflowed) => {
    setIsTooltipNeeded(isOverflowed);
  }

  return (
      <DetectableOverflow onChange={handleOverflowChange}>
        <Tooltip title={isTooltipNeeded ? text : ''} placement={"topRight"}>{text}</Tooltip>
      </DetectableOverflow>
  )
}

export const ItemSelectedIcon = () => (
  <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.7553 1.30005L3.25305 4.8043L1.50004 3.05182" stroke="white" strokeWidth="1.5"/>
</svg>

)