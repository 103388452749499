import axios from 'axios';



const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

export const getData = (url) => {
    return axios.get(baseApiUrl + url,{headers,withCredentials:true});
}

export const putData = (url,data) => {
     return axios.put(baseApiUrl + url,data,{headers,withCredentials:true}).then(res=>res.data);
}

export const patchData = (url,data) => {
     return axios.patch(baseApiUrl + url,data,{headers,withCredentials:true}).then(res=>res.data);
}

export const deleteData = (url) => {
      return axios.delete(baseApiUrl + url,{headers,withCredentials:true}).then(res=>res.data);
}

export const postData = (url,data) => {
    return axios.post(baseApiUrl+url,data,{headers,withCredentials:true}).then(res=>res.data);
}

export const postFormData = (url,data) => {
    return fetch( baseApiUrl + url,{
       method:'POST',
       // headers,
       credentials: 'include',
       body: data
    })
    .then( res => {return res.json()});
}

export const handleUrlFileDownload = async ({ url, fileName, body }) => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok.');
        }

        const blob = await response.blob();
        const downloadUrl = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.setAttribute('download', fileName);
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
    } catch (err) {
        console.error('Download failed:', err);
        // Handle the error as needed
    }
};