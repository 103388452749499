import { displayMoneyValue } from "../../../../common/utils/number-display.utils";
import { BoldedPriceText, MagicBoxCell, PlanPriceDescription, PlanPriceDiscountValue, PlanPriceValue, PlanTitleText } from "./ComparePlansModal.styles";

export const planPerks = (perkDataKey, tierIndex, selectedPlan, relevantPricingObj, isWithDiscount) => {
  const perkByTier = {
    '': [
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', minWidth: '140px' }}>
        <PlanTitleText>Starter</PlanTitleText>
        <PlanPriceDescription>{`Explore the Power of\nVyzer. Risk-Free`}</PlanPriceDescription>
        <PlanPriceValue>
          <BoldedPriceText>Free</BoldedPriceText>
        </PlanPriceValue>
      </div>,
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', minWidth: '140px' }}>
        <PlanTitleText>Plus</PlanTitleText>
        <PlanPriceDescription>{`Tailored for Passive\nIncome Investors`}</PlanPriceDescription>
        <PlanPriceValue>
          <BoldedPriceText>{displayMoneyValue(Math.floor((isWithDiscount ? relevantPricingObj.originalPriceAfterDiscount : relevantPricingObj.originalPrice) / 100 / (selectedPlan === 'year' ? 12 : 1)), 'USD')}</BoldedPriceText>
          {isWithDiscount && <PlanPriceDiscountValue>{displayMoneyValue(Math.floor(relevantPricingObj.originalPrice / 100 / (selectedPlan === 'year' ? 12 : 1), 'USD'))}</PlanPriceDiscountValue>}
          <div>/month</div>
        </PlanPriceValue>
      </div>,
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', minWidth: '140px' }}>
        <PlanTitleText>Premium</PlanTitleText>
        <PlanPriceDescription>{`Designed for Diverse\nPortfolios Investors`}</PlanPriceDescription>
        <PlanPriceValue>
          <BoldedPriceText>{displayMoneyValue(Math.floor((isWithDiscount ? relevantPricingObj.originalPriceAfterDiscount : relevantPricingObj.originalPrice) / 100 / (selectedPlan === 'year' ? 12 : 1)), 'USD')}</BoldedPriceText>
          {isWithDiscount && <PlanPriceDiscountValue>{displayMoneyValue(Math.floor(relevantPricingObj.originalPrice / 100 / (selectedPlan === 'year' ? 12 : 1), 'USD'))}</PlanPriceDiscountValue>}
          <div>/month</div>
        </PlanPriceValue>
      </div>,
      <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', minWidth: '140px' }}>
        <PlanTitleText style={{display:'flex', alignItems:'center'}}>Elite <FamilyOfficeSvg size={'26'} /></PlanTitleText>
        <PlanPriceDescription>{`Ultimate Wealth\nManagement Experience`}</PlanPriceDescription>
        <PlanPriceValue>
          <BoldedPriceText>{displayMoneyValue(Math.floor((isWithDiscount ? relevantPricingObj.originalPriceAfterDiscount : relevantPricingObj.originalPrice) / 100 / (selectedPlan === 'year' ? 12 : 1)), 'USD')}</BoldedPriceText>
          {isWithDiscount && <PlanPriceDiscountValue>{displayMoneyValue(Math.floor(relevantPricingObj.originalPrice / 100 / (selectedPlan === 'year' ? 12 : 1), 'USD'))}</PlanPriceDiscountValue>}
          <div>/month</div>
        </PlanPriceValue>
      </div>,
    ],
    'Portfolio size': [3, 'Unlimited', 'Unlimited', 'Unlimited'],
    'Sync online banks and brokerage platforms': [3, 'Unlimited', 'Unlimited', 'Unlimited'],
    'Holding entity': [1, 5, 10, 'Unlimited'],
    'Cash flow scenarios': [2, 10, 20, 'Unlimited'],
    'Magic Box': [<MagicBoxCell>1 file / month<br/>Unlimited for setup</MagicBoxCell>,<MagicBoxCell>5 files / month<br/>Unlimited for setup</MagicBoxCell>,<MagicBoxCell>20 files / month<br/>Unlimited for setup</MagicBoxCell>, 'Unlimited'],
    'Transaction tracking & Notifications': [<CheckMarkSvg />, <CheckMarkSvg />, <CheckMarkSvg />, <CheckMarkSvg />],
    'Performance insights': [<CheckMarkSvg />, <CheckMarkSvg />, <CheckMarkSvg />, <CheckMarkSvg />],
    'Reports and statements': ['', <CheckMarkSvg />, <CheckMarkSvg />, <CheckMarkSvg />],
    'Community benchmarking': ['', <CheckMarkSvg />, <CheckMarkSvg />, <CheckMarkSvg />],
    'Mobile app': ['', <CheckMarkSvg />, <CheckMarkSvg />, <CheckMarkSvg />],
    'VIP syncing service': [1, 3, 10, 'Unlimited'],
    'Role management': ['', '', <CheckMarkSvg />, <CheckMarkSvg />],
  }
  return perkByTier[perkDataKey][tierIndex];

}

const CheckMarkSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.5105 8.604L10.506 15.6125L6.99995 12.1075" stroke="#1A1B1D" strokeWidth="2" />
    </svg>
  )
}

const FamilyOfficeSvg = ({ color, size }) => {
  return (
    <svg width={size || "20"} height={size || "20"} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.1101 8.90597L10 2.76758V2.76767L6.88988 8.90606L3.125 5.22303V13.0802H10V13.0801H16.875V5.22294L13.1101 8.90597Z" fill={color || "#1A1B1D"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M16.1875 16.1905H3.8125V14.5405H16.1875V16.1905Z" fill={color || "#1A1B1D"} />
    </svg>
  )
}