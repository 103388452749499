import styled from '@emotion/styled/macro';
import { Link } from "react-router-dom";
import { flexCenter } from '../../../common/styles/combos.styles';
import { colors, shadows, views } from '../../../common/styles/theme.styles';

export const PageWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex: 1 1;
    // overflow-y: scroll;
    // padding:0px 0px 16px;
    // align-items: center;
    position:relative;
    overflow:hidden;
    ${views.desktop}{
        background-color: ${colors.lightGray2};
    }
`   


export const PageContent = styled.div`
    // width: 100%;
    max-width:1200px;
    margin:0 auto;
    display: flex;
    flex-direction: column; // row;
    flex: 1 1;
    overflow-y: auto;
    position: relative;
    /* justify-content: center; */
    // padding-bottom:56px;
    padding: 16px 16px 0px 16px;
    /* gap: 8px; */
    /* padding:16px 0px; */
    // padding-inline-start:calc(100vw - 100%);
    /* padding:0px 0px 16px; */
    ${views.tablet}{
        padding:0;
        padding-bottom: calc( env(safe-area-inset-bottom) + 64px );
    }
`;

export const ContentWithEmptyStateBannerWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    flex: 1 1;
`;

export const ChartAndScenariosWrapper = styled.div`
    width: 100%;
    max-width: 880px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
`;

export const TransactionsWrapper = styled.div`

`;

export const HeaderWrapper = styled.div`
    background-color:#282d33;
    padding:16px;
    width: 100%;
`;

export const HeaderContentWrapper = styled.div`
    display:flex;
    flex-direction:column;
    color:#9097A3;
`;

export const HeaderMainValue = styled.div`
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    color:#fff;
`

export const HeaderDateText = styled.span`
    color:#fff;
    font-size: 14px;
`

export const HeaderBoxLink = styled(Link)`
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
    display:flex;
    align-items: center;
    color:#fff;
`;

export const HeaderTooltipLink = styled(Link)`
        display: flex;
        justify-content: space-between;
        color: #343739;
        font-size: 14px;
        .account-name{
            max-width: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 16px;
            // color: #343739;
        }
`

export const HeaderAccountsDetails = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;
    padding-top:24px;
`;

export const HeaderAccountItemWrapper = styled.div`
    display:flex;
    align-items: center;
    padding:0 16px;
    position: relative;
    // flex-direction:column;
    &:before{
        content:'';
        position:absolute;
        height:1px;
        left:0;
        right:0px;
        top:-16px;
        background-color:#505D66;
    }
    &:last-child{
        &:before{
            right:50%;
        }
    }
    &:first-of-type{
        &:before{
            left:calc(50% + 1px);
        }
    }
    &:after{
        content:'';
        position:absolute;
        width:1px;
        height:12px;
        left:50%;
        //right:0px;
        top:-16px;
        background-color:#505D66;
    }
`;

export const HeaderAccountItemIcon = styled.div`
    width:24px;
    height:24px;
    margin-right:8px;
    background-color:#343a3f;
`;
export const HeaderAccountItemText = styled.div`
    display:flex;
    flex-direction:column;
    align-items: start;
`;

export const HeaderAccountItemName = styled.div`
    font-size: 14px;
    color:#fff;
`;
export const HeaderAccountItemValue = styled.div`
    font-size: 16px;
    color:#fff;
`;

export const ChartAndSimulationsWrapper = styled.div`
    width: 100%;
    display:flex;
    flex-direction:row;
    margin-bottom:24px;
`

export const ChartBoxWrapper = styled.div`
    width: 100%;
    // height: 540px;
    /* padding: 24px; */
    background-color: #FFFFFF;
    // box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    position:relative;
    box-shadow:${shadows.cardShadow};
    border: 1px solid ${colors.gray3};
    ${views.tablet}{
        border:none;
        border-radius: 0px;
        background-color: #111213;
    }
`;

export const ChartTopHeaderWrapper = styled.div`
    ${flexCenter}
    border-bottom:1px solid ${colors.gray3};
    padding:14px 24px;
    justify-content:flex-start;
`

export const ChartTopHeaderTitleWrapper = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    align-items: flex-start;
`;

export const ChartTopHeaderTitle = styled.div`
    flex:1;
    color:${colors.darkGray1};
    font-size:18px;
    line-height:16px;
    font-weight:500;
    margin-bottom:${props => props.noMargin ? 0 : 8 }px;
    /* ${flexCenter};
    justify-content:flex-start;
    svg{
        margin-right:12px;
    } */
`;
export const ChartTopHeaderSubTitle = styled.div`
    flex:1;
    color:${colors.darkGray3};
    font-size:13px;
    line-height:14px;
    font-weight:450;
    /* ${flexCenter};
    justify-content:flex-start;
    svg{
        margin-right:12px;
    } */
`;

export const CashAccountsLinkWrapper = styled.div`
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
    /* height:70px; */
    ${flexCenter}
    border-radius:6px;
    padding:2px 6px 2px 8px;
    background-color:${colors.lightGray2};
    svg path{
        stroke:${colors.darkGray3};
    }
    cursor: pointer;
`;

export const CashFlowLinkText = styled.div`
    color:${colors.darkGray2};
    font-size:14px;
    font-weight:500;
    margin-right:8px;
    position:relative;
    ${flexCenter};
    ::after{
        content:'';
        position:absolute;
        width:0%;
        height:1px;
        left:50%;
        bottom:0;
        background-color:${colors.darkGray1};
        transition:all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1);
    }
    ${CashAccountsLinkWrapper}:hover &{
        ::after{
            width:100%;
            left:0%;
        }
        // color:#2299ff;
    }
`;

export const ChartHeaderWrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    padding: 16px 24px;
    h2{
        color:#000;
        margin-bottom:0px;
    }
    ${views.tablet}{
        align-items: start;
        padding-top: 4px;
    }
`;

export const ChartHeaderTitleWrapper = styled.div`
    flex:1;
    display:flex;
    flex-direction:column;
    // justify-content:space-between;
    align-items:flex-start;
    color:${colors.darkGray1};
    ${views.tablet}{
        align-items: stretch;
    }
`;

export const ChartHeaderTitleText = styled.div`
    color:${colors.darkGray1};
    font-size:14px;
    font-weight:500;
    line-height:17px;
    ${flexCenter};
    cursor: pointer;
`;

export const ChartHeaderTextDropdownIconWrapper = styled.div`
    width: 18px;
    height: 18px;
    border-radius:50%;
    background-color: ${props=> props.isOpen ? colors.lightGray2 :  'transparent'};
    ${flexCenter};
    transform:rotate(${props=> props.isOpen ? 180 : 0}deg);
    transition:all 0.2s;
    margin:0 4px;
`;
export const ChartHeaderTitleValue = styled.div`
    font-size:32px;
    line-height:38px;
    ${views.tablet}{
        color:#ffffff;
        font-size:36px;
        font-weight: 500;
    }
`;

export const ChartHeaderInputsWrapper = styled.div`
    display:flex;
    flex-direction:row;
`
export const ChartSelectButtonWrapper = styled.div`
    position:relative;
    .ant-select-selector{
        height:30px;
        overflow:hidden;
        cursor: pointer!important;
    }
    .ant-select-selection-item{
        cursor: pointer!important;
    }
`;

export const ChartDisplayAccountsDropdownButton = styled.div`
    border: 1px solid #CBD8E1;
    display:flex;
    flex-direction:row;
    //margin-left:16px;
    width:160px;
    height:100%;
    align-items:center;
    justify-content:space-between;
    padding:0 12px;
    .accountsSum{
        background-color:#EFF1F3;
        padding:0px 8px;
    }
`;

export const ChartDisplayAccountsDropdownMenu = styled.div`
    width: 208px;
    border: 1px solid #CBD8E1;
    margin-top:8px;
`;

export const ChartDisplayPeriodInputWrapper = styled.div`
    border: 1px solid #CBD8E1;
    display:flex;
    flex-direction:row;
    margin-left:16px;
    
    ${views.tablet}{
        border: 1px solid ${colors.darkGray2};
        border-radius: 10px;
        // gap:4px;
        padding:2px;
    }
`;

export const ChartDisplayPeriodOption = styled.div`
    color: ${props=> props.selected ? '#343739' : '#A3A9AD'}; 
    font-size:14px;
    font-weight: ${props=> props.selected ? '600' : 'normal'}; 
    padding:4px 8px;
    cursor: pointer;
    ${views.tablet}{
        border-radius: 8px;
        background-color: ${props=> props.selected ? colors.darkGray2 : 'none'}; 
        color: ${props=> props.selected ? '#fff' : colors.gray1}; 
    }
`;

export const ScenariosCardWrapper = styled.div`
    background-color:#fff;
    box-shadow:${shadows.cardShadow};
    overflow:hidden;
    border-radius: 8px;
    width:100%;
    border: 1px solid ${colors.gray3};
    margin-top:16px;
    max-height:100%;
    margin-bottom:16px;
    // @media (max-height: 979px) {
    //     height:100%;
    // }
    // @media (min-height: 980px) {
    //     margin-bottom:16px;
    // }
    
    
`

export const ChartWrapper = styled.div`
    width: 100%;
    // height: ${props=> props.isHoverScenarios ? '200' : '340'}px;
    height: 150px;
    @media (min-height: 640px) {
        height: 200px;
    }
    @media (min-height: 880px) {
        height: 340px;
    }
    background-color: #FFFFFF;
    padding:0 24px;
    border-radius:0 0 8px 8px;
    // transition: height 0.1s;
    // @media (min-height: 980px) {
    //     height:340px;
    // }
    ${views.tablet}{
        border-radius:0;
        background-color: #111213;
        padding:0 16px;
    }
`;








export const LegendsWrapper = styled.div`
    display:flex;
    justify-content:center;
    padding: 8px 40px;
    flex-wrap: wrap;
    // ${views.tablet}{
    //     padding:0px;
    // }
`;


export const SimulationsWrapper = styled.div`
    padding:24px 0 0 16px;
    color:#97A0A9;
    text-align:left;
    h3{
        font-size:16px;
        color:#97A0A9;
        .anticon{
            padding-right:8px;
        }
    }
    .sectionDescription{
        font-size:14px;
        // padding-left:25px;
        display: block;
    }
`;

export const SimulationsActionWrapper = styled.div`
    text-align:center;
    /* margin-top:16px; */
    /* border-top: 1px solid ${colors.gray3}; */
    /* padding: 16px 0; */
    width:100%;
`;
export const AddSimulationButton = styled.div`
    //background-color:green;
    background: #FDFDFD;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08), 0px 8px 16px rgba(0, 0, 0, 0.04); //${shadows.cardShadow};
    border-radius: 8px;
    color:${colors.darkGray1}; 
    padding: 0 16px;
    min-height: 56px;
    font-size: 14px;
    ${flexCenter};
    display:inline-flex;
    width: 100%;
    cursor: pointer;
    svg{
        margin-right: 4px;
    }
`

export const CenterContent = styled.div`
    width: 100%;
    max-width: 1160px;
    padding: 0 16px;
    margin: 0 auto;
`;

export const CashAccountsWrapper = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin: 0 -16px;
`

export const CashAccountBox = styled.div`
    background: rgb(255, 255, 255);
    box-shadow: rgb(0 0 0 / 8%) 0px 8px 24px;
    width:calc(25% - 32px);
    margin:16px;
    padding: 16px;
`;

export const CashAccountBoxTitle = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`

export const CashAccountBoxValue = styled.div`
    font-size:22px;
    font-weight:bold;
`

export const FutureTransactionsWrapper = styled.div`
    text-align: left;
    padding-top: 16px;
`

export const FutureTransactionsTable = styled.div`
    .tableRow{
        display: grid;
        grid-template-columns: repeat(5, 1fr) 120px 120px;
    }
`

export const SimulationsTableWrapper = styled.div`
    text-align: left;
    padding-top: 16px;
`

export const SimulationsTable = styled.div`
    .tableRow{
        display: grid;
        grid-template-columns: repeat(4, 1fr) 120px 120px 100px;
    }
`

export const CostOfLivingFormDescription = styled.div`
    background: #F8F9FB;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.32), 0px 2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    margin: 24px;
    padding:16px;
    text-align:left;
`;

export const CostOfLivingModalHeader = styled.div`
    display: flex;
    width: 100%;
    padding: 16px;
    color: #000000d9;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    border-radius: 2px 2px 0 0;
`;

export const CostOfLivingModalTitle = styled.div`
    margin: 0;
    color: #000000d9;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    word-wrap: break-word;
`;

export const CostOfLivingModalClose = styled.div`
    display: grid;
    margin-left: auto;
    order: 2;
    place-items: center;
    width: 32px;
    cursor: pointer;
`;

export const CostOfLivingModalContent = styled.div`
    background-color: #F7F8F9;
    .fd-sectionWrapper{
        padding-top:0;
    }
    //// very ugly, do not use important ever - TODO: fix soon with new form displayer style.
    .fd-inputLabel{
        font-weight: 500!important;
        font-size: 14px!important;
    }
    .ant-input{
        font-size: 14px!important;
        border-radius: 4px!important;
        box-shadow: 0px 0px 2px rgb(0 0 0 / 24%), 0px 2px 4px rgb(0 0 0 / 4%)!important;
    }
    .ant-picker{
        font-size: 14px!important;
        border-radius: 4px!important;
        box-shadow: 0px 0px 2px rgb(0 0 0 / 24%), 0px 2px 4px rgb(0 0 0 / 4%)!important;
    }
    .ant-select{
        font-size: 14px!important;
        border-radius: 4px!important;
        box-shadow: 0px 0px 2px rgb(0 0 0 / 24%), 0px 2px 4px rgb(0 0 0 / 4%)!important;
        .ant-select-selector{
            height: 32px!important;
            line-height:32px!important;
            .ant-select-selection-item{
                height: 32px!important;
                line-height:32px!important;
            }
        }
        
    }
    .ant-dropdown-trigger{
        border-radius: 4px!important;
        box-shadow: 0px 0px 2px rgb(0 0 0 / 24%), 0px 2px 4px rgb(0 0 0 / 4%)!important;
    }
`;

export const CostOfLivingModalFooter = styled.div`
    padding: 16px 24px;
    color: #000000d9;
    background: #fff;
    border-top: 1px solid #f0f0f0;
    border-radius: 0 0 2px 2px;
    display:flex;
    justify-content:space-between; 
`;

export const CostOfLivingModalButton = styled.button`
    background-color: ${props => props.disabled ? 'grey' : 'black'};
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.32), 0px 2px 8px rgba(0, 0, 0, 0.08);
`;


export const CashAccountsDropDownWrapper = styled.div`
    width:340px;
    border: 1px solid ${colors.gray3};
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.05), 0px 0px 4px rgba(0, 0, 0, 0.05);
    border-radius: 0px 8px 8px 8px;
    background-color:#fff;
`; 

export const CashAccountsDropDownTopText = styled.div`
    padding:12px;
    // border-bottom:1px solid ${colors.gray3};
    color:${colors.darkGray2};
    line-height:16px;
`;

export const CashAccountsDropDownAccountsList = styled.div`
    border-top:1px solid ${colors.gray3};
    padding: 8px 12px;
`;

export const CashAccountsDropDownAccountRow = styled.div`
    ${flexCenter};
    justify-content:space-between;
    color:${colors.darkGray1};
    :not(:last-of-type){
        margin-bottom:8px;
    }
`;


export const CashAccountLogoWrapper = styled.div`
    height:26px;
    width:26px;
    border-radius: 23px;
    margin-right:12px;
    /* margin-left:24px; */
    img{
        height:26px;
        width:26px;
    }
`;

export const ClassIconWrapper = styled.div`
    height:26px;
    width:26px;
    border-radius: 23px;
    background-color:#82AAFA;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:12px;
    svg{
        width:16px;
        height:16px;
    }
    path{
        // transition:all 0.2s;
        /* stroke:#4F699D; */
        fill:#fff;
    }
`;



export const CashAccountTitle = styled.div`
    flex:1;
    
`;

export const CashAccountValue = styled.div`
    
`;

export const CashFlowMobileHeader = styled.div`
    
    position: ${props=>props.isHeaderFixed ? `fixed` : `relative`};
    top: 0;
    background-color: #111213;
    z-index: 2;
    width: 100%;
    min-height: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    // ${flexCenter}
`;

export const HeaderTitle = styled.div`
    font-weight: 450;
    font-size: 18px;
    color: #fff;
`;

export const MobileContentHeader = styled.div`
    background-color:#fff;
    position: relative;
    height: 50px;
    // ${flexCenter};
    ${props=>props.isFixed ? `
        position: fixed;
        top:64px;
        width:100%;
    ` : `
    `};
    border-bottom:1px solid #DDE3EB;
    z-index: 1;
`;


export const MobileHeaderTabsWrapper = styled.div`
    // ${flexCenter};
    // justify-content:flex-start;
    // gap:12px;
    // padding-left:24px;
    // margin-bottom: -9px;
    width: 100%;
    
    .ant-tabs-nav-list{
        flex:1;
    }

    .ant-tabs-tab{
        margin:0px;
        padding: 14px;
        flex:1;
        justify-content: center;
    }
    .ant-tabs-tab-btn{
        color:#3F3F45;
        font-weight: normal;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color:#3F3F45;
        font-weight:normal;
    }

    .ant-tabs-ink-bar{
        background-color:#3F3F45;
    }

    .ant-tabs-top > .ant-tabs-nav{
        margin-bottom: 0px;
    }

    .ant-tabs-nav-more {
        position: absolute;
        visibility: hidden;
        pointer-events: none;
    }
`;

export const MobileHeaderTab = styled.div`
    flex:1;
`;

export const MobileTitleDate = styled.div`
    color:${colors.darkGray3};
    font-size: 15px;
    font-weight: 450;
    text-transform:uppercase;
`;

export const MobileTitleDescription = styled.div`
    color:${colors.lightGray1};
    font-size: 13px;
    line-height: 13px;
    font-weight: 450;
    margin-top: 4px;
`;

export const MobileExpectedInflowOutflowWrapper = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

export const MobileInflowBoxWrapper = styled.div`
    flex:1;
    display: flex;
    flex-direction: column;
    border-left:2px solid  ${props => props.isInflow ? colors.inflowText  : colors.outflowText};
    padding: 2px 0px 4px 8px;
`;

export const MobileInflowBoxValue = styled.div`
    font-size: 22px;
    line-height: 22px;
    font-weight: 500;
    color:${props => props.isInflow ? colors.inflowText  : colors.outflowText};
`;
export const MobileInflowBoxDescription = styled.div`
    font-size: 13px;
    line-height: 13px;
    font-weight: 450;
    margin-top: 4px;
    color:${props => props.isInflow ? colors.inflowText  : colors.outflowText};
`;


//
// export const MobileContentWrapper