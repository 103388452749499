import styled from '@emotion/styled/macro';
import { flexCenter, textOverflow } from '../../../../../common/styles/combos.styles';
import { colors, shadows, sizes, views } from '../../../../../common/styles/theme.styles';

export const PageWrapper = styled.div`
    width:100%;
    text-align:left;
`   


export const HeroWrapper = styled.div`
    height:400px;
    background: #000000;
    /* Card Shadow */
    box-shadow: ${shadows.cardShadow};
    border-radius: 4px;
    padding:62px;
    display:flex;

`;

export const HeroDataBox = styled.div`
    flex:1;
`;
export const ChartWrapper = styled.div`
     // padding:16px 0px;
     flex:1;
`;

export const WealthStats = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    margin-top:16px;
`;

export const StatBox = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    padding:8px 40px;
    &:not(:last-child){
        border-right:1px solid #DBDFE2;
    }
    .stat-title{
        font-size:14px;
        color:#9097A3;
    }
`;

export const StatTitle = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    font-size:16px;
    color:#3F3F45;
    span{
        padding-left: 8px;
    }
    // color: #21242A;
`;

export const StatValue = styled.div`
    font-weight: 500;
    font-size:${props=>props.isIncognito ? '12px' : '22px'};
    color: #3F3F45;
`;


export const Title = styled.div`
    position:relative;
    width:100%;
    padding:16px 0;
    font-weight:400;
    font-size:32px;
    padding:96px 0 32px;
`

export const ItemsTitleWrapper = styled.div`
    width:100%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    top:16px;
`
export const ItemsTitle = styled.div`
    font-size: 24px;
    display: flex;
    align-items: center;
`;
export const ItemsTotalCount = styled.span`
    height:24px;
    width:24px;
    background: #2A2D33;
    font-size: 14px;
    color:#fff;
    display:inline-flex;
    justify-content:center;
    align-items:center;
    margin-left:8px;
`;
export const ItemsTotalValue = styled.div`
    font-size: 24px;;
`;

export const ClassesItemsWrapper = styled.div`
    margin:0 -12px 40px -12px;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
`;

export const AssetClassItem = styled.div`
    width: ${props=>props.isAddMode || props.isClassViewMode ? '100%' : 'calc(50% - 24px)'}; 
    margin:12px;
    background: #FFFFFF;
    border: 1px ${props=>props.isAdd ? 'dashed #848484' : 'solid #E7E7E7'};
    border-radius:4px;
    padding:0px;
    display:flex;
    flex-direction:column;
    align-items:center;
    transition:all 0.3s;
    cursor: ${props=>props.isActive ? 'default' : 'pointer'};
    color: ${props=>props.isAdd ? '#848484' : '#2A2D33'};
    position:relative;
    min-height:74px;
`;

export const AssetClassItemRow = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    width:100%;
    padding:16px;
    ${props=>props.isClassViewMode ? `
        position:sticky;
        top:0px;
        border-bottom: 1px solid #E7E7E7;
        background:#FFFFFF;
        z-index:1;
    ` : ``}
`;


export const AssetClassItemAddPanelAnimationWrapper = styled.div`
    display:flex;
    width:100%;
    overflow:hidden;
    border-top: ${props=>props.isShown ? '1px' : '0px' } solid #E7E7E7;
    padding:  ${props=>props.isShown ? '16px 24px 16px 88px' : '0px' };
    transition:all 0.3s;
    height: ${props=>props.isShown ? '80px' : '0px' };
`;

export const AssetClassItemsListWrapper = styled.div`
    display:flex;
    width:100%;
    margin-top: 16px;
    padding:0px 24px 0px 88px;
    flex-direction:column;
`;

export const AssetClassItemAddFormWrapper = styled.div`
    display:flex;
    flex-direction:row;
    align-items: flex-end;
    justify-content:space-between;
    width:100%;
`;

export const AssetItemAddButtonWrapper = styled.div`
    position:absolute;
    width:${props=>props.isActive ? '72px' : '0px'};
    top:0px;
    bottom:0px;
    left:0px;
    background-color:#5a5a5a9c;
    transition:width 0.3s;
    overflow:hidden;
    display:flex;
    justify-content:center;
    align-items:center;
    z-index:2;
    ${AssetClassItem}:hover &{
        /* background-color:red; */
        width:72px;
    }
    .anticon{
        transition:margin 0.3s;
        margin-top:${props=>props.isActive ? '76px' : '0px'};
    }
`;

export const AssetClassIconWrapper = styled.div`
    width: 40px;
    height: 40px;   
    border-radius: 4px;
    background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        width:20px;
        height:20px;
    }
    path{
        /* stroke:#4F699D; */
        fill:#623e2e;
    }
`

export const AssetClassDescription = styled.div`
    flex:1;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
    padding:0 16px;
    transition:padding 0.3s;
    ${props=>props.isAddMode ? `padding-left:32px;`:``}
    ${AssetClassItem}:hover &{
        padding-left:32px;
    }
`

export const AssetClassText = styled.div`
    display:flex;
    flex-direction:column;
`;

export const AssetClassTitle = styled.div`
  font-size:14px;
`;
export const AssetClassItemsCount = styled.div`
    font-size:12px;
    color: #9097A3;
`;

export const AssetClassTotal = styled.div`
    font-size:14px;
    padding-right:8px;
`

export const HeroDataBoxWrapper = styled.div`
    color:#fff;
    flex:1;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    height:100%;
    text-align:center;
`;

export const HeroDataBoxStatWrapper = styled.div`
    :not(:last-child){
        margin-bottom: 32px;
    } 
    @media (max-width: ${sizes.contentWidth-32}px) {
        ${props=>props.marginBottomMobileView ? `
            margin-bottom:32px;
        ` : ``}; 
    }
    
`;
export const HeroDataBoxStatLabel = styled.div`
    color:${colors.darkGray2};
    font-size:14px;
    line-height:16px;
`;

export const HeroDataBoxStatValue = styled.div`
    font-size:${props=>props.isIncognito ? '14px' : '24px'};
    line-height:28px;
    color:#fff;
`;


export const ListItemWrapper = styled.div`
    ${flexCenter};
    padding:12px 0;
    /* :not(:last-child){
        margin-bottom: 8px;
    }  */
    cursor: pointer;
    transition:background-color 0.2s;
    :hover{
        background-color:${colors.lightGray1};
    }
    ${props=>props.customStyle? props.customStyle : `` }
`;

export const ItemDetailsWrapper = styled.div`
    ${flexCenter};
`;

export const ItemIconWrapper = styled.div`
    margin-left:24px;
    height:32px;
    width:32px;
    border-radius: 2px;
    background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:12px;
    svg{
        width:24px;
        height:24px;
        path{
            /* transition:all 1s; */
            fill:#fff;
        }
        rect{
            /* transition:all 1s; */
            fill:#fff;
        }
    }
    
    /* ${ListItemWrapper}:hover &{
        path{
            fill:#3e3e3e;
        }
    } */
`;

export const AllocationItemIconWrapper = styled.div`
    margin-left:24px;
    height:32px;
    width:32px;
    border-radius: 2px;
    background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:12px;
    svg{
        width:24px;
        height:24px;
    }
`;

export const AllocationInstitutionItemIconWrapper = styled.div`
    margin-left:24px;
    height:32px;
    width:32px;
    border-radius: 2px;
    // background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right:12px;
    svg{
        width:26px;
        height:26px;
    }
`;

export const ItemContent = styled.div`
    flex:1;
    display:flex;
    align-items:center;
`;

export const AllocationItemContent = styled.div`
    flex:1;
    display:flex;
    flex-direction: column;
    align-items:flex-start;;
    justify-content: center;
`;

export const AllocationItemCountsWrapper = styled.div`
    ${flexCenter};
    font-size:12px;
    font-weight: 450;
    color:${colors.darkGray3};
    gap:4px;
`

export const AllocationItemCountSeparator = styled.div`
    width:2px;
    height: 2px;
    border-radius: 50%;
    background-color: ${colors.darkGray3};
`;


export const ItemTitle = styled.div`
    color:${colors.darkGray1};
    font-size:16px;
    line-height:19px;
    font-weight:500;
`;

export const LightColorText = styled.span`
    color:${colors.gray2};
`;
export const IndicationsWrapper = styled.div`
    ${flexCenter};
    display:inline-block;
    margin-left:8px;
`;

export const IndicationCircle = styled.div`
    width:8px;
    height:8px;
    background-color: ${props=>props.isEventPass ? colors.redNotification : '#82AAFA'} ;
    border-radius: 20px;
    margin-right:${props=>props.isClassView ? 0 : 8}px;
`;

export const ItemValue = styled.div`
    color:${colors.darkGray1};
    font-size:14px;
    // line-height:19px;
    // padding-right:24px;
`;

export const DataLayersIconWrapper = styled.div`
    ${flexCenter};
    cursor: pointer;
    padding: 0px 16px 0px 12px;
    border-radius: 20px;
    box-shadow: ${shadows.toggleShadow};
    gap:4px;
    height: 34px;
    :hover {
        outline: 1px solid ${colors.gray3};
    }
    :active {
        outline: 1px solid ${colors.darkGray3};
    }
`;

export const DataLayersIconWithText = styled.div`
    ${flexCenter}
    padding-right: 8px;
    border-right: 1px solid ${colors.gray3};
`;

export const SortHoverIconWrapper = styled.div`
    display: none;
`;

export const TableHeaderCell = styled.div`
    font-size:12px;
    line-height:14px;
    color:${colors.gray1};
    ${flexCenter};
    /* justify-content:${props=>props.isTitle ? 'flex-start' : 'flex-end'}; */
    justify-content:${props=>props.isValue ? 'flex-end' : 'flex-start'};
    // text-align:${props=>props.isTitle ? 'left' : 'right'};
    padding:0px 8px;
    // border-right:1px solid ${colors.gray3};
    position: relative;
    user-select: none;
    ${props=>props.clickable ? 'cursor:pointer;' : ''}
    ::after{
        content:' ';
        position:absolute;
        right:0;
        width:1px;
        top:calc(50% - 8px);
        height:16px;
        background-color:${colors.gray3};
    }
    :first-of-type{padding-left:24px;}
    :last-of-type{padding-right:24px;
        border-right:none;
        ::after{
            content:none;
        }
    }
    :hover{
        ${SortHoverIconWrapper} {
            display: flex;
            /* margin-left:8px; */
            ${props=>props.isValue ? `margin-right:8px;` : `margin-left:8px;`}
        }
    }
`;

export const TableHeaderTextWrapper = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const TableHeaderSortIconWrapper = styled.div`
    display: flex;
    ${props=>props.isValue ? `margin-right:8px;` : `margin-left:8px;`}
    ${props=>props.isAsc ? 'transform: rotate(180deg)' : ''} 
`;

export const MobileSortButtonWrapper = styled.div`
    ${props=>props.isAsc ? 'transform: rotate(180deg)' : ''};
    ${flexCenter};
    padding:8px;
`;

export const ShowHideClassButton = styled.div`
    ${flexCenter};
    position:absolute;
    cursor:pointer;
    bottom:-40px;
    ${props => props.isCashFlowView ? `
        right:0%;
    ` : `
        left:50%;
        transform:translateX(-50%);
    `}
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    color:#41557D;
    svg{
        margin-left:4px;
        transition:0.2s;
        path{
            stroke:#41557D;
        }
        transform:rotate(${props=>props.isOpen ? '0' : '180'}deg);
    }
    
`;

export const InflowOutflowCalculationMessage = styled.div`
    font-size: 12px;
    line-height: 18px;
    // color: ${colors.darkGray3};
    color:#41557D;
    font-weight: 450;
    position:absolute;
    bottom:-40px;
    left:0%;
    ${views.tablet}{
        padding: 20px;
        position:relative;
        bottom:0px;
    }
`;

export const ClassAddButton = styled.div`
    box-shadow:${shadows.toggleShadow};
    border-radius:6px;
    // margin-right:28px;
    background-color:#fff;
    color:${colors.darkGray1};
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    ${flexCenter};
    gap:2px;
    padding:6px 4px 6px 16px;
`;

export const MobileDataLayersTableCell = styled.div`
    margin-right: 8px;
    ${flexCenter}
    justify-content: end;
`;

export const MobileDataLayerButtonWrapper = styled.div`
    border-radius: 20px;
    margin-top:2px;
    box-shadow: ${shadows.toggleShadow};
  
    ${flexCenter}
    justify-content: space-between;
    padding: 0 12px;
    gap:12px;
    max-width: 100%;
`;

export const MobileDataLayerButtonText = styled.div`
    ${textOverflow}
    font-size: 14px;
    font-weight: 450;
`;

