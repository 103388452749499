import styled from '@emotion/styled/macro';
import { flexCenter, ScrollPanelWithShadows } from '../../../common/styles/combos.styles';
import { colors, shadows, views } from '../../../common/styles/theme.styles';

export const ModalBackDrop = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    display: flex;
    background-color: rgba(0, 0, 0, 0.6);
    align-items: center;
    justify-content: center;
    z-index: 120;
`;

export const ModalWrapper = styled.div`
    width: 90vw;
    max-width: 800px;
    background-color: ${props => props.isWhiteBackgroundColor ? '#fff' : colors.lightGray1};
    ${props=>props.hasMinHeight ? 'min-height: 65vh' : ``};
    max-height: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    border-radius:4px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05), 0px 0px 30px rgba(0, 0, 0, 0.02);
    display:flex;
    flex-direction:column;
    position:relative;
    //flex:1;
    margin-left:260px;
    ${views.desktopWithMinimizedMenu}{
        margin-left: 80px;
    }
    // ${props=>props.isCorrectingUpward ? `
    //     transform: translateY(-32px);
    //     margin-top:-32px;
    // ` : ``};
`;

export const CreateHeaderWrapper = styled.div`
    display:flex;
    justify-content: space-between;
    align-items:center;
    border-radius:4px 4px 0 0;
    border-bottom:1px solid #e3e3e3;
    min-height:64px;
    ${views.tablet}{
        position: fixed;
        top:0;
        height:64px;
        width:100%;
        background: #fff;
        z-index: 1;
    }
`;

export const CloseButtonWrapper = styled.div`
    margin:8px 8px 8px 0;
    cursor: pointer;
    padding: 0px 16px;
    display: flex;
    align-items: center;
`;

export const AbsCloseButton = styled.div`
    cursor: pointer;
    position:absolute;
    top: 16px;
    right: 16px;
    height: 24px;
    width: 24px;
`;


export const CreateHeaderContentWrapper = styled.div`
    padding:16px 0 16px 16px;
    min-height:64px;
    flex:1;
    align-items:center;
    display:flex;
`;

export const CreateContentWrapper = styled(ScrollPanelWithShadows)`
    display:flex;
    flex-direction:column;
    flex:1;
    // padding:0px 16px;
    overflow-y: auto;
    border-radius: 0 0 4px 4px;
    ${props=>props.isWhiteBackgroundColor ? `background: #fff` : ``};
    ${views.tablet}{
        padding-top:64px;
        flex:1;
    }
`;

export const CreateItemBackButtonWrapper = styled.div`
    padding:8px 8px 8px 0;
    cursor: pointer;
    ${flexCenter};
    ${props=>props.hide ? `visibility:hidden` : ``}
`;
export const CreateItemHeaderTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left:8px;
    font-size:16px;
    line-height:16px;
    font-weight:500;
    color:${colors.darkGray1};
`;

export const CreateItemAccountsHeaderTitle = styled.div`
    display: flex;
    flex-direction: column;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    gap: 4px;
    padding-left:8px;
`;

export const CreateItemVipSyncHeaderTitle = styled.div`
    display: flex;
    flex-direction: column;
    padding-left:8px;
`;

export const CreateItemAccountsTitle = styled.div`
    font-weight: 500;
    font-size: 17px;
    line-height: 22px;
    color: ${colors.darkGray1};
`;

export const CreateItemAccountsSubTitle = styled.div`
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.darkGray2};
`;

export const ItemTypeSwitcherWrapper = styled.div`
    // width:100%;
    padding:4px;
    background-color:${colors.gray3};
    height:44px;
    border-radius:5px;
    display:flex;
    //margin-left: 24px;
    // margin-bottom: 16px;
    ${flexCenter};
    position:relative;
`;


export const ClassesPanel = styled.div`
    width: 256px;
    display:flex;
    flex-direction:column;
    /* padding-left:24px;
    padding-top:24px; */
    padding:24px 0px 24px 24px;
    ${views.tablet}{
        width: 100%;
        padding:12px 0;
        padding-bottom: calc( env(safe-area-inset-bottom) + 24px )
    }
`;

export const ClassesSeparator = styled.div`
    background-color:#DDE3EB;
    height:1px;
`;

export const ClassButton = styled.div`
    border-radius:4px;
    //${flexCenter};
    display:flex;
    align-items:center;
    padding:8px 16px;
    margin-bottom:4px;
    cursor: pointer;
    :hover{
        background-color:#fff;
        box-shadow:${shadows.cardShadow}
    }
    &.selected{
        background-color:#fff;
        box-shadow:${shadows.cardShadow}
    }
`;

export const ClassIconWrapper = styled.div`
    width: ${props=>props.size}px;
    min-width: ${props=>props.size}px;
    height: ${props=>props.size}px;   
    border-radius: 2px;
    background-color:${props=>props.color};
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
        width:${props=>props.iconSize}px;
        height:${props=>props.iconSize}px;
    }
    path{
        /* stroke:#4F699D; */
        fill:#fff;
    }
`;

export const ClassTitle = styled.div`
    padding-left:12px;
    font-weight:500;
    font-size:16px;
    color:${colors.darkGray1};
`;

export const CategoryPanel = styled.div`
    ${flexCenter};
    flex:1;
    /* padding-bottom: 76px; */
`;

export const CategoryButton = styled.div`
    // border : 1px solid #222222;
    color: ${props=> props.black ? '#fff' : '#000'};
    background-color: ${props=> props.black ? '#222222' : '#fff'};
    font-size: 18px;
    padding: 8px 16px;
    text-align:center;
    cursor: pointer;
    &:not(:last-child){
        margin: 0px 0px 8px 0px;
    }
    box-shadow:${shadows.toggleShadow};
    // min-width: 144px;
    transition:all 0.2s;
    border-radius:4px;
    &:hover{
        background-color: ${props=> props.black ? '#000' : '#f1f4f8'};
        // border : 1px solid ${props=> props.black ? '#000' : '#222222'};
    }
    display: ${props=> props.inline ? 'inline-block' : 'block'};
`;

export const ClassCategoryStepWrapper = styled.div`
    display:flex;
    flex-direction:row;
    flex:1;
    padding:0px 16px;
`;

export const ClassCategoryGroupTitle = styled.div`
    display: flex;
    padding: 8px 0px;
    gap: 8px;
    color: ${colors.darkGray1};
    font-family: Circular;
    font-size: 14px;
    font-weight: 450;
`;

export const SelectClassMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

export const SelectClassMessage = styled.div`
    font-size:16px;
    line-height:19px;
    color:${colors.gray2};
    text-align:center;
`;

export const HoverClassMessage = styled.div`
    display:flex;
    flex-direction:column;
    text-align:center;
    align-items:center;
    padding:0 40px;
    width:100%;
    ${views.tablet}{
        padding-top: 24px;
        padding:24px 0px 0px;
    }
`;

export const SelectedClassTitle = styled.div`
    color:${colors.darkGray1};
    font-size:22px;
    margin-top:8px;
`;

export const SelectedClassDescription = styled.div`
    font-size:14px;
    color:${colors.darkGray2};
`;


