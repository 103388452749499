import { DatePicker, Dropdown , Drawer } from 'antd';
import { Observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ApproveIcon, ArrowDownIcon, CalendarIcon, ClockIcon, InstitutionPlaceholderIcon, ModalCloseIcon, TransactionSuggestionIcon, UndoIcon, UnlinkIcon } from '../../../../../../assets/icons/common/common-icons';
import { LoadingOutlined } from '@ant-design/icons';
import { BaseModal } from '../../../../../../common/components/BaseModal/BaseModal';
import { CommonBadge } from '../../../../../../common/components/CommonBadge/CommonBadge';
import { DropDownButtonText, DropDownButtonWrapper, SmallActionButton } from '../../../../../../common/components/buttons/Buttons.styles';
import { FieldLabel } from '../../../../../../common/components/form-displayer/FormDisplayer.styles';
import { CURRENCY_SYMBOL } from '../../../../../../common/constants/currency-symbol.const';
import { addMonths, disabledMoreThanToday, enabledBetween } from '../../../../../../common/utils/dates.utils';
import { displayMoneyValue } from '../../../../../../common/utils/number-display.utils';
import { isNullOrUndefinedOrEmptyString } from '../../../../../../common/utils/object.utils';
import { useStore } from '../../../../../app/data/root.store';
import { AssetClassIcon } from '../../../../../assets/components/AssetClassIcon';
import { FormWithStore } from '../../../../../form-displayer-connector/components/FormWithStore';
import { useFormHook } from '../../../../../form-displayer-connector/data/useFormHook';
import { ItemIconAndTitleWrapper, ItemIconWrapper, ItemLogoWrapper, ItemTitleOverflowText, ItemTitleWrapper, VerticalSeparator } from '../AssetPageHeader.styles';
import { ActualEventRowBadgeWrapper, CanceledEventRowBadgeWrapper, OverdueEventRowBadgeWrapper, TargetEventRowBadgeWrapper } from '../Events/AssetEventRow.styles';
import { CancelButton, CreateEventModalButton, CreateEventModalClose, CreateEventModalContent, CreateEventModalHeader, CreateEventModalTitle, DeleteEventModalButton, DeleteIconWrapper, EditTargetWrapper, EventTransactionDataColumnWrapper, EventTransactionIconColumnWrapper, EventTransactionInstitutionLogoWrapper, EventTransactionInstitutionPlaceholderIconWrapper, EventTransactionWrapper, EventTypeDropdownWrapper, EventTypeFieldMessage, EventTypeFieldWrapper, EventTypeStyle, EventValuesAtDateWrapper, ExtraFooterTextWrapper, FormStepFooter, LinkTransactionFieldWrapper, LinkTransactionTypeStyle, LinkedEventTransactionActionsWrapper, LinkedEventTransactionPriceAndDate, LinkedEventTransactionTitleAndAction, LinkedTransactionDate, LinkedTransactionDescription, LinkedTransactionPrice, OptionalText, StepWrapper, SuggestedBadge, ActionsBottomMessage } from './CreateEvent.styles';
import { customValueAtDateText, customValueAtDateType, findAttributeValueAtDate, findValueAtDate } from './EditEvent.utils';
import { LinkEventTransactionMenu } from './LinkEventTransactionMenu';
import { HoldingsBadgeText, HoldingsBadgeTriangle, HoldingsBadgeWrapper } from './LinkEventTransactionMenu.styles';
import { ASSET_CATEGORIES_CREATE_EVENT, ASSET_CATEGORIES_DISPLAY_ATTRIBUTES_VALUE_AT_DATE, DEFAULT_EVENTS_TYPES } from './constants/createEvent.events.const';
import { fetchCryptoCoinValueAtDate, fetchPpsAtDate } from '../../../../data/wealth.service';
import { navigateToUrl } from '../../../../../../common/utils/navigation.utils';
import { useHistory } from "react-router-dom";
import { getUrlDisplayName } from '../../../../../../common/utils/string.utils';
import { useAnalytics } from '../../../../../app/data/AnalyticsProvider';
import { ActionLockedIconWithTooltip } from '../../../../../../common/components/ActionLocked/ActionLockedIconWithTooltip';


const disableFieldsOnTransactionLinkByCategoryId = [37,38]
const keepTransactionValueDisabledAfterUnlink = [41]

export const EditEvent = (props) => {
    const { uiStore } = useStore();



    // event.eventType === 'Item created' ? (currentDate)=>{disabledMoreThanToday(currentDate)} : (currentDate)=>{enabledBetween(currentDate,itemStore?.item??.sstartDate,new Date())}; 
    // useEffect(() => {
    //     transactionsStore.getData(); 
    //     transactionsStore.checkUpdates();
    //     return () => {

    //     }
    // }, []);
    return (
        <Observer>{() => (
            uiStore.isDesktopView ? 
            <BaseModal width={'512px'} height={'auto'} borderRadius={8}>
                <EditEventContent {...props} />
            </BaseModal> 
            : 
            <Drawer
                closable={false}
                visible={true}
                width={  340  }
                height={'calc(100% - 32px)'}
                className={'editEventDrawerContainer_mobile'}
                placement={'bottom'}
                destroyOnClose={true}
                onClose={()=>{ props.onClose()}} >
                    <EditEventContent {...props} /> 
            </Drawer>
        )}</Observer>
    //     <BaseModal width={'600px'} height={'auto'} borderRadius={8}>
    //        <EditEventContent {...props} />
    //    </BaseModal> 
    )
}

export const EditEventContent = (props) => {
    const { event, itemId, itemCategoryId, itemCurrency, itemHoldings, onClose, eventIndex, isFormDisabled } = props;
    const formName = 'editEvent';
    const today = new Date();
    const pointData = addMonths(today, event.month);
    const [eventType, setEventType] = useState(-1);
    const [eventDate, setEventDate] = useState(moment.utc(event.date || pointData));
    const [valueAtDate, setValueAtDate] = useState();
    const { metadataStore, itemStore, eventsStore, uiStore, userStore, createItemStore, billingStore, wealthStore } = useStore();
    const [sectionsState, setSectionsState] = useState([])
    const [eventFormData, setEventFormData] = useState({});
    const [suggestionId, setSuggestionId] = useState(0)
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [actions, setActions] = useState({ delete: true, cancel: true, save: true });
    const [actionsBottomMessage, setActionsBottomMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(0);
    const [shouldFetchPpsAtDate, setShouldFetchPpsAtDate] = useState(false);
    // const [isTransactionsDropdownOpen,setIsTransactionsDropdownOpen] = useState(false);
    // const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false)
    const history = useHistory();
    const editEventForm = useFormHook(formName);
    const { trackEvent } = useAnalytics()
    const isValueUpdate = event.eventType === 'Value update';

    const isNotApprovedOrCanceledPredictedEvent = !event.id && event.eventId === null;
    const isLoanAndNotCurrentMonth = itemCategoryId === 37 && !moment(event.date).isSame(moment(), 'month');
    const unapprovingEventShouldCancelIt = itemCategoryId === 37 && !event.isCanceled;

    let eventName = props.oldEventType ? props.oldEventType : (event.description ? event.description : event.title ? event.title : event.eventType)

    
    // useEffect(() => {
    //     transactionsStore.getData();
    //     transactionsStore.checkUpdates();
    //     return () => {}
    // }, [transactionsStore]);
    const handleSelectTransaction = (trn) => {
        setSelectedTransaction(trn);
        const trnValue = Math.abs(trn.value * (((trn.holdings || 100) / 100)) / (itemHoldings / 100));
        editEventForm.updateValue('transactionValue', trnValue, true);
        editEventForm.updateField('transactionValue', { disabled: true, prefix: CURRENCY_SYMBOL[trn.currency], placeholder: CURRENCY_SYMBOL[trn.currency] });
        editEventForm.updateValue('isTransactionLinked', true);
        // TODO : Add private behavior to the events const 
        if (disableFieldsOnTransactionLinkByCategoryId.includes(itemCategoryId)) {
            const newPrincipal = trnValue - editEventForm.values.interestPayment;
            editEventForm.updateField('principalPayment', { disabled: true });
            editEventForm.updateValue('principalPayment', newPrincipal)
            editEventForm.updateField('updatedValue', { disabled: true });
            if (event.eventType !== 'Item created'){
                editEventForm.updateValue('updatedValue', editEventForm.values.valueAtDate - newPrincipal);
            } else {
                editEventForm.updateValue('updatedValue', trnValue);
            }
        }
        // setEventDate(moment(trn.date));
    }

    const handleUnlinkTransaction = () => {
        if (isFormDisabled){return}
        setSelectedTransaction(null);
        if (event.amount) {
            editEventForm.updateValue('transactionValue', Math.abs(event.amount), true);
        }
        editEventForm.updateField('transactionValue', { disabled: keepTransactionValueDisabledAfterUnlink.includes(itemCategoryId) || ( itemCategoryId === 37 && moment(event.date).isSame(moment(), 'month')), prefix: CURRENCY_SYMBOL[itemCurrency], placeholder: CURRENCY_SYMBOL[itemCurrency] });
        editEventForm.updateValue('isTransactionLinked', false);
        if (isLoanAndNotCurrentMonth) {
            editEventForm.updateField('principalPayment', { disabled: false });
            if (event.eventType !== 'Item created'){
                editEventForm.updateField('updatedValue', { disabled: false });
            }
        }
        // editEventForm.updateField('transactionValue',{disabled : false});

        // setEventDate(moment(event.date || pointData));
        // setIsTransactionsDropdownOpen(true);
    }

    const handleCloseItem = (e) => {
        if (userStore.isDemoOrReadOnlyMode) {
          billingStore.setShowPricingTableModal(true);
        } else {
          if (itemStore.item?.closedAt && userStore?.isCreateItemManuallyLimited){
            return createItemStore.setShowAddManualItemLimitModal(true);
          }
          if(!itemStore.item?.closedAt) {
            itemStore.setShowCloseItemModal(true);
          }
          // props.showCloseAssetConfirm?.()
        }
      }

    const handleEditEvent = async () => {
        if (isFormDisabled){return false}
        const { values } = editEventForm;
        const relevantDate = eventDate || new Date();

        const sectionValidations = editEventForm.currentSectionErrors;
        if (!sectionValidations.isValid) {
            editEventForm.setErrors(sectionValidations.errors);
            editEventForm.setBottomErrors(sectionValidations.bottomErrors);
            return false;
        }
        
        const utcMiddleOfMonthDate = moment(relevantDate).utc().set('D',15).startOf('D');
        setIsLoading(1);
        await props.onSave?.({
            eventType: eventType > -1 ? createEventTypes[eventType] : { isCustom: true },
            formValues: values,
            eventDate: utcMiddleOfMonthDate,
            selectedTransaction,
            valueAtDate
        });
        eventsStore.setShouldReloadTransactions(true);
        onClose?.();
        if (isNotApprovedOrCanceledPredictedEvent && event.eventType === 'Exit event') {
            handleCloseItem();
        }
    }

    const handleDeleteEvent = async () => {
        if (isFormDisabled || isLoading !== 0){return false}
        if (isNotApprovedOrCanceledPredictedEvent ||  ( itemStore?.item?.isConnected && unapprovingEventShouldCancelIt ) ) {
            const { values } = editEventForm;
            props.onCancel?.({
                eventType: createEventTypes[eventType],
                formValues: values
            });
        } else if (props.onDelete) {
            props.onDelete();
        } else {
            setIsLoading(2);
            await eventsStore.deleteEvent(event.isPredicted ? event.eventId : event.id, itemStore, eventIndex);
            wealthStore.setGetLastChangesDataAfterExitPage();
        }
        eventsStore.setShouldReloadTransactions(true);
        onClose?.();
    }

    const createEventTypes = ASSET_CATEGORIES_CREATE_EVENT.hasOwnProperty(itemCategoryId) ? ASSET_CATEGORIES_CREATE_EVENT[itemCategoryId] : DEFAULT_EVENTS_TYPES;

    useEffect(() => {
        let eventTitle = props.oldEventType ? props.oldEventType : (event.description ? event.description : event.title ? event.title : event.eventType)
        const eventTypeIndex = findEventIndex(createEventTypes, eventTitle, event?.eventType);  // createEventTypes.findIndex(val => val.eventType === event?.eventType || val.title === event?.eventType);
        setEventType(eventTypeIndex);
        setSectionsState(() => props.formSections ? props.formSections : (createEventTypes[eventTypeIndex] ? createEventTypes[eventTypeIndex].sections : []));
        const isLiability = createEventTypes[eventTypeIndex] ? createEventTypes[eventTypeIndex].isLiability : false;

        const formAttributes = {}

        if (createEventTypes[eventTypeIndex]?.attributesToSend) {
            // console.log(createEventTypes[eventTypeIndex].isDistributionReversed);
            createEventTypes[eventTypeIndex].attributesToSend.forEach(element => {
                // console.log(element.dataKey);
                if (event.eventData) {
                    const relevantAtr = event.eventData.attributes?.find(atr => atr.data.hasOwnProperty(element.dataKey));
                    if (relevantAtr) {
                        // formAttributes[element.fieldKey] = relevantAtr.data[element.dataKey]
                        formAttributes[element.fieldKey] = element.getEditValueFn ? element.getEditValueFn({ value: relevantAtr.data[element.dataKey] }) : relevantAtr.data[element.dataKey]
                    }
                } else if (event.predictedAttributes && event.predictedAttributes.hasOwnProperty(element.dataKey)) {
                    formAttributes[element.fieldKey] = element.getEditValueFn ? element.getEditValueFn({ value: event.predictedAttributes[element.dataKey] }) : event.predictedAttributes[element.dataKey]
                }
            });
        }
        if (createEventTypes[eventTypeIndex]?.valuesForEditFromItem) {
            // console.log(createEventTypes[eventTypeIndex].isDistributionReversed);
            createEventTypes[eventTypeIndex].valuesForEditFromItem.forEach(element => {
                // console.log(element.dataKey);
                formAttributes[element.dataKey] = element.getEditValueFn ? element.getEditValueFn({value: itemStore?.item[element.itemDataKey] }) :  itemStore?.item[element.itemDataKey];
            });
        }

        const updatedValue = event.eventData?.valueUpdates.length ? event.eventData.valueUpdates[0].value : (event.predictedValue ??  null);

        // let valueAtDate = findValueAtDate(itemStore?.item?.valueUpdates, eventDate, (event.id || event.eventId));
        // console.log(event);
        const formData = props.formData ? { ...props.formData, ...formAttributes } : {
            //valueAtDate: valueAtDate ? valueAtDate.value : updatedValue,
            eventDescription: event.description,
            eventDate: event.date,
            eventPredictedAmount: event?.isPredicted ?  ( event?.predictedAmount || event?.amount ) : null,
            predictedAttributes : event?.predictedAttributes ? event?.predictedAttributes : null,
            eventTitle: event.id ? event.title : event.description,
            ...(!isNullOrUndefinedOrEmptyString(updatedValue) ? { updatedValue } : {}),
            eventId: (event.eventId || event.id),
            transactionValue: (event.id || event.eventId) ? (event.eventData.transactions.length ? Math.abs(event.eventData.transactions[0].value) : 0) : Math.abs(event.amount),
            // originalEventTransactionValue: (event.id || event.eventId) ? (event.eventData.transactions.length ? Math.abs(event.eventData.transactions[0].value) : 0) : 0,
            currentItemValue: itemStore?.item?.value,
            allTransactions: (event.id || event.eventId) ? (event.eventData.transactions.length > 1 ? event.eventData.transactions : []) : [],
            ...(event.id && event.eventData.transactions.length ? { trnCurrency: event.eventData.transactions[0].currency } : {}),
            eventRemarks: event.remarks ? event.remarks : '',
            ...formAttributes,
            categoryId: itemCategoryId,
            ...(itemHoldings ? { holdings: itemHoldings } : {}),
            ...(itemCurrency ? { currency: itemCurrency } : {}),
            isEditEvent: true,
            isTransactionLinked: event.trnDetails ? true : (event.eventData?.transactions?.length ? event.eventData.transactions[0].provider === 'api' : false),
            ...(event.isReinvested || event.eventData?.transactions?.length > 1  ? {isReinvestedEvent : true} : {})
            // ...(distributionReinvested || event.isReinvested ? {distributionReinvested: true} : {} )
        }

        if (formData.updatedValue && formData.transactionValue && event.eventData && event.eventData.transactions?.length) {
            formData.value = parseFloat(formData.updatedValue) + (parseFloat(event.eventData.transactions[0].value) * (isLiability ? -1 : 1));
        }
        // console.log(event,moment(event.date))
        setEventFormData(formData);

        eventsStore.setCurrentEventDetails(
            event.month,
            event.date,
            createEventTypes[eventTypeIndex] ? createEventTypes[eventTypeIndex].isDistribution : false,
            formData.transactionValue,
            formData.trnCurrency ? formData.trnCurrency : itemCurrency,
            createEventTypes[eventTypeIndex] ? !createEventTypes[eventTypeIndex].hideLinkedTrn : true,
        );

        const lastApprovedOrCanceledEvents = itemStore?.itemEvents?.slice().find(ev => ev.hasHappened || ev.isCanceled);
        const lastApprovedAndNotCanceled = itemStore?.itemEvents?.slice().find(ev => ev.hasHappened && !ev.isCanceled);
        const lastOverdue = itemStore?.itemEvents?.slice().find(ev => ev.isPredicted && !ev.hasHappened && !ev.isCanceled && ev.month < 0);
        const firstOfEventType = itemStore?.itemEvents?.slice().reverse().find(ev => ev.eventType === event.eventType);
        const dataForEventTypeDefinitions = {
            eventDate,
            isItemConnected: itemStore?.item?.isConnected,
            hasHappened: event.hasHappened,
            isCanceled: event.isCanceled,
            isOneAfterLastApprovedOrCanceled: moment(lastApprovedOrCanceledEvents?.date).add(1,'M').isSame(eventDate,"M"), 
            isLastPredictedApprovedEvent: lastApprovedAndNotCanceled?.eventId === event.eventId,
            isLastPredictedApprovedOrCanceledEvent: lastApprovedOrCanceledEvents?.eventId === event.eventId,
            isMostRecentOverdue: lastOverdue?.month === event.month,
            isPastEvent: event.month < 0,
            isFirstOfEventType: firstOfEventType?.eventId ? (firstOfEventType.eventId === event.eventId) : (firstOfEventType?.id === event.id)
        }

        const actionsToCheck = ['delete', 'cancel', 'save'];
        const actionsResults = { delete: true, cancel: true, save: true };
        actionsToCheck.forEach(actionName => {
            const eventActionKey = actionName + 'Enable';
            if (createEventTypes[eventTypeIndex]?.hasOwnProperty(eventActionKey)) {
                actionsResults[actionName] =
                    (typeof createEventTypes[eventTypeIndex][eventActionKey] === 'function') ?
                        createEventTypes[eventTypeIndex][eventActionKey](dataForEventTypeDefinitions) :
                        createEventTypes[eventTypeIndex][eventActionKey];
            }
        });

        const bottomMessage = createEventTypes[eventTypeIndex]?.bottomMessage ? createEventTypes[eventTypeIndex].bottomMessage(dataForEventTypeDefinitions) : null;
        setActions(actionsResults);
        setActionsBottomMessage(bottomMessage);


        // eventsStore.setCurrentEventTransactionValueAndCurrency(formData.transactionValue,formData.trnCurrency ?  formData.trnCurrency : itemCurrency)
        return () => {
            // cleanup
        }
    }, [createEventTypes, event?.eventType, eventsStore, event, eventDate, itemCurrency, itemHoldings, props.formData, props.oldEventType, props.formSections, itemStore, itemCategoryId])

    useEffect(() => {
        let valueAtDate = findValueAtDate(itemStore?.itemEvents || [] , eventDate, (event.id || event.eventId));
        setValueAtDate(valueAtDate && event.eventType !== 'Item created' ? valueAtDate.value : '');

        const displayedAttributesAtDate = ASSET_CATEGORIES_DISPLAY_ATTRIBUTES_VALUE_AT_DATE[itemCategoryId] || [];
        let attributesAtDate = {}
        // console.log(itemStore?.item?.attributesHistory);
        for (let index = 0; index < displayedAttributesAtDate.length; index++) {
            const atrDisplayObj = displayedAttributesAtDate[index];
            // console.log(itemStore?.item?.attributesHistory ,atrDisplayObj.dataKey , itemStore?.item?.attributesHistory[atrDisplayObj.dataKey] );
            const atrAtDate = findAttributeValueAtDate(itemStore?.itemEvents || [], atrDisplayObj.dataKey, eventDate , (event.id || event.eventId))
            attributesAtDate[atrDisplayObj.dataKey+'AtDate'] = atrAtDate ? atrAtDate.data[atrDisplayObj.dataKey] : 0;
        }
        //console.log(attributesAtDate);

        valueAtDate && setTimeout(() => {
            editEventForm && editEventForm.updateValue('valueAtDate', event.eventType === 'Item created' ? 0 : valueAtDate.value);
            for (const propKey in attributesAtDate){
                editEventForm && editEventForm.updateValue(propKey, event.eventType === 'Item created' ? 0 : attributesAtDate[propKey]);
            }
        }, 0)
        return () => {
            //   cleanup
        }
    }, [itemStore, eventDate, event?.eventType, event?.id, event?.eventId, editEventForm, itemStore?.itemEvents,itemCategoryId])

    useEffect(() => {
        const fetchStockPriceAtDate = async () => {
            try {
                const price = await fetchPpsAtDate(itemStore?.item?.stock?.symbol, itemStore?.item?.stock?.exchange, itemStore?.item.stock?.currency, moment(eventDate).endOf('M').format('YYYY-MM-DD'), itemStore?.item?.currency);
                if (price) {
                    setTimeout(() => {
                        if (editEventForm){
                            editEventForm.updateValue('pricePerShare', parseFloat(price).toFixed(2));
                            if (editEventForm.values.newNumberOfShares && !editEventForm.values.doNotUpdateTransactionValue){
                                editEventForm.updateValue('transactionValue', parseFloat(editEventForm.values.newNumberOfShares * price).toFixed(2));
                            }
                        }  
                    }, 0);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setShouldFetchPpsAtDate(false)
            }
        };
        const fetchCryptoCoinPriceAtDate = async () => {
            try {
                const price = await fetchCryptoCoinValueAtDate(itemStore?.item?.cryptoCoin?.symbol, moment(eventDate).endOf('M').format('YYYY-MM-DD'),itemStore?.item?.currency);
                if (price) {
                    setTimeout(() => {
                        if (editEventForm){
                            const itemCurrencyRate = price;
                            editEventForm.updateValue('newPricePerCoin', parseFloat(itemCurrencyRate).toFixed(2));
                            if (editEventForm.values.newQuantity && !editEventForm.values.doNotUpdateTransactionValue){
                                editEventForm.updateValue('transactionValue', parseFloat(editEventForm.values.newQuantity * itemCurrencyRate).toFixed(2));
                            }
                        }  
                    }, 0);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setShouldFetchPpsAtDate(false)
            }
        };
        if (itemStore?.item?.categoryId === 39 && itemStore?.item?.stock?.symbol && itemStore?.item?.currency && shouldFetchPpsAtDate) {
            fetchStockPriceAtDate();
        }
        if (itemStore?.item?.categoryId === 36 && itemStore?.item?.cryptoCoin?.symbol && itemStore?.item?.currency && shouldFetchPpsAtDate) {
            fetchCryptoCoinPriceAtDate();
        }
    }, [itemStore?.item?.categoryId, itemStore?.item?.stock?.symbol, itemStore?.item?.stock?.exchange, itemStore?.item.stock?.currency, itemStore?.item?.cryptoCoin?.symbol, itemStore?.item?.currency, eventDate, editEventForm, shouldFetchPpsAtDate]);  

    // Linked transaction check
    // if (event.eventData?.transactions?.length && event.eventData.transactions[0].connectedToId){}
    useEffect(() => {
        /// Hack for update the form after load,
        // TODO - think about something better
        if (editEventForm !== undefined) {
            if (event.trnDetails ||
                (event.eventData?.transactions?.length && event.eventData.transactions[0].connectedToId === itemId)
            ) {
                const relevantTrn = event.trnDetails ? event.trnDetails : event.eventData.transactions[0];
                if (metadataStore.itemsMeta[relevantTrn.associateId]) {
                    relevantTrn.holdings = metadataStore.itemsMeta[relevantTrn.associateId].holdings;
                }
                setSelectedTransaction(relevantTrn);
                // editEventForm.updateValue('transactionValue',Math.abs(relevantTrn.value * (((relevantTrn.holdings || 100) / 100)) / (itemHoldings / 100) ),true);
                const trnValue = Math.abs((relevantTrn.originalValue || relevantTrn.value) * (((relevantTrn.holdings || 100) / 100)) / (itemHoldings / 100));
                editEventForm.updateValue('transactionValue', trnValue, true);
                editEventForm.updateField('transactionValue', { disabled: true });
                if (isNotApprovedOrCanceledPredictedEvent) {
                    setSuggestionId(relevantTrn.id)
                }
            }
            if (event.eventData?.valueUpdates.length) {
                editEventForm.updateField('updatedValue', { showPrevComp: false });
            }
        }
        return () => {
            //   cleanup
        }
    }, [editEventForm, event, itemId, isNotApprovedOrCanceledPredictedEvent, itemHoldings, metadataStore])

    const handleEventDateChange = (momentDate) => {
        setEventDate(momentDate);
        setShouldFetchPpsAtDate(true);
    }

    const getEnableDateFn = () => {
        if (event.eventType === 'Item created') {
            return disabledMoreThanToday;
        } else if (event.eventType === 'Exit event') {
            return (currentDate) => { return enabledBetween(currentDate, itemStore?.item?.startDate, itemStore.latestItemEventDate) }
        } else {
            return (currentDate) => { return enabledBetween(currentDate, itemStore?.item?.startDate, new Date()) }
        };
    }

    const getValueAtDateTextAndValue = () => {
        const valueAtDateText = customValueAtDateText[itemStore.item.category.id] || 'Value at date:';
        const valueAtDateType = customValueAtDateType[itemStore.item.category.id] || 'money';
        const displayedAttributesAtDate = ASSET_CATEGORIES_DISPLAY_ATTRIBUTES_VALUE_AT_DATE[itemCategoryId] || [];
        return (
            <EventValuesAtDateWrapper>
            <EventTypeFieldMessage>
                {valueAtDateText}&nbsp;
                { valueAtDateType === 'money' && displayMoneyValue(valueAtDate, itemCurrency, uiStore.isIncognitoMode, (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100) }
                { valueAtDateType === 'number' && ( valueAtDate || 0 ) }
            </EventTypeFieldMessage>
            {displayedAttributesAtDate.map((da)=>(
                <EventTypeFieldMessage key={da.dataKey}>
                    {da.title}&nbsp;
                    { da.type === 'money' && displayMoneyValue(editEventForm?.values[da.dataKey+'AtDate'] || 0, itemCurrency, uiStore.isIncognitoMode, (itemStore.isHoldingsView && itemHoldings) ? itemHoldings : 100) }
                    { da.type  === 'number' && ( editEventForm?.values[da.dataKey+'AtDate'] || 0 ) }
                </EventTypeFieldMessage>
            ))}
            
            </EventValuesAtDateWrapper>
        )
    }
    const handleEditTargetOnClick = (e) => {
        trackEvent('CTA Clicked', {
            cta_text: e.target.textContent,
            destination: 'Asset info',
            location: 'Edit event',
            type: 'Button',
        })
        e.stopPropagation();
        const { assetInfoLabelId } = createEventTypes[eventType];
        navigateToUrl(history, `/wealth/assets/${getUrlDisplayName(itemStore.item.categoryClass.title)}/${itemStore.item.id}/info?field=${assetInfoLabelId || ''}`);
    }

    const startDate = moment(itemStore?.item?.startDate);
    const [isShowingDatePickerExtraFooter, setIsShowingDatePickerExtraFooter] = useState(moment(startDate).year() === moment().year() && moment(startDate).month() !== 0);

    const ExtraFooter = () => {
        return (<ExtraFooterTextWrapper>
          Events cannot be created before the 'Item created' event ({moment(itemStore?.item?.startDate).format('MMMM YYYY')})
        </ExtraFooterTextWrapper>)
    }
    // event.eventType === 'Item created' ? (currentDate)=>{disabledMoreThanToday(currentDate)} : (currentDate)=>{enabledBetween(currentDate,itemStore?.item??.sstartDate,new Date())}; 
    return (
        <Observer>{() => (
            <>
                <CreateEventModalHeader>
                    <CreateEventModalTitle>
                        Edit event
                    </CreateEventModalTitle>

                    <div style={{ paddingLeft: '6px' }}>
                        {event.id ? <ActualEventRowBadgeWrapper style={{ margin: 0 }}><ApproveIcon /> Actual</ActualEventRowBadgeWrapper> : event.isCanceled ? <CanceledEventRowBadgeWrapper style={{ margin: 0 }}><ClockIcon />Canceled</CanceledEventRowBadgeWrapper> :
                            !event.eventId && event.month < 0 ? <OverdueEventRowBadgeWrapper style={{ margin: 0 }}><CalendarIcon />Overdue</OverdueEventRowBadgeWrapper> :
                                event.id || event.hasHappened ? <ActualEventRowBadgeWrapper style={{ margin: 0 }}><ApproveIcon />Actual</ActualEventRowBadgeWrapper> :
                                    <TargetEventRowBadgeWrapper style={{ margin: 0 }}><ClockIcon />Target</TargetEventRowBadgeWrapper>}
                    </div>

                    <VerticalSeparator />

                    <ItemIconAndTitleWrapper>
                        {itemStore?.item?.logoBase64 ?
                            <ItemLogoWrapper iconWidth={'28px'} iconHeight={'28px'}><img alt={itemStore.item.connectedInstitutionName} src={'data:image/png;base64,' + itemStore?.item?.logoBase64} /></ItemLogoWrapper>
                            :
                            itemStore?.item?.logoUrl ?
                                <ItemLogoWrapper iconWidth={'28px'} iconHeight={'28px'}><img alt={itemStore.item.connectedInstitutionName} src={itemStore?.item?.logoUrl} /></ItemLogoWrapper>
                                :
                                <ItemIconWrapper iconWidth={'28px'} iconHeight={'28px'} color={itemStore.item.categoryClass.color}>
                                    <AssetClassIcon name={itemStore.item.categoryClass.icon} />
                                </ItemIconWrapper>}
                        <ItemTitleWrapper style={{width:'calc(100% - 64px)'}}>
                            <ItemTitleOverflowText>
                                {itemStore.item.category.id === 39 ? 
                                <>
                                    {itemStore.item.title}, {itemStore.item.type}{itemStore.item.name ? `, ${itemStore.item.name}` : ''}
                                </> : 
                                <>
                                    {itemStore.item.title !== itemStore.item.name && itemStore.item.name ? `${itemStore.item.name}, ` : ``}
                                    {itemStore.item.title}
                                </>}
                            </ItemTitleOverflowText>

                            {itemStore.item.closedAt && itemHoldings === 100  && <CommonBadge badgeType={'closed'} />}
                            {!isNullOrUndefinedOrEmptyString(itemHoldings) && itemHoldings !== 100 &&
                                <HoldingsBadgeWrapper style={{ marginLeft: '8px' , marginTop:'2px' }}>
                                    <HoldingsBadgeText>
                                        {itemHoldings}% Ownership
                                    </HoldingsBadgeText>
                                    <HoldingsBadgeTriangle />
                                </HoldingsBadgeWrapper>
                            }
                        </ItemTitleWrapper>
                    </ItemIconAndTitleWrapper>

                    <CreateEventModalClose onClick={onClose}>
                        <ModalCloseIcon />
                    </CreateEventModalClose>
                </CreateEventModalHeader>

                <StepWrapper id="edit-event-modal-form-wrapper">
                    <CreateEventModalContent>
                        {/* <EventTypeStyle isHeader={true} style={{ width: '464px' }} >
                            <EventTypeFieldWrapper style={{ display: 'flex', gap: '12px', paddingBottom: '16px' }}>
                                <div style={{ paddingRight: '12px', borderRight: '1px solid #DDE3EB' }}>
                                    <EventRowDateWrapper>
                                        <EventRowShortnameMonth>{eventDate.format('MMM')}</EventRowShortnameMonth>
                                        <EventRowFullYear>{eventDate.format('YYYY')}</EventRowFullYear>
                                    </EventRowDateWrapper>

                                </div>


                            </EventTypeFieldWrapper>
                        </EventTypeStyle> */}


                        <EventTypeDropdownWrapper>
                            <EventTypeStyle >
                                <EventTypeFieldWrapper>
                                    <FieldLabel className={"fd-inputLabel"}>Event</FieldLabel>
                                    <DropDownButtonWrapper isDisabled >
                                        <DropDownButtonText>{eventName}</DropDownButtonText>
                                    </DropDownButtonWrapper>
                                </EventTypeFieldWrapper>
                                <EventTypeFieldWrapper>
                                    <FieldLabel className={"fd-inputLabel"}>Date</FieldLabel>
                                    <DatePicker style={{ width: '100%' }} picker="month"
                                        // disabledDate={disabledMoreThanToday}
                                        inputReadOnly
                                        disabledDate={getEnableDateFn()}
                                        allowClear={false} value={eventDate} format={(val) => moment(val).format("MMMM YYYY")}
                                        disabled={isFormDisabled || (event.isPredicted && event.eventType !== 'Exit event')}
                                        onChange={(val) => { handleEventDateChange(val ? val : moment()) }}
                                        onPanelChange={(val) => {
                                            const valYear = moment(val).year();
                                            setIsShowingDatePickerExtraFooter(
                                              valYear < startDate.year() || (valYear === startDate.year() && startDate.month() !== 0)
                                            );
                                        }}
                                        renderExtraFooter={ uiStore.isDesktopView && event.eventType !== 'Item created' && isShowingDatePickerExtraFooter ? ExtraFooter : null}
                                        dropdownClassName='editEventDatePicker'
                                    // onChange={(val) => { setEventDate(val ? val : moment()) }}
                                    />

                                    {getValueAtDateTextAndValue()}
                                </EventTypeFieldWrapper>
                            </EventTypeStyle>
                        </EventTypeDropdownWrapper>

                        {!isValueUpdate && eventsStore.shouldShowLinkedTransaction && !event.isCanceled && !editEventForm?.values.isLinkedTrnHidden &&
                            <LinkTransactionTypeStyle style={{ margin: '0 auto 24px auto' }} >

                                <LinkTransactionFieldWrapper>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <FieldLabel className={"fd-inputLabel"}>Linked transaction <OptionalText>(optional)</OptionalText></FieldLabel>
                                        {selectedTransaction?.id ? <SmallActionButton disabled={isFormDisabled} onClick={() => { handleUnlinkTransaction() }}>
                                            <UnlinkIcon className={'buttonIcon'} />
                                            Unlink
                                        </SmallActionButton> : null}
                                    </div>

                                    {selectedTransaction?.id ?
                                        <EventTransactionWrapper>
                                            <EventTransactionIconColumnWrapper>
                                                {metadataStore.itemsMeta[selectedTransaction.associateId]?.logoBase64 ? <EventTransactionInstitutionLogoWrapper><img alt={metadataStore.itemsMeta[selectedTransaction.associateId]?.connectedInstitutionName} src={'data:image/png;base64,' + metadataStore.itemsMeta[selectedTransaction.associateId]?.logoBase64} /></EventTransactionInstitutionLogoWrapper> :
                                                    metadataStore.itemsMeta[selectedTransaction.associateId]?.logoUrl ? <EventTransactionInstitutionLogoWrapper><img alt={metadataStore.itemsMeta[selectedTransaction.associateId]?.connectedInstitutionName} src={metadataStore.itemsMeta[selectedTransaction.associateId]?.logoUrl} /></EventTransactionInstitutionLogoWrapper> : <EventTransactionInstitutionPlaceholderIconWrapper><InstitutionPlaceholderIcon /></EventTransactionInstitutionPlaceholderIconWrapper>}
                                            </EventTransactionIconColumnWrapper>
                                            <EventTransactionDataColumnWrapper>
                                                <LinkedEventTransactionTitleAndAction>
                                                    <LinkedEventTransactionPriceAndDate>
                                                        <LinkedTransactionPrice>
                                                            {displayMoneyValue(Math.abs(selectedTransaction.originalValue || selectedTransaction.value), selectedTransaction.currency)}
                                                            {suggestionId === selectedTransaction.id && <SuggestedBadge><TransactionSuggestionIcon />Suggested</SuggestedBadge>}
                                                            {!isNullOrUndefinedOrEmptyString(selectedTransaction.holdings) && selectedTransaction.holdings !== 100 &&
                                                                <HoldingsBadgeWrapper style={{ marginLeft: '8px' }}>
                                                                    <HoldingsBadgeText>
                                                                        {selectedTransaction.holdings}% Ownership
                                                                    </HoldingsBadgeText>
                                                                    <HoldingsBadgeTriangle />
                                                                </HoldingsBadgeWrapper>
                                                            }
                                                        </LinkedTransactionPrice>
                                                        <LinkedTransactionDate>
                                                            {moment(selectedTransaction.date).format("MMMM DD, YYYY")}
                                                        </LinkedTransactionDate>
                                                    </LinkedEventTransactionPriceAndDate>
                                                    <LinkedEventTransactionActionsWrapper>
                                                        {/* <SmallActionButton onClick={()=>{handleUnlinkTransaction()}}>
                                                    <UnlinkIcon className={'buttonIcon'}/>
                                                    Unlink
                                                </SmallActionButton> */}
                                                    </LinkedEventTransactionActionsWrapper>
                                                </LinkedEventTransactionTitleAndAction>
                                                <LinkedTransactionDescription>
                                                    {selectedTransaction.remarks}
                                                </LinkedTransactionDescription>
                                            </EventTransactionDataColumnWrapper>
                                            {/* <EventTransactionWrapperCutout img={bottomAngle} /> */}
                                        </EventTransactionWrapper>
                                        :
                                        <Dropdown
                                            disabled={isFormDisabled || !actions.save}
                                            overlay={
                                                // <div><div onClick={(e)=>{e.stopPropagation()}}>
                                                <LinkEventTransactionMenu handleSelectTransaction={handleSelectTransaction} />
                                                // </div></div>
                                                // LinkEventTransactionMenu({
                                                //     // transactions: ['a', 'b', 'c'],
                                                //     transactionsStore: {transactionsStore},
                                                //     uiStore: {uiStore}
                                                //     // setItem: (index) => handleEventTypeDropdownSelect(index),
                                                //     // isValueDisabled: item.plaidConnectedAccountId && !item.isLoginRequired,
                                                // })
                                            }
                                            trigger={['click']} placement="bottomLeft"
                                        // visible={isTransactionsDropdownOpen}
                                        >
                                            <DropDownButtonWrapper
                                                // onClick={()=>setIsTransactionsDropdownOpen(!isTransactionsDropdownOpen)}
                                                style={ (isFormDisabled || !actions.save) ? {position: 'relative', cursor:'not-allowed',backgroundColor:'#f5f5f5' , color:'rgba(0, 0, 0, 0.25)' } : { backgroundColor: 'white', position: 'relative' }}>
                                                <DropDownButtonText>Select transaction</DropDownButtonText>
                                                <ArrowDownIcon />
                                                {/* <EventTransactionWrapperCutout img={bottomAngle} /> */}
                                            </DropDownButtonWrapper>
                                        </Dropdown>
                                    }

                                </LinkTransactionFieldWrapper>
                            </LinkTransactionTypeStyle>
                        }

                        {!event.isCanceled &&
                            <FormWithStore
                                formName={formName}
                                sections={sectionsState}
                                isDisabled={isFormDisabled || !actions.save}
                                isIncognito={uiStore.isIncognitoMode}
                                defaultValues={eventFormData}
                                scrollContainerId={'edit-event-modal-form-wrapper'}
                            />
                        }

                    </CreateEventModalContent>
                </StepWrapper>
                <FormStepFooter>
                    {!event.isCanceled && actions.save &&
                        <CreateEventModalButton onClick={handleEditEvent} disabled={isFormDisabled}>
                            {isLoading === 1 ? <LoadingOutlined /> : <>
                            {isNotApprovedOrCanceledPredictedEvent ? 'Approve' : 'Save'} event
                            {isFormDisabled && <ActionLockedIconWithTooltip iconColor={'#FFF'} isRoleBlocked={true} />}
                            </>}
                        </CreateEventModalButton>}
                    {actions.delete && <DeleteEventModalButton onClick={handleDeleteEvent} >
                        {event.isCanceled ? <CancelButton><UndoIcon /> Undo event cancellation</CancelButton> :
                            <DeleteIconWrapper disabled={isFormDisabled}>
                                {isLoading === 2 ? <LoadingOutlined /> : <>{!event.id && event.hasHappened ? <UnapproveEventSvg /> : <DeleteEventSvg />}</>}
                                {!event.id && event.hasHappened ? 'Unapprove event' : isNotApprovedOrCanceledPredictedEvent ? 'Cancel event' : 'Delete Event'}
                            </DeleteIconWrapper>
                        }
                        {createEventTypes[eventType]?.assetInfoLabelId && !event.isCanceled && event.isPredicted ?
                            <EditTargetWrapper onClick={handleEditTargetOnClick}>
                                {userStore.isActionBlockedByRole('editTargetEvent') ? 'View target' : <><PencilEditSvg /> Edit target</>}
                            </EditTargetWrapper> : null}
                    </DeleteEventModalButton>}
                    {actionsBottomMessage && <ActionsBottomMessage>{actionsBottomMessage}</ActionsBottomMessage>}
                </FormStepFooter>
            </>
        )}
        </Observer>)

}



function findEventIndex(types, eventTitle, eventType) {
    const indexByTitle = types.findIndex(val => val.title === eventTitle);
    if (indexByTitle !== -1) { return indexByTitle };
    const indexByEventType = types.findIndex(val => val.eventType === eventType);
    if (indexByEventType !== -1) { return indexByEventType };
    return types.findIndex(val => val.title === eventType);
}

const UnapproveEventSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M9 4.7998V4H7.5V4.7998H4.9998C4.33706 4.7998 3.7998 5.33706 3.7998 5.9998V17.9998C3.7998 18.6625 4.33706 19.1998 4.9998 19.1998H18.9998C19.6625 19.1998 20.1998 18.6625 20.1998 17.9998V5.9998C20.1998 5.33706 19.6625 4.7998 18.9998 4.7998H16.5V4H15V4.7998H9ZM15 5.9998H9V7H7.5V5.9998H4.9998V17.9998H18.9998V9.7002L5 9.70019L5 8.50019L18.9998 8.5002V5.9998H16.5V7H15V5.9998Z" fill="#FF0000"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.0911 13.2426L14.7884 11.5453L13.9399 10.6967L12.2426 12.3941L10.5453 10.6968L9.69681 11.5454L11.394 13.2426L9.69727 14.9394L10.5458 15.7879L12.2426 14.0911L13.9395 15.788L14.788 14.9395L13.0911 13.2426Z" fill="#FF0000"/>
        </svg>
    )
}

const DeleteEventSvg = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.2087 7.91797C15.2087 7.91797 14.7935 12.9927 14.5358 15.6291C14.4836 16.1625 14.0342 16.5645 13.4983 16.5648L6.57322 16.5688C6.03678 16.5691 5.58636 16.1669 5.53161 15.6333C5.26021 12.9881 4.79199 7.91797 4.79199 7.91797" stroke="#FF0000" strokeWidth="1.26316" strokeLinecap="square" strokeLinejoin="round"/>
            <path d="M16.25 5.46224H3.75" stroke="#FF0000" strokeWidth="1.26316" strokeLinecap="square" strokeLinejoin="round"/>
            <path d="M13.75 5.46203C12.9609 5.46203 12.6541 4.89613 12.5617 4.24071C12.4637 3.54575 11.9627 2.91797 11.2609 2.91797H8.73909C8.03725 2.91797 7.53627 3.54575 7.43829 4.24071C7.34589 4.89613 7.0391 5.46203 6.25 5.46203" stroke="#FF0000" strokeWidth="1.26316" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

const PencilEditSvg = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.1403 0.292212C13.9445 0.0975619 13.6275 0.0981976 13.4324 0.293632L6.2469 7.49187C6.18707 7.55181 6.14348 7.62593 6.12021 7.70727L5.40723 10.2001C5.36404 10.3511 5.50431 10.4905 5.65549 10.4468L8.15103 9.72542C8.23247 9.70188 8.30657 9.65809 8.3664 9.59815L13.6415 4.31374L11.5309 2.20313L12.238 1.49602L14.348 3.60601L15.5519 2.39991C15.747 2.20448 15.7464 1.88825 15.5505 1.6936L14.1403 0.292212Z" fill="#1A1B1D"/>
            <path d="M14 8V13.9C14 13.9552 13.9552 14 13.9 14H2.1C2.04477 14 2 13.9552 2 13.9V2.1C2 2.04477 2.04477 2 2.1 2H8" stroke="#1A1B1D" strokeWidth="1.5"/>
        </svg>
    )
  }