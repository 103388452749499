import { Dropdown } from "antd"
import { useState } from "react"
import { DownloadIcon, VerticalDotsMenu } from "../../../../../../assets/icons/common/common-icons"
import { ExportViewModal } from "./ExportViewModal/ExportViewModal"

import { 
  MoreActionsButton, 
  MoreActionsItemIconWrapper, 
  MoreActionsItemText, 
  MoreActionsItemWrapper, 
  MoreActionsMenuWrapper 
} from "./WealthMoreActions.styles"
import { ModifiedTooltip } from "../../../../../../common/components/ModifiedTooltip/ModifiedTooltip"

export const WealthMoreActions = ({ isInflowOutflowTab , listViewMode , allocation , isBulkEditMode , setEditBulkItemsMode }) => {
  const [exportModalVisible, setExportModalVisible] = useState(false)

  const handleExportViewModal = () => {
    if (!isInflowOutflowTab && listViewMode !== 'holdings') {
      setExportModalVisible(true)
    }
  }

  const handleEditItems = () => {
    setEditBulkItemsMode(!isBulkEditMode);
  }

  const isExportViewDisabled = isInflowOutflowTab || listViewMode === 'holdings';
  
  const createActionsMenu = () => {
    return (
      <MoreActionsMenuWrapper>
        {['items', 'holdings', 'crypto-holdings'].includes(listViewMode) && <MoreActionsItemWrapper onClick={handleEditItems}>
              <MoreActionsItemIconWrapper><EditItemsIcon /></MoreActionsItemIconWrapper>
              <MoreActionsItemText>{isBulkEditMode ? `Cancel edit items` : `Select items to edit`}</MoreActionsItemText>
            </MoreActionsItemWrapper> 
        }
        <ModifiedTooltip overlayStyle={{whiteSpace:'pre-line'}} overlayInnerStyle={{fontWeight: 450, fontSize: '12px', lineHeight: '15px'}} title={isExportViewDisabled ? `Export is currently not\navailable for this view` : ''}>
            <MoreActionsItemWrapper onClick={handleExportViewModal} isDisabled={isExportViewDisabled}>
              <MoreActionsItemIconWrapper><DownloadIcon /></MoreActionsItemIconWrapper>
              <MoreActionsItemText>Export View</MoreActionsItemText>
            </MoreActionsItemWrapper>
        </ModifiedTooltip>
      </MoreActionsMenuWrapper>
    )
  }

  return (
    <>
      <Dropdown 
        overlay={createActionsMenu()}
        trigger={['click']}
        placement="bottomLeft">
        <MoreActionsButton onClick={e => e.preventDefault()}>
            <VerticalDotsMenu />
        </MoreActionsButton>
      </Dropdown>

      {exportModalVisible && <ExportViewModal onClose={() => setExportModalVisible(false)} />}
      
    </>
  )

}

const EditItemsIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.3981 11.7969L12.003 6.587" stroke="white" strokeWidth="1.5"/>
    <path d="M9.90656 19.5683L9.9056 20.3183C10.1098 20.3186 10.3053 20.2355 10.4469 20.0884L9.90656 19.5683ZM4.5028 14.3674L3.96242 13.8473C3.8208 13.9945 3.74531 14.193 3.75338 14.3971L4.5028 14.3674ZM4.70825 19.5617L3.95883 19.5913L3.98729 20.3107L4.70729 20.3117L4.70825 19.5617ZM14.6889 5.22602L18.6517 9.04001L19.6919 7.95926L15.7291 4.14526L14.6889 5.22602ZM18.6585 9.3935L9.36619 19.0482L10.4469 20.0884L19.7392 10.4337L18.6585 9.3935ZM5.04317 14.8875L14.3354 5.23278L13.2547 4.1926L3.96242 13.8473L5.04317 14.8875ZM9.90752 18.8183L4.70921 18.8117L4.70729 20.3117L9.9056 20.3183L9.90752 18.8183ZM5.45766 19.532L5.25221 14.3378L3.75338 14.3971L3.95883 19.5913L5.45766 19.532ZM18.6517 9.04001C18.7512 9.13576 18.7542 9.29402 18.6585 9.3935L19.7392 10.4337C20.4094 9.73732 20.3882 8.62948 19.6919 7.95926L18.6517 9.04001ZM15.7291 4.14526C15.0328 3.47504 13.9249 3.49624 13.2547 4.1926L14.3354 5.23278C14.4312 5.1333 14.5895 5.13027 14.6889 5.22602L15.7291 4.14526Z" fill="white"/>
    <path d="M20 19.5977H13" stroke="white" strokeWidth="1.5"/>
  </svg>
)
