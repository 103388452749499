import styled from '@emotion/styled/macro';
import { NavLink } from "react-router-dom";
import { Input, Select, DatePicker } from 'antd';
import { shadows, colors, views } from '../../../common/styles/theme.styles';
import { MobileHeader } from '../../layout/components/MobileLayout.styles';
import { flexCenter } from '../../../common/styles/combos.styles';

// export const PageWrapper = styled.div`
//     width:100%;
    
//     display: flex;
//     flex-direction: row;
//     flex: 1 1;
//     // padding:0 16px;
//     overflow-y: scroll;
//     // padding:0px 0px 16px;
//     // align-items: center;
//     // background-color: #fcfdff;
//     position:relative;
//     //overflow:hidden;
// `

// export const CenterContent = styled.div`
//     width: 100%;
//     max-width: 816px;
//     padding: 0 16px;
//     margin: 0 auto;
//     min-height:100%;
// `;

// export const CenterContent = styled.div`
//     width: 100%;
//     // max-width: ${sizes.contentWidth+32}px;
//     max-width: 960px;
//     padding: 0 16px;
//     margin: 0 auto;
//     display: flex;
//     flex-direction: row;
// `;


export const PageSideMenu = styled.div`
    
    // background: #FFFFFF;
    // box-shadow: 0px 115px 70px rgba(0, 0, 0, 0.08);
    z-index: 2;
    flex:2;
    display: flex;
    flex-direction: column;

`

export const PageSideMenuWidthContent = styled.div`
    width:196px;
    display:flex;
    flex-direction:column;
    position:sticky;
    top: 0;
`

export const PageContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    // overflow-y: auto;
    padding:24px 16px 16px;
    flex:7;
    /* padding:0px 0px 16px; */
`;

export const PageWidthContent = styled.div`
    max-width: 740px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    /* overflow-y: auto;
    padding:24px 16px 16px; */
    /* padding:0px 0px 16px; */
`;

export const SideMenuTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color:#000;
    padding:24px 8px 24px;
`;

export const SideMenuLink = styled(NavLink)`
    color:#a7a8aa;
    font-weight: 500;
    font-size: 14px;
    &.selected{
        color:#2A2D33;
        svg{
            path{
                stroke: #2A2D33;
            }
        }
        :hover{
            svg{
                path{
                    stroke: #2A2D33;
                }
            }
        }
    }
    padding:8px;

    ${props => props.border &&
        `display: flex;
        align-items: center;
        gap:6px;
        border-top: 1px solid #DDE3EB;
        border-bottom: 1px solid #DDE3EB;
        padding: 16px 8px;`
    }

    svg{
        path{
            stroke: #a7a8aa;
            transition: 0.3s;
        }
    }
    
    :hover{
        svg{
            path{
                stroke: #40a9ff;
            }
        }
    }

`;

export const CashAccountsLink = styled(NavLink)``;



export const DataBox = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.24), 0px 2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    width:100%;
    display:flex;
    flex-direction:column;
    margin-bottom: 16px;
`;

export const DataBoxSeparator = styled.div`
    height:1px;
    width:100%;
    background: #E3E4E6;
`;

export const DataBoxHeaderWrapper = styled.div`
    padding:24px 24px 0px 24px;
`;

export const DataBoxHeader = styled.div`
    border-bottom: 1px solid #DDE3EB;
    padding-bottom: 24px;
`;

export const DataBoxTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.darkGray1};
    margin-bottom: 2px;
`;

export const DataBoxSubTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: ${colors.darkGray2};
    /* padding:8px 16px 0px; */
`;

export const DataBoxTextDescription = styled.div`
    color: #8C9199;
    font-size: 14px;
    padding:0 16px 16px;
`;


export const DataBoxFormContentWrapper = styled.div`
    display:flex;
    flex-direction:row;
    gap: 24px;
    padding: 24px 24px 0;
    ${views.tablet}{
        flex-direction: column;
        gap:0px;
        padding: 24px ;
    }
`;

export const DataBoxFormWrapper = styled.div`
    flex:0.65;
    ${views.tablet}{
        flex:1;
    }
`;

export const DataBoxInfoWrapper = styled.div`
    flex: 0.35;
    display: flex;
    align-items: flex-start;
    justify-content: end;
    ${views.tablet}{
        flex:1;
    }
`;

export const DataBoxActions = styled.div`
    padding:12px 24px;
    border-top:1px solid #f1f3f5;
    background-color:#FFFFFF;
    display:flex;
    flex-direction:row-reverse;
    border-radius:0 0 4px 4px;
    gap: 10px;

`;

export const ButtonContentWrapper = styled.div`
    display: flex;
    gap: 6px;
`;

export const ActionsButton = styled.div`
    cursor: pointer;
    background: ${props => props.black ? '#1A1B1D' : '#FFFFFF'};
    box-shadow: ${props => props.borderless ? 'none' : '0px 1px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.3)'};
    border-radius: 6px;
    padding:8px 20px;
    font-weight: 500;
    font-size: 14px;
    color:${props => props.danger ? '#EA6464' : props.black ? '#FFFFFF' : props.borderless ? '#1A1B1D' : '#1A1B1D'};
    display: inline-block;
    border:${props => props.danger ? '1px solid #EA6464' : '1px solid #00000000'};
    
    :hover{
        color:${props => props.danger ? '#EA6464' : props.black ? '#FFFFFF' : props.borderless ? '#585D66' : '#1A1B1D'};
        border:${props => props.danger ? '1px solid #EA6464' : props.black ? '1px solid #00000000' : props.borderless ? '1px solid #00000000' : '1px solid #BDC6DA' };
        background: ${props => props.black ? '#353A44' : '#FFFFFF'};

    }
    
    :active {
        color:${props => props.danger ? '#EA6464' : props.black ? '#FFFFFF' : props.borderless ? '#585D66cc' : '#1A1B1D'};
        border:${props => props.danger ? '1px solid #EA6464' : props.black ? '1px solid #00000000' : props.borderless ? '1px solid #00000000' : '1px solid #1A1B1D' };
        background: ${props => props.black ? '#353A44cc' : '#FFFFFF'};
    }

    ${props => props.disabled ? `
        cursor: not-allowed;
        opacity: 0.4;
    ` : ``}
`;

export const DataBoxInputWrapper = styled.div`
    margin-bottom: 24px;
    display:flex;
    flex-direction:column;
`;

export const DataBoxInputLabel = styled.div`
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    margin-bottom:4px;
    color: ${colors.darkGray2};
`;

export const DataBoxPhoneInputWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

export const InputWithLabelOnTopWrapper = styled.div`
    position: relative;
    width: 100%;

    .ant-dropdown-trigger {

      .simple_select_button_text {
        padding-top: 18px;
        font-size: 18px;
        color:${colors.darkGray1};
        font-weight:450;
      }
      .select-arrow{
        width:18px;
        height:18px;
        margin-right: 4px; 
      }
      ${props => props.withError ? `
            border: 1px solid red;
        ` : ``}
    }

    .ant-input{
        padding-top: 26px;
        font-size: 18px;
        :hover{
            border-color: #1A1B1D;
        }
        ${props => props.withError ? `
            border: 1px solid red;
        ` : ``}

    }

    .ant-picker .ant-picker-input input{
      padding-top: 18px;
      font-size: 18px;
      color:${colors.darkGray1};
      font-weight:450;
    }
    .ant-picker .ant-picker-input .ant-picker-suffix{
      font-size:18px;
    }
`;

export const FloatingLabeInInput = styled.div`
    position:absolute;
    top:6px;
    left:12px;
    font-size: 13px;
    color:${colors.darkGray3};
    font-weight: 450;
    pointer-events: none;
    z-index: 2;
`;

export const DataBoxInputElement = styled.div`
`;

export const StyledInput = styled(Input)`
    /* box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.8), 0px 2px 4px rgba(0, 0, 0, 0.04) !important; */
    box-shadow:  ${props => props.email ? 'none' : '0px 0px 1px rgba(0, 0, 0, 0.3)' } !important;
    border-radius: 6px;
    background:${props => props.email ? '#F7F8F9' : '#fff'};
    border:none;
    padding: 5px 11px;
    &:focus{
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 1), 0px 2px 4px rgba(0, 0, 0, 0.1);
    }
    &.lockedMonthlyIncome{
        background-color: #f5f5f5;
    }
`;

export const StyledSelect = styled(Select)`
    width:100%;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    background:#fff;
    border:none;
    .ant-select-selector{
        border:none!important;
        border-radius:6px!important;
        box-shadow: none!important;
    }
    &.ant-select-focused{
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 1), 0px 2px 4px rgba(0, 0, 0, 0.1);
        .ant-select-selector{                
            // border-color:red!important;
            // box-shadow: none!important;
        }
    } 
`;

export const StyledMoneyWithCurrencyInput = styled.div`
    display:flex;
    flex-direction: row;
`;


export const StyledDatePicker = styled(DatePicker)`
    // background-color:red;
    width:100%;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    background:#fff;
    border:none;
    padding: 5px 11px;
    .ant-select-selector{
        border:none;
        box-shadow: none!important;
    }
    &.ant-picker-focused{
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 1), 0px 2px 4px rgba(0, 0, 0, 0.1);
        .ant-select-selector{                
            // border-color:red!important;
            // box-shadow: none!important;
        }
    } 
`;

export const InfoBoxWrapper = styled.div`
    background: #F8F9FB;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.32), 0px 2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding:16px;
    display:flex;
    flex-direction:column;
    ${views.tablet}{
        flex:1;
    }
`;

export const InfoBoxTitle = styled.div`
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color:#2A2D33;
    margin-bottom:8px;
`;

export const InfoBoxDescription = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: #9299A7;
`;

export const RadioButtonsWrapper = styled.div`
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;
    gap: 8px;
`;
export const StyledRadioOption = styled.div`
    cursor: pointer;
    background: ${props => props.isSelected ? '#2A2D33' : '#FFFFFF'};
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.32), 0px 2px 4px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding:4px 8px;
    font-size: 14px;
    color:${props => props.isSelected ? '#FFFFFF' : '#2A2D33'};
    /* margin-right:8px; */
    display: inline-block;
    ${props=>props.disabled ? `
        opacity: 0.3;
        cursor: not-allowed;
    ` : ``}
`;

export const InputLabel = styled.div`
    color:#3D3D3D;
    font-size:14px;
    text-align:left;
    font-weight:500;
    padding-bottom:4px;
    padding-left: 1px;
`;

export const LoginPasswordInput = styled(Input.Password)`
    height:42px;
    border: 1px solid #DFDFDF;
    font-size:14px;
    padding:12px;
    background-color:#FFFFFF;
    box-shadow: ${shadows.inputShadow};
    border-radius: 4px;
`;

export const DataBoxFormTitle = styled.div`
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: ${colors.darkGray1};
    margin-bottom: 24px;
`;

export const ActionsWrapper  = styled.div`
    width:100%;
    position:relative;
    position:sticky;
    bottom:0px;
    background-color:#fff;
    z-index:2;
    border-radius:0 0 8px 8px;
`;

export const ActionsStickyWrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    /* max-width: 512px; */
    padding: 16px 24px;
    border-top: 1px solid #f0f0f0;
    border-radius:0 0 8px 8px;
    // position:sticky;
    // top:40px;
`;


export const ActionButton = styled.div`
    background-color: ${props=>props.disabled ? '#8c8c8c' : props.white ? '#ffffff' : '#2A2D33'} ;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
    border-radius: 2px;
    padding:8px 16px;
    font-size:14px;
    color: ${props=> props.white ? '#000' : '#fff' };
    cursor: ${props=>props.disabled ? 'not-allowed' : 'pointer'};
    text-align:center;
    transition:all 0.2s;
`;

export const ProfileMobileHeader = styled(MobileHeader)``;

export const HeaderTitle = styled.div`
    font-weight: 450;
    font-size: 18px;
    color: #fff;
`;

export const ProfileDesktopBackLink = styled.div`
    ${flexCenter}
    cursor: pointer;
`;

export const ProfileHeader = styled.div`
    color: ${colors.darkGray1};
    font-family: Circular;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
`;