import { makeAutoObservable } from "mobx";
import { WEALTH_FILTER_RESULT_TYPE } from "../../wealth/data/wealth.store";
import { ls } from "../../../common/utils/localstorage.util";
import { MAIN_RESULTS_ALLOCATIONS_ATTRIBUTES, MAIN_RESULTS_CLASSES_CATEGORIES_ATTRIBUTES, MAIN_RESULTS_ITEMS_ATTRIBUTES, MIXED_RESULTS_DATA_LAYERS, SINGLE_CLASS_RESULTS_DATA_LAYERS, STOCK_DATA_LAYERS_FOR_MIXED_RESULTS } from "./data-layers.const";

const LS_KEY = 'data-layers';
// const MAX_SELECTION_COUNT = 4;
const appliedDataLayersDefaultsByAllocationType = {
    'classes': ['allocation'],
    'categories': ['allocation'],
    'entities': ['assetsAllocation', 'liabilitiesValue'],
    'accounts': ['assetsAllocation', 'liabilitiesValue'],
    'currencies': ['assetsAllocation', 'liabilitiesValue'],
    'risk': ['assetsAllocation', 'liabilitiesValue'],
    'liquidity': ['assetsAllocation', 'liabilitiesValue'],
    'institution': ['assetsAllocation', 'liabilitiesValue'],
    'customClasses': ['assetsAllocation', 'liabilitiesValue'],
    'customSubClasses': ['assetsAllocation', 'liabilitiesValue'],
}

export class DataLayersStore {
    showDrawer = false;
    resultType = '';
    singleClassId = 0;
    selectedListViewMode = '';
    appliedDataLayers = [];
    attributes = [];
    localStorageKey = '';
    showMaxSelectedMessage = false;
    timeout = null;
    isItemsContainsStocks = false;
    isShowAllAttributes = true;

    sortBy = 'title';
    sortByColumn = {dataKey: 'title', isItemProp: true};
    

    constructor(metadataStore,filtersStore,uiStore) {
        makeAutoObservable(this);
        this.metadataStore = metadataStore;
        this.filtersStore = filtersStore;
        this.uiStore = uiStore;
    }

    setResultType(resultType,classId, isItemsContainsStocks, listViewMode , allocation){
        this.resultType = resultType;
        this.singleClassId = classId;
        this.selectedListViewMode = listViewMode;
        this.selectedAllocation = allocation;
        this.isItemsContainsStocks = isItemsContainsStocks;
        this.setData();
    }

    setSortBy = (sortByParam) => {
        this.sortBy = sortByParam;
    }
    setSortByColumn = (dlCol) => {
        this.sortByColumn = dlCol;
    }
    setSortDirection = (dir) => {
        this.sortByColumn = {...this.sortByColumn, direction:dir}
    }

    setSelectedListViewMode(viewMode, allocation){
        this.selectedListViewMode = viewMode;
        this.selectedAllocation = allocation;
        if (viewMode === 'allocations' && ['classes','categories','risk','liquidity'].includes(allocation)){
            this.setSortBy('title');
            this.setSortByColumn({dataKey: 'title', direction: ['risk','liquidity'].includes(allocation) ? 'asc' : 'desc'});
        } else {
            this.setSortBy('usdValue');
            this.setSortByColumn({dataKey: 'usdValue', isItemProp: true, direction:'desc'});
        }
        this.setData();
    }

    setData() {
        if (this.resultType !== ''){
            // TODO : get saved data-layers by type on Local storage and mark as selected
            const resultLsKey = this.localStorageKey + '_' + this.resultType + (this.resultType === WEALTH_FILTER_RESULT_TYPE.SINGLE_CLASS ? ('_' + this.singleClassId) : '') + '_' + this.selectedListViewMode + ( this.selectedListViewMode === 'allocations' ? `_${this.selectedAllocation}` : ``);
            let storedDataLayers = JSON.parse(ls.get(resultLsKey));
            let defaultDataLayers = this.selectedListViewMode === 'allocations' ? appliedDataLayersDefaultsByAllocationType?.[this.selectedAllocation] || [] : [];

            this.appliedDataLayers = this.uiStore.isDesktopView ? (storedDataLayers || defaultDataLayers) : storedDataLayers?.length === 1 ? storedDataLayers : [];
            const relevantDataLayers = getRelevantDataLayers({
                isItemsContainsStocks: this.isItemsContainsStocks,
                filtersStore: this.filtersStore,
                resultType: this.resultType,
                selectedListViewMode: this.selectedListViewMode,
                selectedAllocation: this.selectedAllocation,
                singleClassId: this.singleClassId
            });
            this.attributes = [
                ...relevantDataLayers.map(attr => {
                    const isSelected = attr.subAttributes.length > 0 ? attr.subAttributes.some(sAttr=> this.appliedDataLayers.includes(sAttr.dataKey)) : this.appliedDataLayers.includes(attr.dataKey);
                    const subAttributes = attr.subAttributes.map(subAttr => ({ ...subAttr, isSelected: this.appliedDataLayers.includes(subAttr.dataKey) }));
                    const selectedCount = subAttributes.reduce((a,b)=>a+b.isSelected,0);
                    const isLocked = attr.isLocked || ( this.uiStore.isDesktopView && attr.isLockedForDesktop)
                    return { ...attr, isOpen: false, isSelected, isLocked, selectedCount, subAttributes }
                }),
            ]
            const hasHiddenSelectedAttributes = this.attributes.slice(7).some(attr=>attr?.isSelected);
            this.setIsShowAllAttributes(hasHiddenSelectedAttributes);
        }
    }

    setShowDrawer(show) {
        this.showDrawer = show;
        if (show){
            this.setData();
        }
    }

    setLSKey(userId) {
        this.localStorageKey = LS_KEY + '_' + userId;
    }


    handleClearDataLayerSelections() {
        this.attributes.forEach(attr=>{
            if(attr.subAttributes.length) {
                attr.subAttributes.forEach(subAttr=>subAttr.isSelected = false);
            }
            attr.isSelected = 0;
            attr.selectedCount = 0;
        })
        ls.remove(this.localStorageKey);
    }

    handleLimitMessage() {
        this.showMaxSelectedMessage = true;
        if (this.timeout) window.clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            if (this.showMaxSelectedMessage) this.showMaxSelectedMessage = false;
        }, 3000);
    }

    closeAllDataLayers() {
        this.attributes.forEach(attr=>attr.isOpen = false);
    }

    setDataLayerIsOpen(dataKey, isOpen) {
        this.attributes.find(attr => attr.dataKey === dataKey).isOpen = isOpen;
    }

    setDataLayerIsSelected(attrDataKey, isSelected) {
        if (this.isMaxSelectedCount && isSelected){
            this.handleLimitMessage();
        }
        else {

            if (this.maxSelectedCount === 1 && isSelected){
                this.attributes.forEach(attr=>{
                    if(attr.subAttributes.length) {
                        attr.subAttributes.forEach(subAttr=>subAttr.isSelected = false);
                    }
                    attr.isOpen = false;
                    attr.isSelected = 0;
                    attr.selectedCount = 0;
                })
            }

            const relevantAttribute = this.attributes.find(attr => attr.dataKey === attrDataKey);
                relevantAttribute.isSelected = isSelected && !this.isMaxSelectedCount;
                const subAttrLength = relevantAttribute.subAttributes.length;
                const markAsSelectedCount = relevantAttribute.isSingleSelect && isSelected && !relevantAttribute.isOpen ? 1 : ((this.maxSelectedCount - this.selectedAttributesCount) < subAttrLength && isSelected) ? this.maxSelectedCount - this.selectedAttributesCount : subAttrLength

                for (let index = 0; index < markAsSelectedCount; index++) {
                    relevantAttribute.subAttributes[index].isSelected = isSelected;
                }
                relevantAttribute.selectedCount = isSelected ? markAsSelectedCount : 0;
                if (isSelected && markAsSelectedCount > 0) {
                    relevantAttribute.isOpen = true;
                }
        }
        
        // this.isMaxSelectedCount ? this.handleLimitMessage() : this.showMaxSelectedMessage = false;
    }

    setListOptionSelected(attrDataKey, subAttrDataKey, isSelected) {
        if (this.isMaxSelectedCount && isSelected){
            this.handleLimitMessage();
        }
        else {

            if (this.maxSelectedCount === 1){
                this.attributes.forEach(attr=>{
                    if(attr.subAttributes.length) {
                        attr.subAttributes.forEach(subAttr=>subAttr.isSelected = false);
                    }
                    attr.isSelected = 0;
                    attr.selectedCount = 0;
                })
            }
            const relevantAttribute = this.attributes.find(attr => attr.dataKey === attrDataKey);
            relevantAttribute.subAttributes.find(subAttr => subAttr.dataKey === subAttrDataKey).isSelected = isSelected && !this.isMaxSelectedCount;
            relevantAttribute.isSelected = relevantAttribute.subAttributes.some(subAttr => subAttr.isSelected);
            relevantAttribute.selectedCount = relevantAttribute.subAttributes.reduce((a,b)=>a+b.isSelected,0);
        }
        // this.parameters[paramIndex].options[optionIndex].isSelected = isSelected;
        // const selectedCount = this.parameters[paramIndex].options.reduce((a,b)=>a + (b.isSelected ? 1 : 0), 0);
        // this.parameters[paramIndex].selectedCount = selectedCount;
    }

    setAppliedDataLayers() {
        const appliedAttributes = this.attributes.filter(attr=>attr.isSelected).reduce((acc,attr)=>{
            if (attr.subAttributes.length){
                const selectedSubs = attr.subAttributes.filter(sAttr=>sAttr.isSelected).reverse().map(sAttr=>sAttr.dataKey);
                acc.push(...selectedSubs);
            } else {
                acc.push(attr.dataKey);
            }
            return acc;
        },[])
        this.appliedDataLayers = appliedAttributes;
        const resultLsKey = this.localStorageKey + '_' + this.resultType + (this.resultType === WEALTH_FILTER_RESULT_TYPE.SINGLE_CLASS ? ('_' + this.singleClassId) : '') + '_' + this.selectedListViewMode + ( this.selectedListViewMode === 'allocations' ? `_${this.selectedAllocation}` : ``);
        ls.set(resultLsKey, JSON.stringify(appliedAttributes));
    }

    setSortByForMobile(){
        if (this.dataLayersHeaders.length > 0){
            this.setSortBy(this.dataLayersHeaders[0].dataKey);
            this.setSortByColumn(this.dataLayersHeaders[0]);
        } else {
            this.setSortBy('usdValue');
            this.setSortByColumn({dataKey:'usdValue'});
        }
    }

    setIsShowAllAttributes = (show) => {
        this.isShowAllAttributes = show;
    }

    get panelDataLayers(){
        // const filteredAttrs = this.uiStore.isDesktopView ? ((!this.isShowAllAttributes && this.attributes.length > 9) ? this.attributes.slice(0, 7) : this.attributes) : this.attributes.filter(atr=>!atr.isHiddenForMobile);
        const filteredAttrs = this.uiStore.isDesktopView ? this.attributes : this.attributes.filter(atr=>!atr.isHiddenForMobile);
        return filteredAttrs.map(attr=>{
            const {dataKey, title, isLocked, isSelected, isOpen, selectedCount } = attr;
            return {
                dataKey, title, isLocked, isSelected, isOpen, selectedCount,
                subAttributes: attr.subAttributes.map(sAttr=>{
                    const {dataKey, title, isSelected} = sAttr;
                    return {dataKey, title, isSelected};
                })
            }
        });
    }

    get selectedAttributesCount() {
        return this.attributes.length ? this.attributes.reduce((attrCount, attr) => {
            if (attr.subAttributes.length === 0) {
                return attrCount + (attr.isSelected || 0);
            }
            // return attrCount + attr.subAttributes.reduce((subAttrCount, subAttr) => subAttrCount + subAttr.isSelected, 0);
            return attrCount + attr.selectedCount;
        }, 0) : 0;
    }

    get maxSelectedCount(){
        const screenWidth = this.uiStore.windowWidth
        return screenWidth > 1190 ? 4 :
            screenWidth > 1070 ? 3 :
            screenWidth > 1024 ? 2 :
            screenWidth > 970 ? 4 :
            screenWidth > 860 ? 3 :
            screenWidth > 768 ? 2 : 1;
        // return this.uiStore.isDesktopView ? 4 : 1;
    }
    get isMaxSelectedCount() {
        return this.maxSelectedCount !== 1 && this.selectedAttributesCount >= this.maxSelectedCount;
    }
    
    get dataLayersHeaders() {
        const flatAttrs = this.attributes.flatMap(attr => {
            const {subAttributes, ...attrProps} = attr;
            return [{...attrProps},...(subAttributes || [])]
        });
        return this.appliedDataLayers.map(dataLayerKey=>{
            const relevantAttribute = flatAttrs.find(attr => attr.dataKey === dataLayerKey);
            if (!relevantAttribute){return null}
            const parentAttribute = relevantAttribute.parentDataKey ? ( flatAttrs.find(attr => attr.dataKey === relevantAttribute.parentDataKey) ) : null;
            
            return {
                dataKey: dataLayerKey,
                type : relevantAttribute.type,
                path : relevantAttribute.path,
                title: parentAttribute ? parentAttribute.title : relevantAttribute.title,
                ...( relevantAttribute.categoryPath ? {categoryPath: relevantAttribute.categoryPath} : {}),
                ...( parentAttribute ? {subTitle: relevantAttribute.title } : {}),
                ...( relevantAttribute.htmlTitle ? {htmlTitle: relevantAttribute.htmlTitle } : {}) 
            }
        }).filter(i=>i).slice(0,this.maxSelectedCount);;
    }

    get dataLayersColumns() {
        const flatAttrs = this.attributes.flatMap(attr => {
            const {subAttributes, ...attrProps} = attr;
            return [{...attrProps},...(subAttributes || [])]
        });
        return this.appliedDataLayers.map(dataLayerKey=>{
            const relevantAttribute = flatAttrs.find(attr => attr.dataKey === dataLayerKey);
            if (!relevantAttribute){return null}
            // const relevantParentAttribute = this.attributes.find(attr => attr.dataKey === dataLayerKey.split('_')[0]);
            // if (!relevantParentAttribute){return null}
            // const subAttribute = dataLayerKey.includes('_') ? relevantParentAttribute.subAttributes.find(sAttr => sAttr.dataKey === dataLayerKey) : null;
            // console.log(relevantAttribute)
            return {
                dataKey: dataLayerKey,
                type: relevantAttribute.type,
                path: relevantAttribute.path,
                labels: relevantAttribute.labels,
                showHoldings: !relevantAttribute.withoutHoldings,
                categoryPath: relevantAttribute.categoryPath,
                fallbackPath: relevantAttribute.fallbackPath,
                // isAttr : relevantParentAttribute.isAttr,
                // isItemProp: relevantParentAttribute.isItemProp,
                // parentDataKey: relevantParentAttribute.dataKey,
                // title: relevantParentAttribute.title,
                // ...( subAttribute ? { isSubAttribute : true, subDataKey: subAttribute.subKey} : {})
            }        
        }).filter(i=>i).slice(0,this.maxSelectedCount);
    }

    get shouldShowMorePanelDataLayers() {
        return this.uiStore.isDesktopView && this.attributes.length > 9;
    }

}

const getRelevantDataLayers = ({
    isItemsContainsStocks,
    filtersStore,
    resultType,
    selectedListViewMode,
    selectedAllocation,
    singleClassId
}) => {
    const additionalLayers = isItemsContainsStocks && !(filtersStore.isOnlyOneClassFilter && filtersStore.singleClassFilter.id === 3) ? STOCK_DATA_LAYERS_FOR_MIXED_RESULTS : [];
    if (['items', 'holdings'].includes(selectedListViewMode)) {
        switch (resultType) {
            case WEALTH_FILTER_RESULT_TYPE.MAIN:
                return MAIN_RESULTS_ITEMS_ATTRIBUTES(additionalLayers);
            case WEALTH_FILTER_RESULT_TYPE.MIXED:
                return MIXED_RESULTS_DATA_LAYERS(additionalLayers);
            case WEALTH_FILTER_RESULT_TYPE.SINGLE_CLASS:
                const key = `${singleClassId}${selectedListViewMode !== 'items' ? `_${selectedListViewMode}` : ''}`;
                return SINGLE_CLASS_RESULTS_DATA_LAYERS.hasOwnProperty(key) 
                    ? SINGLE_CLASS_RESULTS_DATA_LAYERS[key](additionalLayers) 
                    : MIXED_RESULTS_DATA_LAYERS();
            default:
                return [];    
        }
    } else {
        if (['classes', 'categories'].includes(selectedAllocation)) {
            return MAIN_RESULTS_CLASSES_CATEGORIES_ATTRIBUTES();
        }
        return MAIN_RESULTS_ALLOCATIONS_ATTRIBUTES();
    }

}